var __defProp = Object.defineProperty;
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};

// src/core.ts
var core_exports = {};
__export(core_exports, {
  ABIContract: () => ABIContract,
  ABIEvent: () => ABIEvent,
  ABIFunction: () => ABIFunction,
  ABIItem: () => ABIItem,
  Account: () => Account,
  Address: () => Address,
  Blake2b256: () => Blake2b256,
  BloomFilter: () => BloomFilter,
  BufferKind: () => BufferKind,
  Certificate: () => Certificate,
  Clause: () => Clause,
  Coin: () => Coin,
  CompactFixedHexBlobKind: () => CompactFixedHexBlobKind,
  ERC1155_ABI: () => ERC1155_ABI,
  ERC20_ABI: () => ERC20_ABI,
  ERC721_ABI: () => ERC721_ABI,
  FixedHexBlobKind: () => FixedHexBlobKind,
  FixedPointNumber: () => FixedPointNumber,
  HDKey: () => HDKey2,
  Hex: () => Hex,
  HexBlobKind: () => HexBlobKind,
  HexInt: () => HexInt,
  HexUInt: () => HexUInt,
  Keccak256: () => Keccak256,
  MAINNET_NETWORK: () => MAINNET_NETWORK,
  Mnemonic: () => Mnemonic,
  NUMERIC_REGEX: () => NUMERIC_REGEX,
  NumericKind: () => NumericKind,
  OptionalFixedHexBlobKind: () => OptionalFixedHexBlobKind,
  Quantity: () => Quantity,
  RLP: () => RLP,
  RLPProfiler: () => RLPProfiler,
  Revision: () => Revision,
  SOLO_NETWORK: () => SOLO_NETWORK,
  ScalarKind: () => ScalarKind,
  Secp256k1: () => Secp256k1,
  Sha256: () => Sha256,
  TESTNET_NETWORK: () => TESTNET_NETWORK,
  ThorId: () => ThorId,
  Transaction: () => Transaction,
  Txt: () => Txt,
  Units: () => Units,
  VET: () => VET,
  VIP180_ABI: () => VIP180_ABI,
  VIP181_ABI: () => VIP181_ABI,
  VIP210_ABI: () => VIP210_ABI,
  VTHO: () => VTHO,
  VTHO_ADDRESS: () => VTHO_ADDRESS,
  ZERO_ADDRESS: () => ZERO_ADDRESS,
  ZERO_BYTES: () => ZERO_BYTES,
  abi: () => ethersAbi,
  addressUtils: () => addressUtils,
  assertCompactFixedHexBlobBuffer: () => assertCompactFixedHexBlobBuffer,
  assertFixedHexBlobKindBuffer: () => assertFixedHexBlobKindBuffer,
  assertFixedHexBlobKindData: () => assertFixedHexBlobKindData,
  assertValidHexBlobKindData: () => assertValidHexBlobKindData,
  assertValidNumericKindBuffer: () => assertValidNumericKindBuffer,
  blake2b256: () => blake2b256,
  dataUtils: () => dataUtils,
  decodeBufferToHexWithLeadingZeros: () => decodeBufferToHexWithLeadingZeros,
  decodeBufferToNumberOrHex: () => decodeBufferToNumberOrHex,
  encodeBigIntToBuffer: () => encodeBigIntToBuffer,
  encodeCompactFixedHexBlob: () => encodeCompactFixedHexBlob,
  keccak256: () => keccak256,
  keystore: () => keystore3,
  mnemonic: () => mnemonic,
  networkInfo: () => networkInfo,
  revisionUtils: () => revisionUtils,
  sha256: () => sha2562,
  validateNumericKindData: () => validateNumericKindData,
  vechain_sdk_core_ethers: () => ethers2
});
import { ethers as ethers2 } from "ethers";

// src/certificate/Certificate.ts
import fastJsonStableStringify from "fast-json-stable-stringify";

// src/vcdm/abi/ABI.ts
import {
  InvalidAbiDataToEncodeOrDecode,
  InvalidOperation as InvalidOperation2
} from "@vechain/sdk-errors";
import { ParamType } from "ethers";
import {
  decodeAbiParameters,
  encodeAbiParameters,
  parseAbiParameters
} from "viem";

// src/vcdm/Hex.ts
import * as nc_utils from "@noble/curves/abstract/utils";
import * as nh_utils from "@noble/hashes/utils";
import { InvalidDataType, InvalidOperation } from "@vechain/sdk-errors";
var Hex = class _Hex {
  /**
   * Negative multiplier of the {@link digits} absolute value.
   *
   * @type {number}
   */
  static NEGATIVE = -1;
  /**
   * Positive multiplier of the {@link digits} absolute value.
   *
   * @type {number}
   */
  static POSITIVE = 1;
  /**
   * A constant string prefix used in hexadecimal notation.
   */
  static PREFIX = "0x";
  /**
   * The radix used for representing numbers base 16 in a positional numeral notation system.
   *
   * @typedef {number} RADIX
   */
  static RADIX = 16;
  /**
   * Regular expression for matching hexadecimal strings.
   * An empty input is represented as a empty digits.
   *
   * @type {RegExp}
   */
  static REGEX_HEX = /^-?(0x)?[0-9a-f]*$/i;
  /**
   * Regular expression pattern to match a prefix indicating hexadecimal number.
   *
   * @type {RegExp}
   */
  static REGEX_HEX_PREFIX = /^-?0x/i;
  /**
       * Returns the hexadecimal digits expressing this absolute value, sign and `0x` prefix omitted.
  
       * @remarks An empty content results in an empty string returned.
       */
  digits;
  /**
   * Represents the sign multiplier of a given number:
   * * {@link NEGATIVE} `-1` if negative,
   * * {@link POSITIVE} `1` if positive.
   */
  sign;
  /**
   * Creates a new instance of this class to represent the value
   * built multiplying `sign` for the absolute value expressed by the hexadecimal `digits`.
   *
   * @param {number} sign - The sign of the value.
   * @param {string} digits - The digits of the absolute value in hexadecimal base.
   * @param {function} [normalize] - The function used to normalize the digits. Defaults to converting digits to lowercase.
   */
  constructor(sign, digits, normalize = (digits2) => digits2.toLowerCase()) {
    this.digits = normalize(digits);
    this.sign = sign;
  }
  /**
   * Returns the absolute value of this Hex object.
   *
   * @return {Hex} A new Hex object representing the absolute value of this Hex.
   */
  get abs() {
    return new _Hex(_Hex.POSITIVE, this.digits);
  }
  /**
   * Returns the value of `bi` as a `BigInt` type.
   *
   * @returns {bigint} The value of `bi` as a `BigInt`.
   */
  get bi() {
    return BigInt(this.sign) * nc_utils.hexToNumber(this.digits);
  }
  /**
   * Returns the Uint8Array representation of the aligned bytes.
   *
   * @return {Uint8Array} The Uint8Array representation of the aligned bytes.
   */
  get bytes() {
    return nc_utils.hexToBytes(this.alignToBytes().digits);
  }
  /**
   * Returns the value of n.
   *
   * @return {number} The value of n.
   *
   * @throws {InvalidOperation<Hex>} Throws an error if this instance doesn't represent
   * an [IEEE 754 double precision 64 bits floating point format](https://en.wikipedia.org/wiki/Double-precision_floating-point_format).
   */
  get n() {
    if (this.isNumber()) {
      return new DataView(this.bytes.buffer).getFloat64(0);
    }
    throw new InvalidOperation("Hex.n", "not an IEEE 754 float 64 number", {
      hex: this.toString()
    });
  }
  /**
   * Aligns the hexadecimal string to bytes by adding a leading '0' if the string length is odd.
   *
   * @returns {Hex} - The aligned hexadecimal string.
   */
  alignToBytes() {
    return this.digits.length % 2 === 0 ? this : new _Hex(this.sign, "0" + this.digits);
  }
  /**
   * Compares the current Hex object with another Hex object.
   *
   * @param {Hex} that - The Hex object to compare with.
   *
   * @return {number} - Returns a negative number if the current Hex object is less than the given Hex object,
   *                    zero if they are equal, or a positive number if the current Hex object is greater than the given Hex object.
   */
  compareTo(that) {
    if (this.sign === that.sign) {
      const digits = Math.max(this.digits.length, that.digits.length);
      const thisBytes = this.fit(digits).bytes;
      const thatBytes = that.fit(digits).bytes;
      let i = 0;
      let compareByte = 0;
      while (compareByte === 0 && i < thisBytes.length) {
        compareByte = thisBytes[i] - thatBytes[i];
        i++;
      }
      return compareByte;
    }
    return this.sign - that.sign;
  }
  /**
   * Returns a new instance of the Hex class, its value fits to the specified number of digits.
   *
   * @param {number} digits - The number of digits to fit the Hex value into.
   *
   * @returns {Hex} - A new Hex instance that represents the fitted Hex value.
   *
   * @throws {InvalidDataType} - If the Hex value cannot be fit into the specified number of digits.
   */
  fit(digits) {
    if (digits < this.digits.length) {
      let cue = 0;
      while (this.digits.length - cue > digits && this.digits.at(cue) === "0") {
        cue++;
      }
      if (this.digits.length - cue === digits) {
        return new _Hex(this.sign, this.digits.slice(cue));
      }
      throw new InvalidDataType(
        "Hex.fit",
        `can't fit in ${digits} digits`,
        { digits, hex: this }
      );
    }
    if (digits > this.digits.length) {
      return new _Hex(
        this.sign,
        "0".repeat(digits - this.digits.length) + this.digits
      );
    }
    return this;
  }
  /**
   * Determines whether this Hex instance is equal to the given Hex instance.
   *
   * @param {Hex} that - The Hex instance to compare with.
   * @return {boolean} - True if the Hex instances are equal, otherwise false.
   */
  isEqual(that) {
    return this.compareTo(that) === 0;
  }
  /**
   * Checks if this instance expresses a valid {@link Number} value
   * according the
   * [IEEE 754 double precision 64 bits floating point format](https://en.wikipedia.org/wiki/Double-precision_floating-point_format).
   *
   * @returns {boolean} Returns true if this instance expresses 32 hex digits (16 bytes, 128 bits) needed to represent
   * a {@link Number} value, else it returns false.
   */
  isNumber() {
    return this.digits.length === 32;
  }
  /**
   * Checks if the given string expression is a valid hexadecimal value.
   *
   * @param {string} exp - The string representation of a hexadecimal value.
   *
   * @return {boolean} - True if the expression is a valid hexadecimal value, case-insensitive,
   * optionally prefixed with `0x`; false otherwise.
   */
  static isValid(exp) {
    return _Hex.REGEX_HEX.test(exp);
  }
  /**
   * Determines whether the given string is a valid hexadecimal number prefixed with '0x'.
   *
   * @param {string} exp - The string to be evaluated.
   * @return {boolean} - True if the string is a valid hexadecimal number prefixed with '0x', otherwise false.
   */
  static isValid0x(exp) {
    return _Hex.REGEX_HEX_PREFIX.test(exp) && _Hex.isValid(exp);
  }
  /**
   * Create a Hex instance from a bigint, number, string, or Uint8Array.
   *
   * @param {bigint | number | string | Uint8Array} exp - The value to represent in a Hex instance:
   * * bigint is always representable in hexadecimal base notation;
   * * number, encoded as [IEEE 754 double precision 64 bits floating point format](https://en.wikipedia.org/wiki/Double-precision_floating-point_format);
   * * string is parsed as the hexadecimal expression of a bigint value, optionally tagged with `0x`;
   * * Uint8Array is interpreted as the sequence of bytes.
   *
   * @returns {Hex} - A Hex instance representing the input value.
   *
   * @throws {InvalidDataType} if the given `exp` can't be represented as a hexadecimal expression.
   */
  static of(exp) {
    try {
      if (exp instanceof Uint8Array) {
        return new _Hex(this.POSITIVE, nc_utils.bytesToHex(exp));
      } else if (typeof exp === "bigint") {
        if (exp < 0n) {
          return new _Hex(
            this.NEGATIVE,
            nc_utils.numberToHexUnpadded(-1n * exp)
          );
        }
        return new _Hex(
          this.POSITIVE,
          nc_utils.numberToHexUnpadded(exp)
        );
      } else if (typeof exp === "number") {
        const dataView = new DataView(new ArrayBuffer(16));
        dataView.setFloat64(0, exp);
        return new _Hex(
          exp < 0 ? this.NEGATIVE : this.POSITIVE,
          nc_utils.bytesToHex(new Uint8Array(dataView.buffer))
        );
      }
      if (this.isValid(exp)) {
        if (exp.startsWith("-")) {
          return new _Hex(
            this.NEGATIVE,
            this.REGEX_HEX_PREFIX.test(exp) ? exp.slice(3) : exp.slice(1)
          );
        }
        return new _Hex(
          this.POSITIVE,
          this.REGEX_HEX_PREFIX.test(exp) ? exp.slice(2) : exp
        );
      }
      throw new InvalidDataType("Hex.of", "not an hexadecimal string", {
        exp
      });
    } catch (e) {
      throw new InvalidDataType(
        "Hex.of",
        "not an hexadecimal expression",
        { exp: `${exp}` },
        // Needed to serialize bigint values.
        e
      );
    }
  }
  /**
   * Generates a random Hex value of the given number of bytes length.
   *
   * @param {number} bytes - The number of bytes to generate.
   * @throws {InvalidDataType} - If the bytes argument is not greater than 0.
   * @returns {Hex} - A randomly generated Hex value.
   *
   * @remarks Security auditable method, depends on
   * * [`nh_utils.randomBytes`](https://github.com/paulmillr/noble-hashes?tab=readme-ov-file#utils).
   */
  static random(bytes) {
    if (bytes > 0) {
      return _Hex.of(nh_utils.randomBytes(bytes));
    }
    throw new InvalidDataType("Hex.random", "bytes argument not > 0", {
      bytes
    });
  }
  /**
   * Returns a string representation of the object.
   *
   * @return {string} The string representation of the object.
   */
  toString() {
    return (this.sign < 0 ? "-0x" : "0x") + this.digits;
  }
};

// src/vcdm/abi/ABI.ts
var ABI = class _ABI {
  types;
  values;
  /**
   * ABI constructor from types and values.
   *
   * @param {string | AbiParameter[]} types - A list of ABI types representing the types of the values.
   * @param {unknown[]} values - An array of values according to the specified ABI types.
   **/
  constructor(types = [], values = []) {
    this.types = typeof types === "string" ? parseAbiParameters(types) : types;
    this.values = values;
  }
  /**
   * Compares the current ABI instance with another ABI instance.
   * @param that The ABI to compare with.
   * @returns {number} A non-zero number if the current ABI is different to the other ABI or zero if they are equal.
   * @override {@link VeChainDataModel#compareTo}
   * @remark The comparison is done by comparing the types and values of the ABI instances.
   **/
  compareTo(that) {
    this.types.forEach((type, index) => {
      if (type !== that.types[index]) {
        return -1;
      }
    });
    this.values.forEach((value, index) => {
      if (value !== that.values[index]) {
        return 1;
      }
    });
    return 0;
  }
  /**
   * Checks if the current ABI object is equal to the given ABI object.
   * @param that The ABI object to compare with.
   * @returns {boolean} True if the objects are equal, false otherwise.
   * @override {@link VeChainDataModel#isEqual}
   * @remark The comparison is done by comparing the types and values of the ABI instances.
   **/
  isEqual(that) {
    return this.compareTo(that) === 0;
  }
  /**
   * Throws an exception because the ABI cannot be represented as a big integer.
   * @returns {bigint} The BigInt representation of the ABI.
   * @throws {InvalidOperation} The ABI cannot be represented as a bigint.
   * @override {@link VeChainDataModel#bi}
   * @remark The conversion to BigInt is not supported for an ABI.
   */
  get bi() {
    throw new InvalidOperation2(
      "ABI.bi",
      "There is no big integer representation for an ABI.",
      { data: "" }
    );
  }
  /**
   * Encodes the values according to the specified ABI types when creating the ABI instance.
   *
   * @returns The ABI-encoded bytes representing the given values.
   * @throws {InvalidAbiDataToEncodeOrDecode, InvalidDataType}
   */
  get bytes() {
    return this.toHex().bytes;
  }
  /**
   * Throws an exception because the ABI cannot be represented as a number.
   * @returns {bigint} The number representation of the ABI.
   * @throws {InvalidOperation} The mnemonic cannot be represented as a number.
   * @override {@link VeChainDataModel#n}
   * @remark The conversion to number is not supported for an ABI.
   */
  get n() {
    throw new InvalidOperation2(
      "ABI.n",
      "There is no number representation for an ABI.",
      { data: "" }
    );
  }
  /**
   * Instantiates an ABI object from the given types and values.
   * @param {string | AbiParameter[]} types ABI parameters representing the types of the values.
   * @param {unknown[]} values ABI values.
   * @returns {ABI} The ABI object with the given types and values.
   */
  static of(types, values) {
    try {
      return new _ABI(types, values);
    } catch (error) {
      throw new InvalidAbiDataToEncodeOrDecode(
        "ABI.of",
        "Types and values must be valid ABI parameters.",
        {
          types,
          values
        },
        error
      );
    }
  }
  /**
   * Decodes the ABI values from the given ABI types and encoded data.
   * @param {string| AbiParameter[]} types The list of ABI types representing the types of the values to decode.
   * @param {Hex} dataEncoded The encoded data to decode.
   * @returns An ABI instance with the decoded values.
   */
  static ofEncoded(types, dataEncoded) {
    try {
      const hexDataEncoded = Hex.of(dataEncoded);
      let values;
      if (typeof types === "string") {
        const parsedAbiParams = parseAbiParameters(types);
        values = decodeAbiParameters(
          parsedAbiParams,
          hexDataEncoded.bytes
        );
      } else {
        values = decodeAbiParameters([...types], hexDataEncoded.bytes);
      }
      return new _ABI(types, [...values]);
    } catch (error) {
      throw new InvalidAbiDataToEncodeOrDecode(
        "ABI.of",
        "Decoding failed: Data must be a valid ABI type with corresponding valid data.",
        {
          types,
          data: dataEncoded
        },
        error
      );
    }
  }
  /**
   * Recursively parses an object and collects the values of each attribute into an array,
   * with nested arrays for nested objects.
   * @param {object} obj - The object to parse.
   * @returns {unknown[]} An array of values from the object, with nested arrays for nested objects.
   */
  parseObjectValues(obj) {
    const values = [];
    const recursiveParse = (currentObj) => {
      const currentValues = [];
      for (const key in currentObj) {
        if (Object.prototype.hasOwnProperty.call(currentObj, key)) {
          const value = currentObj[key];
          if (typeof value === "object" && value !== null) {
            currentValues.push(recursiveParse(value));
          } else {
            currentValues.push(value);
          }
        }
      }
      return currentValues;
    };
    values.push(...recursiveParse(obj));
    return values;
  }
  /**
   * It gets the first decoded value from the ABI.
   * @returns {ReturnType} The first decoded value from the ABI.
   */
  getFirstDecodedValue() {
    if (this.values[0] instanceof Object) {
      return this.parseObjectValues(
        this.values[0]
      );
    }
    return this.values[0];
  }
  /**
   * Parses an ABI to its Hex representation.
   * @returns {Hex} The Hex representation of the ABI.
   */
  toHex() {
    try {
      const abiParametersEncoded = encodeAbiParameters(
        this.types,
        this.values
      );
      return Hex.of(abiParametersEncoded);
    } catch (error) {
      throw new InvalidAbiDataToEncodeOrDecode(
        "ABI.toHex",
        "Encoding failed: Data must be a valid ABI type with corresponding valid data.",
        {
          types: this.types,
          values: this.values
        },
        error
      );
    }
  }
};
var ethersAbi = {
  encode: (type, value) => ABI.of(
    type instanceof ParamType ? (
      // This condition is here to enable compatibility with ethers regarding tuple[] types.
      type.format("full").replace(" list", "")
    ) : type,
    [value]
  ).toHex().toString(),
  encodeParams: (types, values) => {
    const stringTypes = types instanceof ParamType ? types.map(
      (type) => type.format("full").replace(" list", "")
    ) : types;
    const typesParam = parseAbiParameters(stringTypes.join(", "));
    return ABI.of([...typesParam], values).toHex().toString();
  },
  decode: (types, data) => ABI.ofEncoded(
    types instanceof ParamType ? (
      // This condition is here to enable compatibility with ethers regarding tuple[] types.
      types.format("full").replace(" list", "")
    ) : types,
    data
  ).getFirstDecodedValue()
};

// src/vcdm/abi/ABIContract.ts
import {
  InvalidAbiDataToEncodeOrDecode as InvalidAbiDataToEncodeOrDecode4,
  InvalidAbiItem as InvalidAbiItem3
} from "@vechain/sdk-errors";
import {
  getAbiItem,
  parseAbi
} from "viem";

// src/vcdm/abi/ABIEvent.ts
import {
  InvalidAbiDataToEncodeOrDecode as InvalidAbiDataToEncodeOrDecode2,
  InvalidAbiItem
} from "@vechain/sdk-errors";
import {
  encodeEventTopics,
  decodeEventLog as viemDecodeEventLog
} from "viem";

// src/vcdm/abi/ABIItem.ts
import {
  parseAbiItem,
  toFunctionHash,
  toFunctionSignature
} from "viem";
var ABIItem = class extends ABI {
  signature;
  stringSignature;
  /**
   * ABIItem constructor from item (Event, Function...) signature.
   *
   * @param {string | ViemABI} signature - The signature of the ABI item (Function, Event...).
   **/
  constructor(signature) {
    super();
    switch (typeof signature) {
      case "string":
        this.stringSignature = signature;
        break;
      case "object":
        this.stringSignature = toFunctionSignature(signature);
        break;
      default:
        this.stringSignature = "";
    }
    this.signature = typeof signature === "string" ? parseAbiItem([signature]) : signature;
  }
  /**
   * Returns and instance of an ABIItem from a signature.
   * @param ABIItemConstructor ABIItem constructor.
   * @param {string | ABIItemType} signature Signature of the ABIIItem.
   * @returns {T} An instance of the ABIItem.
   */
  static ofSignature(ABIItemConstructor, signature) {
    return new ABIItemConstructor(signature);
  }
  /**
   * Returns a string representation of a JSON object or a string.
   * @param {'json' | 'string'} formatType Either JSON or String
   * @returns The string representation of the ABI item.
   */
  format(formatType = "string") {
    return formatType === "json" ? JSON.stringify(this.signature) : this.stringSignature;
  }
  /**
   * The signature hash of the ABIItem.
   * @returns {string} The signature hash of the ABIItem.
   * @remarks Wrapper for {@link toFunctionHash}.
   **/
  get signatureHash() {
    return toFunctionHash(this.stringSignature);
  }
  /**
   * Compares the current ABIItem instance with another ABIItem instance.
   * @param {ABIItem} that The item to compare with.
   * @returns {number} A non-zero number if the current ABIItem is different to the other ABI or zero if they are equal.
   * @override {@link VeChainDataModel#compareTo}
   **/
  compareTo(that) {
    if (super.compareTo(that) !== 0) {
      return -1;
    }
    return this.stringSignature.localeCompare(that.stringSignature);
  }
};

// src/vcdm/abi/ABIEvent.ts
var ABIEvent = class _ABIEvent extends ABIItem {
  abiEvent;
  constructor(signature) {
    try {
      super(signature);
      this.abiEvent = this.signature;
    } catch (error) {
      throw new InvalidAbiItem(
        "ABIEvent constructor",
        "Initialization failed: Cannot create Event ABI. Event format is invalid.",
        {
          type: "event",
          value: signature
        },
        error
      );
    }
  }
  /**
   * Decode event log data using the event's ABI.
   *
   * @param abi - Event to decode.
   * @returns Decoding results.
   * @throws {InvalidAbiDataToEncodeOrDecode}
   */
  static parseLog(abi, eventData) {
    try {
      return viemDecodeEventLog({
        abi,
        data: eventData.data.toString(),
        topics: eventData.topics.map((topic) => {
          if (topic === null) {
            return topic;
          } else if (Array.isArray(topic)) {
            return topic.map((t) => t.toString());
          }
          return topic.toString();
        })
      });
    } catch (error) {
      throw new InvalidAbiDataToEncodeOrDecode2(
        "ABIEvent.parseLog",
        "Decoding failed: Data must be a valid hex string encoding a compliant ABI type.",
        {
          data: {
            abi,
            data: eventData.data,
            topics: eventData.topics
          }
        },
        error
      );
    }
  }
  /**
   * Decode event log data using the event's ABI.
   *
   * @param event - Event to decode.
   * @returns Decoding results.
   * @throws {InvalidAbiDataToEncodeOrDecode}
   */
  decodeEventLog(event) {
    try {
      return _ABIEvent.parseLog([this.abiEvent], event);
    } catch (error) {
      throw new InvalidAbiDataToEncodeOrDecode2(
        "ABIEvent.decodeEventLog",
        "Decoding failed: Data must be a valid hex string encoding a compliant ABI type.",
        { data: event },
        error
      );
    }
  }
  /**
   * Decode event log data as an array of values
   * @param {ABIEvent} event The data to decode.
   * @returns {unknown[]} The decoded data as array of values.
   */
  decodeEventLogAsArray(event) {
    try {
      const rawDecodedData = this.decodeEventLog(event);
      if (rawDecodedData.args === void 0) {
        return [];
      } else if (rawDecodedData.args instanceof Object) {
        return Object.values(rawDecodedData.args);
      }
      return rawDecodedData.args;
    } catch (error) {
      throw new InvalidAbiDataToEncodeOrDecode2(
        "ABIEvent.decodeEventLogAsArray",
        "Decoding failed: Data must be a valid hex string encoding a compliant ABI type.",
        { data: event },
        error
      );
    }
  }
  /**
   * Encode event log data returning the encoded data and topics.
   * @param dataToEncode - Data to encode.
   * @returns {ABIEventData} Encoded data along with topics.
   * @remarks There is no equivalent to encodeEventLog in viem {@link https://viem.sh/docs/ethers-migration}. Discussion started here {@link https://github.com/wevm/viem/discussions/2676}.
   */
  encodeEventLog(dataToEncode) {
    try {
      const topics = this.encodeFilterTopics(dataToEncode);
      const dataTypes = [];
      const dataValues = [];
      this.abiEvent.inputs.forEach((param, index) => {
        if (param.indexed ?? false) {
          return;
        }
        const value = dataToEncode[index];
        dataTypes.push(param);
        dataValues.push(value);
      });
      return {
        data: ABI.of(dataTypes, dataValues).toHex(),
        topics: topics.map((topic) => {
          if (topic === null) {
            return topic;
          } else if (Array.isArray(topic)) {
            return topic.map((t) => Hex.of(t));
          }
          return Hex.of(topic);
        })
      };
    } catch (error) {
      throw new InvalidAbiDataToEncodeOrDecode2(
        "ABIEvent.encodeEventLog",
        "Encoding failed: Data format is invalid. Event data must be correctly formatted for ABI-compliant encoding.",
        { dataToEncode },
        error
      );
    }
  }
  /**
   * Encode event log topics using the event's ABI.
   *
   * @param valuesToEncode - values to encode as topics. Non-indexed values are ignored.
   *                         Only the values of the indexed parameters are needed.
   * @returns Encoded topics array.
   * @throws {InvalidAbiDataToEncodeOrDecode}
   */
  encodeFilterTopics(valuesToEncode) {
    if (this.abiEvent.inputs.length < valuesToEncode.length) {
      throw new InvalidAbiDataToEncodeOrDecode2(
        "ABIEvent.encodeEventLog",
        "Encoding failed: Data format is invalid. Number of values to encode is greater than the inputs.",
        { valuesToEncode }
      );
    }
    try {
      return encodeEventTopics({
        abi: [this.abiEvent],
        args: valuesToEncode
      });
    } catch (error) {
      throw new InvalidAbiDataToEncodeOrDecode2(
        "ABIEvent.encodeEventLog",
        "Encoding failed: Data format is invalid. Event topics values must be correctly formatted for ABI-compliant encoding.",
        { valuesToEncode },
        error
      );
    }
  }
  /**
   * Encode event log topics using the event's ABI, replacing null values with undefined.
   * @param valuesToEncode - values to encode as topics. Non-indexed values are ignored.
   *                         Only the values of the indexed parameters are needed.
   * @returns Encoded topics array.
   * @throws {InvalidAbiDataToEncodeOrDecode}
   */
  encodeFilterTopicsNoNull(valuesToEncode) {
    const encodedTopics = this.encodeFilterTopics(
      valuesToEncode
    );
    return encodedTopics.map(
      (topic) => topic === null ? void 0 : topic
    );
  }
};

// src/vcdm/abi/ABIFunction.ts
import {
  InvalidAbiDataToEncodeOrDecode as InvalidAbiDataToEncodeOrDecode3,
  InvalidAbiItem as InvalidAbiItem2
} from "@vechain/sdk-errors";
import {
  decodeFunctionData,
  decodeFunctionResult,
  encodeFunctionData
} from "viem";
var ABIFunction = class extends ABIItem {
  abiFunction;
  constructor(signature) {
    try {
      super(signature);
      this.abiFunction = this.signature;
    } catch (error) {
      throw new InvalidAbiItem2(
        "ABIFunction constructor",
        "Initialization failed: Cannot create Function ABI. Function format is invalid.",
        {
          type: "function",
          value: signature
        },
        error
      );
    }
  }
  /**
   * Get the function selector.
   * @returns {string} The function selector.
   * @override {@link ABIItem#signatureHash}
   */
  get signatureHash() {
    return super.signatureHash.substring(0, 10);
  }
  /**
   * Decode data using the function's ABI.
   *
   * @param {Hex} data - Data to decode.
   * @returns Decoding results.
   * @throws {InvalidAbiDataToEncodeOrDecode}
   */
  decodeData(data) {
    try {
      return decodeFunctionData({
        abi: [this.abiFunction],
        data: data.toString()
      });
    } catch (error) {
      throw new InvalidAbiDataToEncodeOrDecode3(
        "ABIFunction.decodeData",
        "Decoding failed: Data must be a valid hex string encoding a compliant ABI type.",
        { data },
        error
      );
    }
  }
  /**
   * Encode data using the function's ABI.
   *
   * @param dataToEncode - Data to encode.
   * @returns {Hex} Encoded data.
   * @throws {InvalidAbiDataToEncodeOrDecode}
   */
  encodeData(dataToEncode) {
    try {
      return Hex.of(
        encodeFunctionData({
          abi: [this.abiFunction],
          args: dataToEncode
        })
      );
    } catch (e) {
      throw new InvalidAbiDataToEncodeOrDecode3(
        "ABIFunction.encodeData",
        "Encoding failed: Data format is invalid. Function data does not match the expected format for ABI type encoding.",
        { dataToEncode },
        e
      );
    }
  }
  /**
   * Decodes the output data from a transaction based on ABI (Application Binary Interface) specifications.
   * This method attempts to decode the given hex-like data into a readable format using the contract's interface.
   *
   * @param {Hex} data - The data to be decoded, typically representing the output of a contract function call.
   * @returns {DecodeFunctionResultReturnType} An object containing the decoded data.
   * @throws {InvalidAbiDataToEncodeOrDecode}
   *
   * @example
   * ```typescript
   *   const decoded = abiFunctionInstance.decodeResult(rawTransactionOutput);
   *   console.log('Decoded Output:', decoded);
   * ```
   */
  decodeResult(data) {
    try {
      return decodeFunctionResult({
        abi: [this.abiFunction],
        data: data.toString()
      });
    } catch (error) {
      throw new InvalidAbiDataToEncodeOrDecode3(
        "ABIFunction.decodeResult",
        "Decoding failed: Data must be a valid hex string encoding a compliant ABI type.",
        { data },
        error
      );
    }
  }
  /**
   * Decodes a function output returning an array of values.
   * @param {Hex} data The data to be decoded
   * @returns {unknown[]} The decoded data as array of values
   */
  decodeOutputAsArray(data) {
    const resultDecoded = this.decodeResult(data);
    if (this.abiFunction.outputs.length > 1) {
      return this.parseObjectValues(resultDecoded);
    } else if (this.abiFunction.outputs.length === 1 && this.abiFunction.outputs[0].type === "tuple") {
      return [this.parseObjectValues(resultDecoded)];
    }
    return [resultDecoded];
  }
};

// src/vcdm/abi/ABIContract.ts
var ABIContract = class _ABIContract extends ABI {
  abi;
  constructor(abi) {
    super();
    this.abi = abi;
  }
  /**
   * Creates an ABIContract instance from a viem ABI.
   * @param {ViemABI} abi representation of the contract.
   * @returns New instance of ABIContract.
   */
  static ofAbi(abi) {
    return new _ABIContract(abi);
  }
  /**
   * Creates an ABIContract instance from an ABI string.
   * @param {string} abi representation of the contract.
   * @returns New instance of ABIContract.
   */
  static ofStringAbi(abi) {
    return new _ABIContract(parseAbi([abi]));
  }
  /**
   * Returns the function with the given name.
   * @param {string} name The function's name.
   * @returns {ABIFunction} The function with the given name.
   * @throws {InvalidAbiItem}
   */
  getFunction(name) {
    const functionAbiItem = getAbiItem({
      abi: this.abi,
      name
    });
    if (functionAbiItem === null || functionAbiItem === void 0) {
      throw new InvalidAbiItem3(
        "ABIContract.getFunction()",
        `Function '${name}' not found in contract ABI.`,
        {
          type: "function",
          value: name
        }
      );
    }
    return new ABIFunction(functionAbiItem);
  }
  /**
   * Returns the event with the given name.
   * @param {string} name The event's name.
   * @returns {ABIEvent} The event with the given name.
   * @throws {InvalidAbiItem}
   */
  getEvent(name) {
    const eventAbiItem = getAbiItem({
      abi: this.abi,
      name
    });
    if (eventAbiItem === null || eventAbiItem === void 0) {
      throw new InvalidAbiItem3(
        "ABIContract.getEvent()",
        `Function '${name}' not found in contract ABI.`,
        {
          type: "event",
          value: name
        }
      );
    }
    return new ABIEvent(eventAbiItem);
  }
  /**
   * Encode function data that can be used to send a transaction.
   * @param {string} functionName The name of the function defined in the ABI.
   * @param {unknown[]} functionData The data to pass to the function.
   * @returns {Hex} The encoded data in hexadecimal that can be used to send a transaction.
   * @throws {InvalidAbiDataToEncodeOrDecode}
   */
  encodeFunctionInput(functionName, functionData) {
    try {
      const functionAbiItem = getAbiItem({
        abi: this.abi,
        name: functionName
      });
      const functionAbi = new ABIFunction(functionAbiItem);
      return functionAbi.encodeData(functionData);
    } catch (error) {
      throw new InvalidAbiDataToEncodeOrDecode4(
        "ABIContract.encodeFunctionInput()",
        `Encoding failed: Data format is invalid. Function data does not match the expected format for ABI type encoding.`,
        { functionName, functionData },
        error
      );
    }
  }
  /**
   * Decode the function data of an encoded function
   * @param {string} functionName The name of the function defined in the ABI.
   * @param {Hex} encodedFunctionInput The encoded function data.
   * @returns {DecodeFunctionDataReturnType} an array of the decoded function data
   * @throws {InvalidAbiDataToEncodeOrDecode}
   */
  decodeFunctionInput(functionName, encodedFunctionInput) {
    try {
      const functionAbiItem = getAbiItem({
        abi: this.abi,
        name: functionName
      });
      const functionAbi = new ABIFunction(functionAbiItem);
      return functionAbi.decodeData(encodedFunctionInput);
    } catch (error) {
      throw new InvalidAbiDataToEncodeOrDecode4(
        "ABIContract.decodeFunctionInput()",
        "Decoding failed: Data must be a valid hex string encoding a compliant ABI type.",
        { functionName, encodedFunctionInput },
        error
      );
    }
  }
  /**
   * Decodes the output from a contract function using the specified ABI and function name.
   * It takes the encoded function output and attempts to decode it according to the ABI definition.
   *
   * @param {string} functionName - The name of the function in the contract to decode the output for.
   * @param {Hex} encodedFunctionOutput - The encoded output data from the contract function.
   * @returns {DecodeFunctionResultReturnType} - The decoded output, which provides a user-friendly way
   * to interact with the decoded data.
   * @throws {InvalidAbiDataToEncodeOrDecode}
   *
   * @example
   * // Example of decoding output for a function called "getValue":
   * const decodedOutput = decodeFunctionOutput('getValue', encodedValue);
   *
   */
  decodeFunctionOutput(functionName, encodedFunctionOutput) {
    try {
      const functionAbiItem = getAbiItem({
        abi: this.abi,
        name: functionName
      });
      const functionAbi = new ABIFunction(functionAbiItem);
      return functionAbi.decodeResult(encodedFunctionOutput);
    } catch (error) {
      throw new InvalidAbiDataToEncodeOrDecode4(
        "ABIContract.decodeFunctionOutput()",
        "Decoding failed: Data must be a valid hex string encoding a compliant ABI type.",
        { functionName, encodedFunctionOutput },
        error
      );
    }
  }
  /**
   * Encodes event log data based on the provided event name, and data to encode.
   * @param {string} eventName - The name of the event to be encoded.
   * @param {unknown[]} eventArgs - An array of data to be encoded in the event log.
   * @returns {ABIEventData} An object containing the encoded data and topics.
   * @throws {InvalidAbiDataToEncodeOrDecode}
   */
  encodeEventLog(eventName, eventArgs) {
    try {
      const eventAbiItem = getAbiItem({
        abi: this.abi,
        name: eventName
      });
      const eventAbi = new ABIEvent(eventAbiItem);
      return eventAbi.encodeEventLog(eventArgs);
    } catch (error) {
      throw new InvalidAbiDataToEncodeOrDecode4(
        "ABIContract.encodeEventLog()",
        `Encoding failed: Data format is invalid. Event data does not match the expected format for ABI type encoding.`,
        { eventName, dataToEncode: eventArgs },
        error
      );
    }
  }
  /**
   * Decodes event log data based on the provided event name, and data/topics to decode.
   * @param {string} eventName - The name of the event to be decoded.
   * @param {ABIEventData} eventToDecode - An object containing the data and topics to be decoded.
   * @returns {DecodeEventLogReturnType} The decoded data of the event log.
   * @throws {InvalidAbiDataToEncodeOrDecode}
   */
  decodeEventLog(eventName, eventToDecode) {
    try {
      const eventAbiItem = getAbiItem({
        abi: this.abi,
        name: eventName
      });
      const eventAbi = new ABIEvent(eventAbiItem);
      return eventAbi.decodeEventLog(eventToDecode);
    } catch (error) {
      throw new InvalidAbiDataToEncodeOrDecode4(
        "ABIContract.encodeEventLog()",
        `Encoding failed: Data format is invalid. Event data does not match the expected format for ABI type encoding.`,
        { eventName, dataToDecode: eventToDecode },
        error
      );
    }
  }
  /**
   * Decodes a VeChain log based on the ABI definition.
   *
   * This method takes raw `data` and `topics` from a VeChain log and attempts
   * to decode them using the contract's ABI definition. If the decoding is successful,
   * it returns a log object representing the decoded information. If the decoding fails,
   * it throws a custom error with detailed information.
   *
   * @param {Hex} data - The hexadecimal string of the data field in the log.
   * @param {Hex[]} topics - An array of hexadecimal strings representing the topics of the log.
   * @returns {DecodeEventLogReturnType} - A log object representing the decoded log or null if decoding fails.
   * @throws {InvalidAbiDataToEncodeOrDecode}
   */
  parseLog(data, topics) {
    try {
      return ABIEvent.parseLog(this.abi, { data, topics });
    } catch (e) {
      throw new InvalidAbiDataToEncodeOrDecode4(
        "ABIContract.parseLog()",
        `Decoding failed: Data must be a valid hex string encoding a compliant ABI type.`,
        { data, topics },
        e
      );
    }
  }
  /**
   *
   * Parses the log data and topics into an array of values.
   *
   * @param {Hex} data - The hexadecimal string of the data field in the log.
   * @param {Hex[]} topics - An array of hexadecimal strings representing the topics of the log.
   * @returns {unknown[]} - An array of values of the decoded log data.
   */
  parseLogAsArray(data, topics) {
    const eventLogDecoded = this.parseLog(data, topics);
    if (eventLogDecoded.args === void 0) {
      return [];
    } else if (eventLogDecoded.args instanceof Object) {
      return Object.values(eventLogDecoded.args);
    }
    return eventLogDecoded.args;
  }
};

// src/vcdm/account/Account.ts
import { InvalidOperation as InvalidOperation3 } from "@vechain/sdk-errors";
var Account = class {
  address;
  balance;
  // Replace the string array with a Transaction class #1162
  transactions;
  type;
  constructor(address, balance, type = "EOA", transactions) {
    this.address = address;
    this.balance = balance;
    this.type = type;
    this.transactions = transactions ?? [];
  }
  /**
   * Throws an exception because the account cannot be represented as a big integer.
   * @returns {bigint} The BigInt representation of the account.
   * @throws {InvalidOperation} The account cannot be represented as a bigint.
   * @override {@link VeChainDataModel#bi}
   * @remarks The conversion to BigInt is not supported for an account.
   */
  get bi() {
    throw new InvalidOperation3(
      "Account.bi",
      "There is no big integer representation for an account.",
      { data: "" }
    );
  }
  /**
   * Throws an exception because the account cannot be represented as a byte array.
   * @returns {Uint8Array} The byte array representation of the account.
   * @throws {InvalidOperation} The account cannot be represented as a byte array.
   * @override {@link VeChainDataModel#bytes}
   * @remarks The conversion to byte array is not supported for an account.
   */
  get bytes() {
    throw new InvalidOperation3(
      "Account.bytes",
      "There is no bytes representation for an account.",
      { data: "" }
    );
  }
  /**
   * Throws an exception because the account cannot be represented as a number.
   * @returns {bigint} The number representation of the account.
   * @throws {InvalidOperation} The account cannot be represented as a number.
   * @override {@link VeChainDataModel#n}
   * @remarks The conversion to number is not supported for an account.
   */
  get n() {
    throw new InvalidOperation3(
      "Account.n",
      "There is no number representation for an account.",
      { data: "" }
    );
  }
  /**
   * Adds a transaction to the account.
   * @param {string} transaction The transaction to add.
   */
  addTransaction(transaction) {
    this.transactions.push(transaction);
  }
  /**
   * Compare this instance with `that` in a meaningful way.
   *
   * @param {Account} that object to compare.
   * @return a negative number if `this` < `that`, zero if `this` = `that`, a positive number if `this` > that`.
   * @override {@link VeChainDataModel#compareTo}
   */
  compareTo(that) {
    const typeDiff = this.type.localeCompare(that.type);
    if (typeDiff === 0) {
      const addressDiff = this.address.compareTo(that.address);
      if (addressDiff === 0) {
        const codeDiff = this.balance.code.compareTo(that.balance.code);
        if (codeDiff === 0) {
          return this.balance.value.compareTo(that.balance.value);
        }
        return codeDiff;
      }
      return addressDiff;
    }
    return typeDiff;
  }
  /**
   * Checks if the given value is equal to the current instance.
   *
   * @param {Account} that - The value to compare.
   * @returns {boolean} - True if the values are equal, false otherwise.
   * @override {@link VeChainDataModel#isEqual}
   */
  isEqual(that) {
    return this.compareTo(that) === 0;
  }
  /**
   * Returns a string representation of the account.
   *
   * @returns {string} A string representation of the account.
   */
  toString() {
    return `${this.type} Address: ${this.address.toString()} Balance: ${this.balance.value} ${this.balance.code}`;
  }
};

// src/vcdm/hash/Keccak256.ts
import { keccak_256 as nh_keccak_256 } from "@noble/hashes/sha3";
import { InvalidOperation as InvalidOperation5 } from "@vechain/sdk-errors";

// src/vcdm/HexInt.ts
import { InvalidDataType as InvalidDataType2 } from "@vechain/sdk-errors";
var HexInt = class _HexInt extends Hex {
  /**
   * Retrieves the value of n cast from this instance interpreted as the hexadecimal expression of a bigint value.
   *
   * @return {number} The value of n.
   *
   * @throws {InvalidDataType} If n is not within the safe number range, if the number representation of this
   * instance results approximated.
   *
   * @remarks This class makes equal instances created from the same value as number or as bigint.
   */
  get n() {
    const bi = this.bi;
    if (Number.MIN_SAFE_INTEGER <= bi && bi <= Number.MAX_SAFE_INTEGER) {
      return Number(bi);
    }
    throw new InvalidDataType2("HexInt.n", "not in the safe number range", {
      bi: `${bi}`,
      hex: this.toString()
    });
  }
  /**
   * Create a HexInt instance from a bigint, number, string, Uint8Array, or {@link Hex}.
   *
   * @param {bigint | number | string | Uint8Array | Hex} exp - The expression to be interpreted as an integer:
   * * bigint is always representable in hexadecimal base notation;
   * * number is converted to a bigint then represented in hexadecimal base notation;
   *   it throws {@link InvalidDataType} if not an integer value;
   * * string is parsed as the hexadecimal expression of a bigint value, optionally tagged with `0x`;
   * * Uint8Array is interpreted as the sequence of bytes expressing a bigint value;
   * * {@link Hex} is interpreted as expressing a bigint value.
   *
   * @returns {HexInt} - The new HexInt object representing the given `exp`.
   *
   * @throws {InvalidDataType} - If the given `exp` is not a valid hexadecimal integer expression,
   * if `exp` is a not integer number.
   *
   * @remarks This class makes equal instances created from the same value as number or as bigint.
   */
  static of(exp) {
    try {
      if (exp instanceof Hex) {
        return new _HexInt(exp.sign, exp.digits);
      }
      if (typeof exp === "number") {
        if (Number.isInteger(exp)) {
          const hex2 = Hex.of(BigInt(exp));
          return new _HexInt(hex2.sign, hex2.digits);
        }
        throw new InvalidDataType2("HexInt.of", "not an integer", {
          exp
        });
      }
      const hex = Hex.of(exp);
      return new _HexInt(hex.sign, hex.digits);
    } catch (e) {
      throw new InvalidDataType2(
        "HexInt.of",
        "not an hexadecimal integer expression",
        { exp: `${exp}`, e }
        // Needed to serialize bigint values.
      );
    }
  }
};

// src/vcdm/HexUInt.ts
import { InvalidDataType as InvalidDataType3 } from "@vechain/sdk-errors";
var HexUInt = class _HexUInt extends HexInt {
  /**
   * Regular expression for matching hexadecimal strings.
   * An empty input is represented as a empty digits.
   *
   * @type {RegExp}
   */
  static REGEX_HEXUINT = /^(0x)?[0-9a-f]*$/i;
  /**
   * Regular expression pattern to match a prefix indicating hexadecimal number.
   *
   * @type {RegExp}
   */
  static REGEX_HEXUINT_PREFIX = /^0x/i;
  /**
   * Checks if the given string expression is a valid unsigned hexadecimal value.
   *
   * @param {string} exp - The string representation of a hexadecimal value.
   *
   * @return {boolean} - True if the expression is a valid unsigned hexadecimal value, case-insensitive,
   * optionally prefixed with `0x`; false otherwise.
   */
  static isValid(exp) {
    return _HexUInt.REGEX_HEXUINT.test(exp);
  }
  /**
   * Determines whether the given string is a valid unsigned hexadecimal number prefixed with '0x'.
   *
   * @param {string} exp - The string to be evaluated.
   * @return {boolean} - True if the string is a valid unsigned hexadecimal number prefixed with '0x', otherwise false.
   */
  static isValid0x(exp) {
    return _HexUInt.REGEX_HEX_PREFIX.test(exp) && Hex.isValid(exp);
  }
  /**
   * Create a HexUInt instance from a bigint, number, string, Uint8Array, or {@link HexInt}.
   *
   * @param {bigint | number | string | Uint8Array | HexInt} exp - The expression to be interpreted as an unsigned integer:
   * * bigint is always representable in hexadecimal base notation,
   *   it throws {@link InvalidDataType} if not positive;
   * * number is converted to a bigint then represented in hexadecimal base notation,
   *   it throws {@link InvalidDataType} if not a positive integer value;
   * * string is parsed as the hexadecimal expression of a bigint value, optionally tagged with `0x`;
   *   it throws {@link InvalidDataType} if not positive;
   * * Uint8Array is interpreted as the sequence of bytes expressing a positive bigint value;
   * * {@link HexInt} is interpreted as expressing a bigint value,
   *   it throws {@link InvalidDataType} if not positive.
   *
   * @returns {HexUInt} he new HexInt object representing the given `exp`.
   *
   * @throws {InvalidDataType} If the given expression is not a valid hexadecimal positive integer expression.
   */
  static of(exp) {
    try {
      const hint = HexInt.of(exp);
      if (hint.sign >= Hex.POSITIVE) {
        return new _HexUInt(hint.sign, hint.digits);
      }
      throw new InvalidDataType3(
        "HexUInt.of",
        "not positive",
        { exp: `${exp}` }
        // Needed to serialize bigint values.
      );
    } catch (e) {
      throw new InvalidDataType3(
        "HexUInt.of",
        "not a hexadecimal positive integer expression",
        { exp: `${exp}`, e },
        // Needed to serialize bigint values.
        e
      );
    }
  }
};

// src/vcdm/Txt.ts
import { InvalidOperation as InvalidOperation4 } from "@vechain/sdk-errors";
var Txt = class _Txt extends String {
  /**
   * Decoder object used for decoding bytes as text data.
   *
   * @class
   * @constructor
   */
  static DECODER = new TextDecoder();
  /**
   * *Normalization Form Canonical Composition*
   * [Unicode Equivalence](https://en.wikipedia.org/wiki/Unicode_equivalence)
   * flag.
   *
   * @type {string}
   * @constant
   */
  static NFC = "NFC";
  /**
   * A TextEncoder instance used for encoding text to bytes.
   *
   * @type {TextEncoder}
   */
  static ENCODER = new TextEncoder();
  /**
   * Creates a new instance of this class representing the `exp` string
   * normalized according the *Canonical Composition Form*
   * [Unicode Equivalence](https://en.wikipedia.org/wiki/Unicode_equivalence).
   *
   * @param {string} exp - The expression to be passed to the constructor.
   * @protected
   * @constructor
   */
  constructor(exp) {
    super(exp.normalize(_Txt.NFC));
  }
  /**
   * Converts the current Txt string to a BigInt.
   *
   * @returns {bigint} The BigInt representation of the Txt string.
   *
   *  @throws {InvalidOperation} If the conversion to BigInt fails because this Txt string doesn't represent an integer.
   */
  get bi() {
    try {
      return BigInt(this.toString());
    } catch (e) {
      throw new InvalidOperation4(
        "Txt.bi()",
        "Can't cast to big integer",
        { txt: this.toString() },
        e
      );
    }
  }
  /**
   * Converts the current Txt string to a buffer of bytes.
   *
   * @returns {Uint8Array} The bytes representation of the Txt string.
   */
  get bytes() {
    return _Txt.ENCODER.encode(this.toString());
  }
  /**
   * Returns the value of n as a number.
   *
   * @returns {number} The value of n as a number.
   */
  /**
   * Converts the current Txt string to a number.
   *
   * @returns {number} The numeric value of the Txt string.
   *
   * @throws {InvalidOperation} If the conversion to number fails because this Txt string doesn't represent a decimal number.
   */
  get n() {
    return Number(this.toString());
  }
  /**
   * Compares the current instance to another instance of Txt.
   *
   * @param {Txt} that - The instance to compare with.
   *
   * @return {number} - A negative number if the current instance is less than the specified instance,
   *                    zero if they are equal, or a positive number if the current instance is greater.
   */
  compareTo(that) {
    return this.toString().localeCompare(that.toString());
  }
  /**
   * Checks if the current Txt object is equal to the given Txt object.
   *
   * @param {Txt} that - The Txt object to compare with.
   *
   *  @return {boolean} - True if the objects are equal, false otherwise.
   */
  isEqual(that) {
    return this.compareTo(that) === 0;
  }
  /**
   * Returns a string representation of the object.
   *
   * @returns {string} A string representation of the object.
   */
  toString() {
    return this.valueOf();
  }
  /**
   * Creates a new Txt instance from the provided expression.
   *
   * @param {bigint | number | string | Uint8Array} exp - The expression to convert to Txt:
   * * {@link bigint} is represented as a {@link NFC} encoded string expressing the value in base 10;
   * * {@link number} is represented as a {@link NFC} encoded string expressing the value in base 10;
   * * {@link string} is encoded as {@link NFC} string;
   * * {@link Uint8Array} is {@link NFC} decoded to a string.
   *
   * @returns {Txt} - A new Txt instance.
   */
  static of(exp) {
    if (exp instanceof Uint8Array) {
      return new _Txt(_Txt.DECODER.decode(exp));
    } else if (typeof exp === "bigint" || typeof exp === "number") {
      return new _Txt(exp.toString());
    }
    return new _Txt(exp);
  }
};

// src/vcdm/hash/Keccak256.ts
var Keccak256 = class _Keccak256 extends HexUInt {
  /**
   * Generates the [SHA-3](https://en.wikipedia.org/wiki/SHA-3) [KECCAK 256](https://keccak.team/keccak.html) hash of the given input.
   *
   * @param {bigint | number | string | Uint8Array | Hex} exp - The input value to hash.
   *
   * @returns {Sha256} - The [KECCAK 256](https://keccak.team/keccak.html) hash of the input value.
   *
   * @throws {InvalidOperation} - If a hash error occurs.
   *
   * @remarks Security auditable method, depends on
   * * [`nh_keccak_256`](https://github.com/paulmillr/noble-hashes#sha3-fips-shake-keccak).
   */
  static of(exp) {
    try {
      const hash2 = nh_keccak_256(HexUInt.of(exp).bytes);
      return new _Keccak256(Hex.POSITIVE, HexUInt.of(hash2).digits);
    } catch (e) {
      throw new InvalidOperation5("Keccak256.of", "hash error", {
        exp: `${exp}`,
        // Needed to serialize bigint values.
        e
      });
    }
  }
};
function keccak256(data, returnType = "buffer") {
  return returnType === "buffer" ? Keccak256.of(Txt.of(data).bytes).bytes : Keccak256.of(Txt.of(data).bytes).toString();
}

// src/hdkey/HDKey.ts
import * as s_bip32 from "@scure/bip32";
import * as s_bip39 from "@scure/bip39";
import * as nc_utils3 from "@noble/curves/abstract/utils";
import { base58 } from "@scure/base";

// src/vcdm/FixedPointNumber.ts
import { InvalidDataType as InvalidDataType4, InvalidOperation as InvalidOperation6 } from "@vechain/sdk-errors";
var FixedPointNumber = class _FixedPointNumber {
  /**
   * The default number of decimal places to use for fixed-point math.
   *
   * @see
   * [bignumber.js DECIMAL_PLACES](https://mikemcl.github.io/bignumber.js/#decimal-places)
   *
   * @constant {bigint}
   */
  static DEFAULT_FRACTIONAL_DECIMALS = 20n;
  /**
   * Not a Number.
   *
   * @remarks {@link fd} and {@link sv} not meaningful.
   *
   * @see [Number.NaN](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Number/NaN)
   *
   */
  static NaN = new _FixedPointNumber(0n, 0n, NaN);
  /**
   * The negative Infinity value.
   *
   * @remarks {@link fd} and {@link sv} not meaningful.
   *
   * @see [Number.NEGATIVE_INFINITY](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Number/NEGATIVE_INFINITY)
   */
  static NEGATIVE_INFINITY = new _FixedPointNumber(
    0n,
    0n,
    Number.NEGATIVE_INFINITY
  );
  /**
   * The positive Infinite value.
   *
   * @remarks {@link fd} and {@link sv} not meaningful.
   *
   * @see [Number.POSITIVE_INFINITY](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Number/POSITIVE_INFINITY)
   */
  static POSITIVE_INFINITY = new _FixedPointNumber(
    0n,
    0n,
    Number.POSITIVE_INFINITY
  );
  /**
   * Regular expression pattern for matching integers expressed as base 10 strings.
   */
  static REGEX_INTEGER = /^[-+]?\d+$/;
  /**
   * Regular expression for matching numeric values expressed as base 10 strings.
   */
  static REGEX_NUMBER = /(^[-+]?\d+(\.\d+)?)$|(^[-+]?\.\d+)$/;
  /**
   * Regular expression pattern for matching natural numbers expressed as base 10 strings.
   */
  static REGEX_NATURAL = /^\d+$/;
  /**
   * Represents the zero constant.
   */
  static ZERO = new _FixedPointNumber(0n, 0n, 0);
  /**
   * Edge Flag denotes the {@link NaN} or {@link NEGATIVE_INFINITY} or {@link POSITIVE_INFINITY} value.
   *
   * @remarks If `ef` is not zero, {@link fd} and {@link sv} are not meaningful.
   */
  ef;
  /**
   * Fractional Digits or decimal places.
   */
  fd;
  /**
   * Scaled Value = value * 10 ^ {@link fd}.
   */
  sv;
  /**
   * Returns the integer part of this FixedPointNumber value.
   *
   * @return {bigint} the integer part of this FixedPointNumber value.
   *
   * @throws {InvalidOperation} If the value is not finite.
   */
  get bi() {
    if (this.isFinite()) {
      return this.sv / 10n ** this.fd;
    }
    throw new InvalidOperation6(
      "FixedPointNumber.bi",
      "not finite value cannot cast to big integer",
      { this: this.toString() }
    );
  }
  /**
   * Returns the array of bytes representing the *Normalization Form Canonical Composition*
   * [Unicode Equivalence](https://en.wikipedia.org/wiki/Unicode_equivalence)
   * of this value expressed in decimal base.
   */
  get bytes() {
    return Txt.of(this.toString()).bytes;
  }
  /**
   * Return this value approximated as {@link number}.
   */
  get n() {
    if (this.isNaN()) return Number.NaN;
    if (this.isNegativeInfinite()) return Number.NEGATIVE_INFINITY;
    if (this.isPositiveInfinite()) return Number.POSITIVE_INFINITY;
    if (this.isZero()) return 0;
    return Number(this.sv) * 10 ** -Number(this.fd);
  }
  /**
   * Returns the new Fixed-Point Number (FixedPointNumber) instance having
   *
   * @param {bigint} fd - Number of Fractional Digits (or decimal places).
   * @param {bigint} sv - Scaled Value.
   * @param {number} [ef=0] - Edge Flag.
   */
  constructor(fd, sv, ef = 0) {
    this.fd = fd;
    this.ef = ef;
    this.sv = sv;
  }
  /**
   * Returns a FixedPointNumber whose value is the absolute value, i.e. the magnitude, of the value of this FixedPointNumber.
   *
   * @return {FixedPointNumber} the absolute value of this FixedPointNumber.
   *
   * @see [bignumber.js absoluteValue](https://mikemcl.github.io/bignumber.js/#abs)
   */
  abs() {
    if (this.isNaN()) return _FixedPointNumber.NaN;
    if (this.isNegativeInfinite())
      return _FixedPointNumber.POSITIVE_INFINITY;
    return new _FixedPointNumber(
      this.fd,
      this.sv < 0n ? -this.sv : this.sv,
      this.ef
    );
  }
  /**
   * Compares this instance with `that` FixedPointNumber instance.
   * * Returns 0 if this is equal to `that` FixedPointNumber, including infinite with equal sign;
   * * Returns -1, if this is -Infinite or less than `that` FixedPointNumber;,
   * * Returns 1 if this is +Infinite or greater than `that` FixedPointNumber.
   *
   * @param {FixedPointNumber} that - The instance to compare with this instance.
   * @return {number} Returns -1, 0, or 1 if this instance is less than, equal to, or greater
   * than the specified instance, respectively.
   * @throw InvalidOperation If this or `that` FixedPointNumber is {@link NaN}.
   *
   * @see [bignumber.js comparedTo](https://mikemcl.github.io/bignumber.js/#cmp)
   */
  compareTo(that) {
    if (this.isNaN() || that.isNaN())
      throw new InvalidOperation6(
        "FixedPointNumber.compareTo",
        "compare between NaN",
        {
          this: `${this}`,
          that: `${that}`
        }
      );
    if (this.isNegativeInfinite())
      return that.isNegativeInfinite() ? 0 : -1;
    if (this.isPositiveInfinite()) return that.isPositiveInfinite() ? 0 : 1;
    if (that.isNegativeInfinite()) return 1;
    if (that.isPositiveInfinite()) return -1;
    const fd = this.fd > that.fd ? this.fd : that.fd;
    const delta = this.dp(fd).sv - that.dp(fd).sv;
    return delta < 0n ? -1 : delta === 0n ? 0 : 1;
  }
  /**
   * Compares this instance with `that` FixedPointNumber instance.
   * * **Returns `null` if either instance is NaN;**
   * * Returns 0 if this is equal to `that` FixedPointNumber, including infinite with equal sign;
   * * Returns -1, if this is -Infinite or less than `that` FixedPointNumber;,
   * * Returns 1 if this is +Infinite or greater than `that` FixedPointNumber.
   *
   * @param {FixedPointNumber} that - The instance to compare with this instance.
   * @return {null | number} A null if either instance is NaN;
   * -1, 0, or 1 if this instance is less than, equal to, or greater
   * than the specified instance, respectively.
   *
   * @remarks This method uses internally {@link compareTo} wrapping the {@link InvalidOperation} exception
   * when comparing between {@link NaN} values to behave according the
   * [[bignumber.js comparedTo](https://mikemcl.github.io/bignumber.js/#cmp)] rules.
   */
  comparedTo(that) {
    try {
      return this.compareTo(that);
    } catch (e) {
      return null;
    }
  }
  /**
   * Returns a FixedPointNumber whose value is the value of this FixedPointNumber divided by `that` FixedPointNumber.
   *
   * Limit cases
   * * 0 / 0 = NaN
   * * NaN / ±n = NaN
   * * ±Infinity / ±Infinity = NaN
   * * +n / NaN = NaN
   * * +n / ±Infinity = 0
   * * -n / 0 = -Infinity
   * * +n / 0 = +Infinity
   *
   * @param {FixedPointNumber} that - The fixed-point number to divide by.
   * @return {FixedPointNumber} The result of the division.
   *
   * @remarks The precision is the greater of the precision of the two operands.
   *
   * @see [bignumber.js dividedBy](https://mikemcl.github.io/bignumber.js/#div)
   */
  div(that) {
    if (this.isNaN() || that.isNaN()) return _FixedPointNumber.NaN;
    if (this.isNegativeInfinite())
      return that.isInfinite() ? _FixedPointNumber.NaN : that.isPositive() ? _FixedPointNumber.NEGATIVE_INFINITY : _FixedPointNumber.POSITIVE_INFINITY;
    if (this.isPositiveInfinite())
      return that.isInfinite() ? _FixedPointNumber.NaN : that.isPositive() ? _FixedPointNumber.POSITIVE_INFINITY : _FixedPointNumber.NEGATIVE_INFINITY;
    if (that.isInfinite()) return _FixedPointNumber.ZERO;
    if (that.isZero())
      return this.isZero() ? _FixedPointNumber.NaN : this.isNegative() ? _FixedPointNumber.NEGATIVE_INFINITY : _FixedPointNumber.POSITIVE_INFINITY;
    const fd = this.fd > that.fd ? this.fd : that.fd;
    return new _FixedPointNumber(
      fd,
      _FixedPointNumber.div(fd, this.dp(fd).sv, that.dp(fd).sv)
    );
  }
  /**
   * Divides the given dividend by the given divisor, adjusted by a factor based on fd.
   *
   * @param {bigint} fd - The factor determining the power of 10 to apply to the dividend.
   * @param {bigint} dividend - The number to be divided.
   * @param {bigint} divisor - The number by which to divide the dividend.
   *
   * @return {bigint} - The result of the division, adjusted by the given factor fd.
   */
  static div(fd, dividend, divisor) {
    return 10n ** fd * dividend / divisor;
  }
  /**
   * Adjusts the precision of the floating-point number by the specified
   * number of decimal places.
   *
   * @param {bigint | number} decimalPlaces - The number of decimal places to adjust to.
   * @return {FixedPointNumber} A new FixedPointNumber instance with the adjusted precision.
   */
  dp(decimalPlaces) {
    const fp = BigInt(decimalPlaces);
    const dd = fp - this.fd;
    if (dd < 0) {
      return new _FixedPointNumber(fp, this.sv / 10n ** -dd);
    } else {
      return new _FixedPointNumber(fp, this.sv * 10n ** dd);
    }
  }
  /**
   * Returns `true `if the value of thisFPN is equal to the value of `that` FixedPointNumber, otherwise returns `false`.
   *
   * As with JavaScript, `NaN` does not equal `NaN`.
   *
   * @param {FixedPointNumber} that - The FixedPointNumber to compare against.
   * @return {boolean} `true` if the FixedPointNumber numbers are equal, otherwise `false`.
   *
   * @remarks This method uses {@link comparedTo} internally.
   *
   * @see [bigbumber.js isEqualTo](https://mikemcl.github.io/bignumber.js/#eq)
   */
  eq(that) {
    return this.comparedTo(that) === 0;
  }
  /**
   * Returns `true` if the value of this FixedPointNumber is greater than `that` FixedPointNumber`, otherwise returns `false`.
   *
   * @param {FixedPointNumber} - that The FixedPointNumber to compare against.
   * @return {boolean} `true` if this FixedPointNumber is greater than `that` FixedPointNumber, otherwise `false`.
   *
   * @remarks This method uses {@link comparedTo} internally.
   *
   * @see [bignummber.js isGreaterThan](https://mikemcl.github.io/bignumber.js/#gt)
   */
  gt(that) {
    const cmp = this.comparedTo(that);
    return cmp !== null && cmp > 0;
  }
  /**
   * Returns `true` if the value of this FixedPointNumber is greater or equal than `that` FixedPointNumber`, otherwise returns `false`.
   *
   * @param {FixedPointNumber} that - The FixedPointNumber to compare against.
   * @return {boolean} `true` if this FixedPointNumber is greater or equal than `that` FixedPointNumber, otherwise `false`.
   *
   * @remarks This method uses {@link comparedTo} internally.
   *
   * @see [bignumber.js isGreaterThanOrEqualTo](https://mikemcl.github.io/bignumber.js/#gte)
   */
  gte(that) {
    const cmp = this.comparedTo(that);
    return cmp !== null && cmp >= 0;
  }
  /**
   * Returns a fixed-point number whose value is the integer part of dividing the value of this fixed-point number
   * by `that` fixed point number.
   *
   * Limit cases
   * * 0 / 0 = NaN
   * * NaN / ±n = NaN
   * * ±Infinity / ±Infinity = NaN
   * * +n / NaN = NaN
   * * +n / ±Infinite = 0
   * * -n / 0 = -Infinite
   * * +n / 0 = +Infinite
   *
   * @param {FixedPointNumber} that - The fixed-point number to divide by.
   * @return {FixedPointNumber} The result of the division.
   *
   * @remarks The precision is the greater of the precision of the two operands.
   *
   * @see [bignumber.js dividedToIntegerBy](https://mikemcl.github.io/bignumber.js/#divInt)
   */
  idiv(that) {
    if (this.isNaN() || that.isNaN()) return _FixedPointNumber.NaN;
    if (this.isNegativeInfinite())
      return that.isInfinite() ? _FixedPointNumber.NaN : that.isPositive() ? _FixedPointNumber.NEGATIVE_INFINITY : _FixedPointNumber.POSITIVE_INFINITY;
    if (this.isPositiveInfinite())
      return that.isInfinite() ? _FixedPointNumber.NaN : that.isPositive() ? _FixedPointNumber.POSITIVE_INFINITY : _FixedPointNumber.NEGATIVE_INFINITY;
    if (that.isInfinite()) return _FixedPointNumber.ZERO;
    if (that.isZero())
      return this.isZero() ? _FixedPointNumber.NaN : this.isNegative() ? _FixedPointNumber.NEGATIVE_INFINITY : _FixedPointNumber.POSITIVE_INFINITY;
    const fd = this.fd > that.fd ? this.fd : that.fd;
    return new _FixedPointNumber(
      fd,
      _FixedPointNumber.idiv(fd, this.dp(fd).sv, that.dp(fd).sv)
    );
  }
  /**
   * Performs integer division on two big integers and scales the result by a factor of 10 raised to the power of fd.
   *
   * @param {bigint} fd - The power to which 10 is raised to scale the result.
   * @param {bigint} dividend - The number to be divided.
   * @param {bigint} divisor - The number by which dividend is divided.
   * @return {bigint} - The scaled result of the integer division.
   */
  static idiv(fd, dividend, divisor) {
    return dividend / divisor * 10n ** fd;
  }
  /**
   * Returns `true `if the value of thisFPN is equal to the value of `that` FixedPointNumber, otherwise returns `false`.
   *
   * As with JavaScript, `NaN` does not equal `NaN`.
   *
   * @param {FixedPointNumber} that - The FixedPointNumber to compare against.
   * @return {boolean} `true` if the FixedPointNumber numbers are equal, otherwise `false`.
   *
   * @remarks This method uses {@link eq} internally.
   */
  isEqual(that) {
    return this.eq(that);
  }
  /**
   * Returns `true` if the value of this FixedPointNumber is a finite number, otherwise returns `false`.
   *
   * The only possible non-finite values of a FixedPointNumber are {@link NaN}, {@link NEGATIVE_INFINITY} and {@link POSITIVE_INFINITY}.
   *
   * @return `true` if the value of this FixedPointNumber is a finite number, otherwise returns `false`.
   *
   * @see [bignumber.js isFinite](https://mikemcl.github.io/bignumber.js/#isF)
   */
  isFinite() {
    return this.ef === 0;
  }
  /**
   * Return `true` if the value of this FixedPointNumber is {@link NEGATIVE_INFINITY} and {@link POSITIVE_INFINITY},
   * otherwise returns false.
   *
   * @return true` if the value of this FixedPointNumber is {@link NEGATIVE_INFINITY} and {@link POSITIVE_INFINITY},
   */
  isInfinite() {
    return this.isNegativeInfinite() || this.isPositiveInfinite();
  }
  /**
   * Returns `true` if the value of this FixedPointNumber is an integer,
   * otherwise returns `false`.
   *
   * @return `true` if the value of this FixedPointNumber is an integer.
   *
   * @see [bignumber.js isInteger](https://mikemcl.github.io/bignumber.js/#isInt)
   */
  isInteger() {
    if (this.isFinite()) {
      return this.sv % 10n ** this.fd === 0n;
    }
    return false;
  }
  /**
   * Checks if a given string expression is an integer in base 10 notation,
   * considering `-` for negative and `+` optional for positive values.
   *
   * @param {string} exp - The string expression to be tested.
   *
   * @return {boolean} `true` if the expression is an integer,
   * `false` otherwise.
   */
  static isIntegerExpression(exp) {
    return this.REGEX_INTEGER.test(exp);
  }
  /**
   *  Returns `true` if the value of this FixedPointNumber is `NaN`, otherwise returns `false`.
   *
   *  @return `true` if the value of this FixedPointNumber is `NaN`, otherwise returns `false`.
   *
   *  @see [bignumber.js isNaN](https://mikemcl.github.io/bignumber.js/#isNaN)
   */
  isNaN() {
    return Number.isNaN(this.ef);
  }
  /**
   * Checks if a given string expression is a natural (unsigned positive integer)
   * number in base 10 notation.
   *
   * @param {string} exp - The string expression to be tested.
   *
   * @return {boolean} `true` if the expression is a natural number,
   * `false` otherwise.
   */
  static isNaturalExpression(exp) {
    return this.REGEX_NATURAL.test(exp);
  }
  /**
   * Returns `true` if the sign of this FixedPointNumber is negative, otherwise returns `false`.
   *
   * @return `true` if the sign of this FixedPointNumber is negative, otherwise returns `false`.
   *
   * @see [bignumber.js isNegative](https://mikemcl.github.io/bignumber.js/#isNeg)
   */
  isNegative() {
    return this.isFinite() && this.sv < 0n || this.isNegativeInfinite();
  }
  /**
   * Returns `true` if this FixedPointNumber value is {@link NEGATIVE_INFINITY}, otherwise returns `false`.
   */
  isNegativeInfinite() {
    return this.ef === Number.NEGATIVE_INFINITY;
  }
  /**
   * Checks if a given string expression is a number in base 10 notation,
   * considering `-` for negative and `+` optional for positive values.
   *
   * The method returns `true` for the following cases.
   * - Whole numbers:
   *   - Positive whole numbers, optionally signed: 1, +2, 3, ...
   *   - Negative whole numbers: -1, -2, -3, ...
   * - Decimal numbers:
   *   - Positive decimal numbers, optionally signed: 1.0, +2.5, 3.14, ...
   *   - Negative decimal numbers: -1.0, -2.5, -3.14, ...
   *   - Decimal numbers without whole part:
   *     - Positive decimal numbers, optionally signed: .1, +.5, .75, ...
   *     - Negative decimal numbers: -.1, -.5, -.75, ...
   *
   * @param exp - The string expression to be checked.
   *
   * @return `true` is `exp` represents a number, otherwise `false`.
   */
  static isNumberExpression(exp) {
    return _FixedPointNumber.REGEX_NUMBER.test(exp);
  }
  /**
   * Returns `true` if the sign of this FixedPointNumber is positive, otherwise returns `false`.
   *
   * @return `true` if the sign of this FixedPointNumber is positive, otherwise returns `false`.
   *
   * @see [bignumber.js isPositive](https://mikemcl.github.io/bignumber.js/#isPos)
   */
  isPositive() {
    return this.isFinite() && this.sv >= 0n || this.isPositiveInfinite();
  }
  /**
   * Returns `true` if this FixedPointNumber value is {@link POSITIVE_INFINITY}, otherwise returns `false`.
   *
   * @return `true` if this FixedPointNumber value is {@link POSITIVE_INFINITY}, otherwise returns `false`.
   */
  isPositiveInfinite() {
    return this.ef === Number.POSITIVE_INFINITY;
  }
  /**
   * Returns `true` if the value of this FixedPointNumber is zero or minus zero, otherwise returns `false`.
   *
   * @return `true` if the value of this FixedPointNumber is zero or minus zero, otherwise returns `false`.
   *
   * [see bignumber.js isZero](https://mikemcl.github.io/bignumber.js/#isZ)
   */
  isZero() {
    return this.isFinite() && this.sv === 0n;
  }
  /**
   * Returns `true` if the value of this FixedPointNumber is less than the value of `that` FixedPointNumber, otherwise returns `false`.
   *
   * @param {FixedPointNumber} that - The FixedPointNumber to compare against.
   *
   * @return {boolean} `true` if the value of this FixedPointNumber is less than the value of `that` FixedPointNumber, otherwise returns `false`.
   *
   * @remarks This method uses {@link comparedTo} internally.
   *
   * @see [bignumber.js isLessThan](https://mikemcl.github.io/bignumber.js/#lt)
   */
  lt(that) {
    const cmp = this.comparedTo(that);
    return cmp !== null && cmp < 0;
  }
  /**
   * Returns `true` if the value of this FixedPointNumber is less than or equal to the value of `that` FixedPointNumber,
   * otherwise returns `false`.
   *
   * @param {FixedPointNumber} that - The FixedPointNumber to compare against.
   * @return {boolean} `true` if the value of this FixedPointNumber is less than or equal to the value of `that` FixedPointNumber,
   * otherwise returns `false`.
   *
   * @remarks This method uses {@link comparedTo} internally.
   *
   * @see [bignumber.js isLessThanOrEqualTo](https://mikemcl.github.io/bignumber.js/#lte)
   */
  lte(that) {
    const cmp = this.comparedTo(that);
    return cmp !== null && cmp <= 0;
  }
  /**
   * Returns a FixedPointNumber whose value is the value of this FixedPointNumber minus `that` FixedPointNumber.
   *
   * Limit cases
   * * NaN - ±n = NaN
   * * ±n - NaN = NaN
   * * -Infinity - -Infinity = NaN
   * * -Infinity - +n = -Infinity
   * * +Infinity - +Infinity = NaN
   * * +Infinity - +n = +Infinity
   *
   * @param {FixedPointNumber} that - The fixed-point number to subtract.
   * @return {FixedPointNumber} The result of the subtraction. The return value is always exact and unrounded.
   *
   * @remarks The precision is the greater of the precision of the two operands.
   *
   * @see [bignumber.js minus](https://mikemcl.github.io/bignumber.js/#minus)
   */
  minus(that) {
    if (this.isNaN() || that.isNaN()) return _FixedPointNumber.NaN;
    if (this.isNegativeInfinite())
      return that.isNegativeInfinite() ? _FixedPointNumber.NaN : _FixedPointNumber.NEGATIVE_INFINITY;
    if (this.isPositiveInfinite())
      return that.isPositiveInfinite() ? _FixedPointNumber.NaN : _FixedPointNumber.POSITIVE_INFINITY;
    const fd = this.fd > that.fd ? this.fd : that.fd;
    return new _FixedPointNumber(fd, this.dp(fd).sv - that.dp(fd).sv);
  }
  /**
   * Returns a FixedPointNumber whose value is the value of this FixedPointNumber modulo `that` FixedPointNumber,
   * i.e. the integer remainder of dividing this FixedPointNumber by `that`.
   *
   * Limit cases
   * * NaN % ±n = NaN
   * * ±n % NaN = NaN
   * * ±Infinity % n = NaN
   * * n % ±Infinity = NaN
   *
   * @param that {FixedPointNumber} - The fixed-point number to divide by.
   * @return {FixedPointNumber} the integer remainder of dividing this FixedPointNumber by `that`.
   *
   * @remarks The precision is the greater of the precision of the two operands.
   *
   * @see [bignumber.js modulo](https://mikemcl.github.io/bignumber.js/#mod)
   */
  modulo(that) {
    if (this.isNaN() || that.isNaN()) return _FixedPointNumber.NaN;
    if (this.isInfinite() || that.isInfinite()) return _FixedPointNumber.NaN;
    if (that.isZero()) return _FixedPointNumber.NaN;
    const fd = this.fd > that.fd ? this.fd : that.fd;
    let modulo = this.abs().dp(fd).sv;
    const divisor = that.abs().dp(fd).sv;
    while (modulo >= divisor) {
      modulo -= divisor;
    }
    return new _FixedPointNumber(fd, modulo);
  }
  /**
   * Multiplies two big integer values and divides by a factor of ten raised to a specified power.
   *
   * @param {bigint} multiplicand - The first number to be multiplied.
   * @param {bigint} multiplicator - The second number to be multiplied.
   * @param {bigint} fd - The power of ten by which the product is to be divided.
   *
   * @return {bigint} The result of the multiplication divided by ten raised to the specified power.
   */
  static mul(multiplicand, multiplicator, fd) {
    return multiplicand * multiplicator / 10n ** fd;
  }
  /**
   * Returns a new instance of FixedPointNumber whose value is the value of this FixedPointNumber value
   * negated, i.e. multiplied by -1.
   *
   * @see [bignumber.js negated](https://mikemcl.github.io/bignumber.js/#neg)
   */
  negated() {
    if (this.isNegativeInfinite())
      return _FixedPointNumber.POSITIVE_INFINITY;
    if (this.isPositiveInfinite())
      return _FixedPointNumber.NEGATIVE_INFINITY;
    return new _FixedPointNumber(this.fd, -this.sv, this.ef);
  }
  /**
   * Constructs a new instance of FixedPointNumber (Fixed Point Number) parsing the
   * `exp` numeric expression in base 10 and representing the value with the
   * precision of `decimalPlaces` fractional decimal digits.
   *
   * @param {bigint|number|string} exp - The value to represent.
   * It can be a bigint, number, or string representation of the number.
   * @param {bigint} [decimalPlaces=this.DEFAULT_FRACTIONAL_DECIMALS] - The
   * number of fractional decimal digits to be used to represent the value.
   *
   * @return {FixedPointNumber} A new instance of FixedPointNumber with the given parameters.
   *
   * @throws {InvalidDataType} If `exp` is not a numeric expression.
   */
  static of(exp, decimalPlaces = this.DEFAULT_FRACTIONAL_DECIMALS) {
    try {
      if (Number.isNaN(exp))
        return new _FixedPointNumber(decimalPlaces, 0n, Number.NaN);
      if (exp === Number.NEGATIVE_INFINITY)
        return new _FixedPointNumber(
          decimalPlaces,
          -1n,
          Number.NEGATIVE_INFINITY
        );
      if (exp === Number.POSITIVE_INFINITY)
        return new _FixedPointNumber(
          decimalPlaces,
          1n,
          Number.POSITIVE_INFINITY
        );
      return new _FixedPointNumber(
        decimalPlaces,
        this.txtToSV(exp.toString(), decimalPlaces)
      );
    } catch (e) {
      throw new InvalidDataType4(
        "FixedPointNumber.of",
        "not a number",
        { exp },
        e
      );
    }
  }
  /**
   * Returns a FixedPointNumber whose value is the value of this FixedPointNumber plus `that` FixedPointNumber.
   *
   * Limit cases
   * * NaN + ±n = NaN
   * * ±n + NaN = NaN
   * * -Infinity + -Infinity = -Infinity
   * * -Infinity + +Infinity = NaN
   * * +Infinity + -Infinity = NaN
   * * +Infinity + +Infinity = +Infinity
   *
   * @param {FixedPointNumber} that - The fixed-point number to add to the current number.
   * @return {FixedPointNumber} The result of the addition. The return value is always exact and unrounded.
   *
   * @remarks The precision is the greater of the precision of the two operands.
   *
   * @see [bignumber.js plus](https://mikemcl.github.io/bignumber.js/#plus)
   */
  plus(that) {
    if (this.isNaN() || that.isNaN()) return _FixedPointNumber.NaN;
    if (this.isNegativeInfinite())
      return that.isPositiveInfinite() ? _FixedPointNumber.NaN : _FixedPointNumber.NEGATIVE_INFINITY;
    if (this.isPositiveInfinite())
      return that.isNegativeInfinite() ? _FixedPointNumber.NaN : _FixedPointNumber.POSITIVE_INFINITY;
    const fd = this.fd > that.fd ? this.fd : that.fd;
    return new _FixedPointNumber(fd, this.dp(fd).sv + that.dp(fd).sv);
  }
  /**
   * Returns a FixedPointNumber whose value is the value of this FixedPointNumber raised to the power of `that` FixedPointNumber.
   *
   * Limit cases
   * * NaN ^ e = NaN
   * * b ^ NaN = NaN
   * * b ^ -Infinite = 0
   * * b ^ 0 = 1
   * * b ^ +Infinite = +Infinite
   * * ±Infinite ^ -e = 0
   * * ±Infinite ^ +e = +Infinite
   *
   * @param {FixedPointNumber} that - The exponent as a fixed-point number.
   * It can be negative, it can be not an integer value
   * ([bignumber.js pow](https://mikemcl.github.io/bignumber.js/#pow)
   * doesn't support not integer exponents).
   * @return {FixedPointNumber} - The result of raising this fixed-point number to the power of the given exponent.
   *
   * @remarks The precision is the greater of the precision of the two operands.
   * @remarks In fixed-precision math, the comparisons between powers of operands having different fractional
   * precision can lead to differences.
   *
   * @see [bignumber.js exponentiatedBy](https://mikemcl.github.io/bignumber.js/#pow)
   */
  pow(that) {
    if (this.isNaN() || that.isNaN()) return _FixedPointNumber.NaN;
    if (this.isInfinite())
      return that.isZero() ? _FixedPointNumber.of(1) : that.isNegative() ? _FixedPointNumber.ZERO : _FixedPointNumber.POSITIVE_INFINITY;
    if (that.isNegativeInfinite()) return _FixedPointNumber.ZERO;
    if (that.isPositiveInfinite())
      return _FixedPointNumber.POSITIVE_INFINITY;
    const fd = this.fd > that.fd ? this.fd : that.fd;
    return new _FixedPointNumber(
      fd,
      _FixedPointNumber.pow(fd, this.dp(fd).sv, that.dp(fd).sv)
    );
  }
  /**
   * Computes the power of a given base raised to a specified exponent.
   *
   * @param {bigint} fd - The scale factor for decimal precision.
   * @param {bigint} base - The base number to be raised to the power.
   * @param {bigint} exponent - The exponent to which the base should be raised.
   * @return {bigint} The result of base raised to the power of exponent, scaled by the scale factor.
   */
  static pow(fd, base, exponent) {
    const sf = 10n ** fd;
    if (exponent < 0n) {
      return _FixedPointNumber.pow(
        fd,
        _FixedPointNumber.div(fd, sf, base),
        -exponent
      );
    }
    if (exponent === 0n) {
      return 1n * sf;
    }
    if (exponent === sf) {
      return base;
    }
    return _FixedPointNumber.pow(
      fd,
      this.mul(base, base, fd),
      exponent - sf
    );
  }
  /**
   * Computes the square root of a given positive bigint value using a fixed-point iteration method.
   *
   * @param {bigint} value - The positive bigint value for which the square root is to be calculated.
   * @param {bigint} fd - The iteration factor determinant.
   * @return {bigint} The calculated square root of the input bigint value.
   *
   * @throws {RangeError} If the input value is negative.
   */
  static sqr(value, fd) {
    if (value < 0n) {
      throw new RangeError(`Value must be positive`);
    }
    const sf = fd * 10n;
    let iteration = 0;
    let actualResult = value;
    let storedResult = 0n;
    while (actualResult !== storedResult && iteration < sf) {
      storedResult = actualResult;
      actualResult = (actualResult + _FixedPointNumber.div(fd, value, actualResult)) / 2n;
      iteration++;
    }
    return actualResult;
  }
  /**
   * Returns a FixedPointNumber whose value is the square root of the value of this FixedPointNumber
   *
   * Limit cases
   * * NaN = NaN
   * * +Infinite = +Infinite
   * * -n = NaN
   *
   * @return {FixedPointNumber} The square root of the number.
   *
   * @see [bignumber.js sqrt](https://mikemcl.github.io/bignumber.js/#sqrt)
   */
  sqrt() {
    if (this.isNaN()) return _FixedPointNumber.NaN;
    if (this.isNegativeInfinite()) return _FixedPointNumber.NaN;
    if (this.isPositiveInfinite())
      return _FixedPointNumber.POSITIVE_INFINITY;
    try {
      return new _FixedPointNumber(
        this.fd,
        _FixedPointNumber.sqr(this.sv, this.fd)
      );
    } catch (e) {
      return _FixedPointNumber.NaN;
    }
  }
  /**
   * Returns a FixedPointNumber whose value is the value of this FixedPointNumber multiplied by `that` FixedPointNumber.
   *
   * Limits cases
   * * NaN * n = NaN
   * * n * NaN = NaN
   * * -Infinite * -n = +Infinite
   * * -Infinite * +n = -Infinite
   * * +Infinite * -n = -Infinite
   * * +Infinite * +n = +Infinite
   *
   * @param {FixedPointNumber} that - The fixed-point number to multiply with this number.
   * @return {FixedPointNumber} a FixedPointNumber whose value is the value of this FixedPointNumber multiplied by `that` FixedPointNumber.
   *
   * @remarks The precision is the greater of the precision of the two operands.
   *
   * @see [bignumber.js multipliedBy](https://mikemcl.github.io/bignumber.js/#times)
   */
  times(that) {
    if (this.isNaN() || that.isNaN()) return _FixedPointNumber.NaN;
    if (this.isNegativeInfinite())
      return that.isNegative() ? _FixedPointNumber.POSITIVE_INFINITY : _FixedPointNumber.NEGATIVE_INFINITY;
    if (this.isPositiveInfinite())
      return that.isNegative() ? _FixedPointNumber.NEGATIVE_INFINITY : _FixedPointNumber.POSITIVE_INFINITY;
    const fd = this.fd > that.fd ? this.fd : that.fd;
    return new _FixedPointNumber(
      fd,
      _FixedPointNumber.mul(this.dp(fd).sv, that.dp(fd).sv, fd)
    );
  }
  /**
   * Converts the fixed-point number to its string representation.
   *
   * @param {string} [decimalSeparator='.'] - The character to use as the decimal separator in the string representation. Default is '.'.
   * @return {string} A string representation of the fixed-point number.
   */
  toString(decimalSeparator = ".") {
    if (this.ef === 0) {
      const sign = this.sv < 0n ? "-" : "";
      const digits = this.sv < 0n ? (-this.sv).toString() : this.sv.toString();
      const padded = digits.padStart(Number(this.fd), "0");
      const decimals = this.fd > 0 ? padded.slice(Number(-this.fd)) : "";
      const integers = padded.slice(0, padded.length - decimals.length);
      const integersShow = integers.length < 1 ? "0" : integers;
      const decimalsShow = _FixedPointNumber.trimEnd(decimals);
      return sign + integersShow + (decimalsShow.length > 0 ? decimalSeparator + decimalsShow : "");
    }
    return this.ef.toString();
  }
  /**
   * Trims the specified trailing substring from the end of the input string recursively.
   *
   * @param {string} str - The input string to be trimmed.
   * @param {string} [sub='0'] - The substring to be removed from the end of the input string. Defaults to '0' if not provided.
   * @return {string} The trimmed string with the specified trailing substring removed.
   */
  static trimEnd(str, sub = "0") {
    if (str.endsWith(sub)) {
      return _FixedPointNumber.trimEnd(
        str.substring(0, str.length - sub.length),
        sub
      );
    }
    return str;
  }
  /**
   * Converts a string expression of a number into a scaled value.
   *
   * @param {string} exp - The string expression of the number to be converted.
   * @param {bigint} fd - The scale factor to be used for conversion.
   * @param {string} [decimalSeparator='.'] - The character used as the decimal separator in the string expression.
   * @return {bigint} - The converted scaled value as a bigint.
   */
  static txtToSV(exp, fd, decimalSeparator = ".") {
    const fc = exp.charAt(0);
    let sign = 1n;
    if (fc === "-") {
      sign = -1n;
      exp = exp.substring(1);
    } else if (fc === "+") {
      exp = exp.substring(1);
    }
    const sf = 10n ** fd;
    const di = exp.lastIndexOf(decimalSeparator);
    if (di < 0) {
      return sign * sf * BigInt(exp);
    }
    const ie = exp.substring(0, di);
    const fe = exp.substring(di + 1);
    return sign * sf * BigInt(ie) + // Integer part
    sign * (sf * BigInt(fe)) / BigInt(10 ** fe.length);
  }
};

// src/vcdm/hash/Sha256.ts
import * as nh_sha256 from "@noble/hashes/sha256";
import { InvalidOperation as InvalidOperation7 } from "@vechain/sdk-errors";
var Sha256 = class _Sha256 extends HexUInt {
  /**
   * Generates the [SHA 256](https://en.wikipedia.org/wiki/SHA-2) hash of the given input.
   *
   * @param {bigint | number | string | Uint8Array | Hex} exp - The input value to hash.
   *
   * @returns {Sha256} - The [SHA256](https://en.wikipedia.org/wiki/SHA-2) hash of the input value.
   *
   * @throws {InvalidOperation} - If a hash error occurs.
   *
   * @remarks Security auditable method, depends on
   * * [`nh_sha256.sha256`](https://github.com/paulmillr/noble-hashes#sha2-sha256-sha384-sha512-and-others).
   */
  static of(exp) {
    try {
      const hash2 = nh_sha256.sha256(HexUInt.of(exp).bytes);
      return new _Sha256(Hex.POSITIVE, HexUInt.of(hash2).digits);
    } catch (e) {
      throw new InvalidOperation7("Sha256.of", "hash error", {
        exp: `${exp}`,
        // Needed to serialize bigint values.
        e
      });
    }
  }
};
function sha2562(data, returnType = "buffer") {
  return returnType === "buffer" ? Sha256.of(Txt.of(data).bytes).bytes : Sha256.of(Txt.of(data).bytes).toString();
}

// src/secp256k1/Secp256k1.ts
import * as nc_utils2 from "@noble/curves/abstract/utils";
import { randomBytes as nh_randomBytes } from "@noble/hashes/utils";
import { secp256k1 as nc_secp256k1 } from "@noble/curves/secp256k1";
import {
  InvalidSecp256k1MessageHash,
  InvalidSecp256k1PrivateKey,
  InvalidSecp256k1Signature
} from "@vechain/sdk-errors";
var Secp256k1 = class _Secp256k1 {
  /**
   * This value is used to identify compressed public key.
   */
  static COMPRESSED_PREFIX = 2;
  /**
   * Represents the fixed length of the cryptographic signature.
   * The value is set to 65, which is the size in bytes
   * required for a 520-bit signature.
   *
   * @constant {number} SIGNATURE_LENGTH
   */
  static SIGNATURE_LENGTH = 65;
  /**
   * This value is used to identify uncompressed public key.
   */
  static UNCOMPRESS_PREFIX = 4;
  /**
   * Defines the required length for a valid hash.
   */
  static VALID_HASH_LENGTH = 32;
  /**
   * Compresses an uncompressed public key.
   *
   * @param {Uint8Array} publicKey - The uncompressed public key to be compressed.
   * @return {Uint8Array} - The compressed public key.
   *
   * @see Secp256k1.inflatePublicKey
   */
  static compressPublicKey(publicKey) {
    const prefix = publicKey.at(0);
    if (prefix === _Secp256k1.UNCOMPRESS_PREFIX) {
      const x = publicKey.slice(1, 33);
      const y = publicKey.slice(33, 65);
      const isYOdd = y[y.length - 1] & 1;
      return nc_utils2.concatBytes(
        Uint8Array.of(_Secp256k1.COMPRESSED_PREFIX + isYOdd),
        x
      );
    } else {
      return publicKey;
    }
  }
  /**
   * Derives the public key from a given private key.
   *
   * @param {Uint8Array} privateKey - The private key in Uint8Array format. Must be a valid 32-byte secp256k1 private key.
   * @param {boolean} [isCompressed=true] - Indicates whether the derived public key should be in compressed format.
   * @return {Uint8Array} The derived public key in Uint8Array format.
   * @throws {InvalidSecp256k1PrivateKey} Throws an error if the provided private key is not valid.
   *
   * @remarks Security auditable method, depends on
   * * [nc_secp256k1.getPublicKey](https://github.com/paulmillr/noble-secp256k1).
   */
  static derivePublicKey(privateKey, isCompressed = true) {
    if (_Secp256k1.isValidPrivateKey(privateKey)) {
      return nc_secp256k1.getPublicKey(privateKey, isCompressed);
    }
    throw new InvalidSecp256k1PrivateKey(
      "Secp256k1.derivePublicKey",
      "Invalid private key given as input. Ensure it is a valid 32-byte secp256k1 private key.",
      void 0
    );
  }
  /**
   * Generates a new random private key.
   * If an error occurs during generation using
   * [nc_secp256k1](https://github.com/paulmillr/noble-secp256k1),
   * an AES-GCM key is generated as a fallback in runtimes not supported
   * by `nc_secp256k1`, if those support {@link {@link global.crypto}.
   *
   * @return {Promise<Uint8Array>} The generated private key as a Uint8Array.
   *
   * @remarks Security auditable method, depends on
   * * {@link global.crypto.subtle.exportKey};
   * * {@link global.crypto.subtle.generateKey};
   * * [nc_secp256k1.utils.randomPrivateKey](https://github.com/paulmillr/noble-secp256k1).
   */
  static async generatePrivateKey() {
    try {
      return nc_secp256k1.utils.randomPrivateKey();
    } catch (e) {
      const cryptoKey = await global.crypto.subtle.generateKey(
        {
          name: "AES-GCM",
          length: 256
        },
        true,
        ["encrypt", "decrypt"]
      );
      const rawKey = await global.crypto.subtle.exportKey(
        "raw",
        cryptoKey
      );
      return new Uint8Array(rawKey);
    }
  }
  /**
   * Inflate a compressed public key to its uncompressed form.
   *
   * @param {Uint8Array} publicKey - The compressed public key to be inflated.
   * @return {Uint8Array} - The uncompressed public key.
   *
   * @remarks Security auditable method, depends on
   * * [nc_secp256k1.ProjectivePoint.fromAffine](https://github.com/paulmillr/noble-secp256k1);
   * * [nc_secp256k1.ProjectivePoint.fromHex](https://github.com/paulmillr/noble-secp256k1);
   * * [nc_secp256k1.ProjectivePoint.toAffine](https://github.com/paulmillr/noble-secp256k1).
   *
   * @see Secp256K1.compressPublicKey
   */
  static inflatePublicKey(publicKey) {
    const prefix = publicKey.at(0);
    if (prefix !== _Secp256k1.UNCOMPRESS_PREFIX) {
      const x = publicKey.slice(0, 33);
      const p = nc_secp256k1.ProjectivePoint.fromAffine(
        nc_secp256k1.ProjectivePoint.fromHex(
          HexUInt.of(x).digits
        ).toAffine()
      );
      return p.toRawBytes(false);
    } else {
      return publicKey;
    }
  }
  /**
   * Checks whether the provided hash is a valid message hash.
   *
   * @param {Uint8Array} hash - The hash to be validated.
   * @return {boolean} `true` if the hash is 32 bytes long, otherwise `false`.
   */
  static isValidMessageHash(hash2) {
    return hash2.length === _Secp256k1.VALID_HASH_LENGTH;
  }
  /**
   * Checks if the provided private key is valid.
   *
   * @param {Uint8Array} privateKey - The private key to validate.
   * @return {boolean} `true` if the private key is valid, `false` otherwise.
   *
   * @remarks Security auditable method, depends on
   * * [nc_secp256k1.utils.isValidPrivateKey](https://github.com/paulmillr/noble-secp256k1).
   */
  static isValidPrivateKey(privateKey) {
    return nc_secp256k1.utils.isValidPrivateKey(privateKey);
  }
  /**
   * Generates a random sequence of bytes.
   * If an error occurs during generation using
   * [nc_secp256k1](https://github.com/paulmillr/noble-secp256k1),
   * {@link {@link global.crypto} is used as fall back togenerate
   * the random sequence.
   *
   * @param {number} [bytesLength=32] - Optional. The number of random bytes to generate.
   * @return {Uint8Array} - A Uint8Array containing the random bytes.
   *
   * @remarks Security auditable method, depends on
   *  * {@link global.crypto.getRandomValues};
   * * [nh_randomBytes](https://github.com/paulmillr/noble-hashes).
   */
  static randomBytes(bytesLength) {
    try {
      return nh_randomBytes(bytesLength);
    } catch (e) {
      return global.crypto.getRandomValues(
        new Uint8Array(bytesLength ?? 32)
      );
    }
  }
  /**
   * Recovers the public key associated with the message hash from the given signature.
   *
   * @param {Uint8Array} messageHash - The 32-byte message hash to be verified.
   * @param {Uint8Array} sig - The 65-byte signature used for recovery, consisting of the compact signature and recovery byte.
   * @return {Uint8Array} The recovered public key in its raw bytes form.
   * @throws {InvalidSecp256k1MessageHash} If the provided message hash is invalid.
   * @throws {InvalidSecp256k1Signature} If the provided signature is not 65 bytes or contains an invalid recovery value.
   *
   * @remarks Security auditable method, depends on
   * * [nc_secp256k1.Signature](https://github.com/paulmillr/noble-secp256k1).
   *
   * @see Secp256k1.isValidMessageHash
   */
  static recover(messageHash, sig) {
    if (!_Secp256k1.isValidMessageHash(messageHash)) {
      throw new InvalidSecp256k1MessageHash(
        "Secp256k1.recover",
        "Invalid message hash given as input. Ensure it is a valid 32-byte message hash.",
        { messageHash }
      );
    }
    if (sig.length !== _Secp256k1.SIGNATURE_LENGTH)
      throw new InvalidSecp256k1Signature(
        "Secp256k1.recover",
        "Invalid signature given as input. Length must be exactly 65 bytes.",
        { signature: sig }
      );
    const recovery = sig[64];
    if (recovery !== 0 && recovery !== 1)
      throw new InvalidSecp256k1Signature(
        "Secp256k1.recover",
        "Invalid signature recovery value. Signature bytes at position 64 must be 0 or 1.",
        { signature: sig, recovery }
      );
    return nc_secp256k1.Signature.fromCompact(sig.slice(0, 64)).addRecoveryBit(recovery).recoverPublicKey(messageHash).toRawBytes(false);
  }
  /**
   * Signs a given message hash using the provided private key.
   *
   * @param messageHash - A 32-byte message hash that needs to be signed.
   * @param privateKey - A 32-byte private key used for signing the message hash.
   * @return The signature of the message hash consisting of the r, s, and recovery values.
   * @throws InvalidSecp256k1MessageHash if the message hash is not a valid 32-byte hash.
   * @throws InvalidSecp256k1PrivateKey if the private key is not a valid 32-byte private key.
   *
   * @remarks Security auditable method, depends on
   * * [nc_secp256k1.sign](https://github.com/paulmillr/noble-secp256k1).
   *
   * @see Secp256k1.isValidMessageHash
   * @see Secp256k1.isValidPrivateKey
   */
  static sign(messageHash, privateKey) {
    if (!_Secp256k1.isValidMessageHash(messageHash)) {
      throw new InvalidSecp256k1MessageHash(
        "Secp256k1.sign",
        "Invalid message hash given as input. Ensure it is a valid 32-byte message hash.",
        { messageHash }
      );
    }
    if (!_Secp256k1.isValidPrivateKey(privateKey)) {
      throw new InvalidSecp256k1PrivateKey(
        "Secp256k1.sign",
        "Invalid private key given as input. Ensure it is a valid 32-byte secp256k1 private key.",
        void 0
      );
    }
    const sig = nc_secp256k1.sign(messageHash, privateKey);
    return nc_utils2.concatBytes(
      nc_utils2.numberToBytesBE(sig.r, 32),
      nc_utils2.numberToBytesBE(sig.s, 32),
      nc_utils2.numberToVarBytesBE(sig.recovery)
    );
  }
};

// src/hdkey/HDKey.ts
import {
  InvalidHDKey,
  InvalidHDKeyMnemonic,
  InvalidSecp256k1PrivateKey as InvalidSecp256k1PrivateKey2
} from "@vechain/sdk-errors";
var HDKey2 = class extends s_bip32.HDKey {
  /**
   * Prefix for extended private key
   */
  static EXTENDED_PRIVATE_KEY_PREFIX = HexUInt.of(
    "0488ade4000000000000000000"
  ).bytes;
  /**
   * Prefix for extended public key
   */
  static EXTENDED_PUBLIC_KEY_PREFIX = HexUInt.of(
    "0488b21e000000000000000000"
  ).bytes;
  /**
   * Default VET derivation path.
   *
   * See
   * [SLIP-0044 : Registered coin types for BIP-0044](https://github.com/satoshilabs/slips/blob/master/slip-0044.md)
   * for more info.
   */
  static VET_DERIVATION_PATH = "m/44'/818'/0'/0";
  /**
   * Creates a
   * [BIP32 Hierarchical Deterministic Key](https://github.com/bitcoin/bips/blob/master/bip-0032.mediawiki)
   * from
   * [BIP39 Mnemonic Words](https://github.com/bitcoin/bips/blob/master/bip-0039.mediawiki)
   * and the given derivation path.
   *
   * @param {string[]} words - An array of words representing the mnemonic.
   * @param {string} path - The derivation path to derive the child node.
   * Default value is {@link VET_DERIVATION_PATH}.
   *
   * @return The derived child hierarchical deterministic key.
   *
   * @throws {InvalidHDKey} If `path` is not valid to derive a node wallet.
   * @throws {InvalidHDKeyMnemonic} If `words` is an invalid array mnemonic.
   *
   * @remarks Security auditable method, depends on
   * * [s_bip32.HDKey.derive](https://github.com/paulmillr/scure-bip32);
   * * [s_bip32.HDKey.fromMasterSeed](https://github.com/paulmillr/scure-bip32);
   * * [s_bip39.mnemonicToSeedSync](https://github.com/paulmillr/scure-bip39).
   */
  static fromMnemonic(words, path = this.VET_DERIVATION_PATH) {
    let master;
    try {
      master = s_bip32.HDKey.fromMasterSeed(
        s_bip39.mnemonicToSeedSync(words.join(" ").toLowerCase())
      );
    } catch (error) {
      throw new InvalidHDKeyMnemonic(
        "HDNode.fromMnemonic",
        "Invalid mnemonic words given as input.",
        void 0,
        error
      );
    }
    try {
      return master.derive(path);
    } catch (error) {
      throw new InvalidHDKey(
        "HDNode.fromMnemonic",
        "Invalid derivation path given as input.",
        { derivationPath: path },
        error
      );
    }
  }
  /**
   * Creates a
   * [BIP32 Hierarchical Deterministic Key](https://github.com/bitcoin/bips/blob/master/bip-0032.mediawiki)
   * from a private key and chain code.
   *
   * @param {Uint8Array} - privateKey The private key.
   * @param {Uint8Array} - chainCode The chain code.
   *
   * @returns Returns the hierarchical deterministic key from `privateKey` and `chainCode`.
   *
   * @throws {InvalidSecp256k1PrivateKey} If the `privateKey` is invalid.
   *
   * @remarks **This method wipes `privateKey`** for security reasons.
   * @remarks Security auditable method, depends on
   * * [base58.encode](https://github.com/paulmillr/scure-base);
   * * {@link Sha256};
   * * [s_bip32.HDKey.fromExtendedKey](https://github.com/paulmillr/scure-bip32).
   */
  static fromPrivateKey(privateKey, chainCode) {
    if (privateKey.length === 32) {
      const header = nc_utils3.concatBytes(
        this.EXTENDED_PRIVATE_KEY_PREFIX,
        chainCode,
        Uint8Array.of(0),
        privateKey
      );
      privateKey.fill(0);
      const checksum = Sha256.of(Sha256.of(header).bytes).bytes.subarray(
        0,
        4
      );
      const expandedPrivateKey = nc_utils3.concatBytes(header, checksum);
      try {
        return s_bip32.HDKey.fromExtendedKey(
          base58.encode(expandedPrivateKey)
        );
      } catch (e) {
        throw new InvalidSecp256k1PrivateKey2(
          "HDNode.fromPrivateKey",
          "Invalid private key path given as input.",
          void 0
        );
      }
    }
    privateKey.fill(0);
    throw new InvalidSecp256k1PrivateKey2(
      "HDNode.fromPrivateKey()",
      "Invalid private key path given as input. Length must be exactly 32 bytes.",
      void 0
    );
  }
  /**
   * Creates a
   * [BIP32 Hierarchical Deterministic Key](https://github.com/bitcoin/bips/blob/master/bip-0032.mediawiki)
   * key from a public key and chain code.
   *
   * @param {Uint8Array} publicKey - The public key bytes.
   * @param {Uint8Array} chainCode - The chain code bytes.
   *
   * @returns {HDKey} Returns the hierarchical deterministic key from `public` and `chainCode`.
   *
   * @throws {InvalidHDKey} if the `publicKey` is invalid.
   *
   * @remarks Security auditable method, depends on
   * * [base58.encode](https://github.com/paulmillr/scure-base);
   * * {@link Secp256k1.compressPublicKey};
   * * {@link Sha256};
   * * [HDKey.fromExtendedKey](https://github.com/paulmillr/scure-bip32).
   */
  static fromPublicKey(publicKey, chainCode) {
    if (chainCode.length === 32) {
      const header = nc_utils3.concatBytes(
        this.EXTENDED_PUBLIC_KEY_PREFIX,
        chainCode,
        Secp256k1.compressPublicKey(publicKey)
      );
      const checksum = Sha256.of(Sha256.of(header).bytes).bytes.subarray(
        0,
        4
      );
      const expandedPublicKey = nc_utils3.concatBytes(header, checksum);
      try {
        return s_bip32.HDKey.fromExtendedKey(
          base58.encode(expandedPublicKey)
        );
      } catch (error) {
        throw new InvalidHDKey(
          "HDNode.fromPublicKey()",
          "Invalid public key path given as input.",
          { publicKey },
          error
        );
      }
    }
    throw new InvalidHDKey(
      "HDNode.fromPublicKey()",
      "Invalid chain code given as input. Length must be exactly 32 bytes.",
      { chainCode }
    );
  }
  /**
   * Checks if derivation path single component is valid
   *
   * @param component - Derivation path single component to check
   * @param index - Derivation path single component index
   *
   * @returns `true`` if derivation path single component is valid, otherwise `false`.
   *
   */
  static isDerivationPathComponentValid(component, index) {
    return (
      // m
      (index === 0 ? component === "m" : false) || // "number"
      FixedPointNumber.isNaturalExpression(component) || // "number'"
      FixedPointNumber.isNaturalExpression(component.slice(0, -1)) && component.endsWith("'")
    );
  }
  /**
   * Checks if derivation path is valid.
   *
   * @param derivationPath - Derivation path to check.
   *
   * @returns `true` if derivation path is valid, otherwise `false`.
   */
  static isDerivationPathValid(derivationPath) {
    const pathComponents = derivationPath.split("/");
    for (let i = 0; i < pathComponents.length; i++) {
      if (!this.isDerivationPathComponentValid(pathComponents[i], i))
        return false;
    }
    return true;
  }
};

// src/vcdm/Address.ts
import {
  InvalidDataType as InvalidDataType5,
  InvalidHDKey as InvalidHDKey2,
  InvalidSecp256k1PrivateKey as InvalidSecp256k1PrivateKey3
} from "@vechain/sdk-errors";
var Address = class _Address extends HexUInt {
  /**
   * It checksums a given hexadecimal address.
   *
   * @param {HexUInt} huint - The HexUInt object representing the hexadecimal value.
   *
   * @returns {string} The checksummed address.
   */
  static checksum(huint) {
    const stringAddress = huint.digits;
    const hash2 = Keccak256.of(Txt.of(stringAddress).bytes).digits;
    let checksum = "";
    for (let i = 0; i < stringAddress.length; i++) {
      checksum += parseInt(hash2[i], 16) > 7 ? stringAddress[i].toUpperCase() : stringAddress[i];
    }
    return "0x" + checksum;
  }
  /**
   * Validate the given expression to be a valid address.
   *
   *  @param {string} exp - Expression to validate
   *
   * @returns {boolean} true if the expression is a valid address, false otherwise
   */
  static isValid(exp) {
    return Hex.isValid0x(exp) && exp.length === 42;
  }
  /**
   * Create ab Address instance from the given expression interpreted as an unsigned integer.
   *
   * @param exp - The expression to convert. It can be of type bigint, number, string, Uint8Array, or HexUInt.
   *
   * @returns {Address} The converted hexadecimal unsigned integer.
   *
   * @throws {InvalidDataType} If the expression is not a valid hexadecimal positive integer expression.
   */
  static of(exp) {
    try {
      const huint = HexUInt.of(exp);
      if (_Address.isValid(huint.toString())) {
        const addressChecksummed = _Address.checksum(huint);
        return new _Address(
          Hex.POSITIVE,
          "0x0",
          // When we normalize we return the checksummed address as digits
          () => addressChecksummed.substring(2)
        );
      } else {
        throw new InvalidDataType5("Address.of", "not a valid address", {
          huint
        });
      }
    } catch (error) {
      throw new InvalidDataType5(
        "Address.of",
        "not a valid hexadecimal positive integer expression",
        { exp: `${exp}` },
        error
      );
    }
  }
  /**
   * Create an Address instance from the given private key.
   *
   * @param {Uint8Array} privateKey - The private key to convert.
   *
   * @param {boolean} [isCompressed=true] - The flag to indicate if the derived public key should be compressed.
   *
   * @returns {Address} The converted address.
   *
   * @remarks Security auditable method, depends on
   * * {@link Secp256k1.derivePublicKey}.
   */
  static ofPrivateKey(privateKey, isCompressed = true) {
    try {
      return _Address.ofPublicKey(
        Secp256k1.derivePublicKey(privateKey, isCompressed)
      );
    } catch (error) {
      if (error instanceof InvalidSecp256k1PrivateKey3) {
        throw error;
      }
      throw new InvalidDataType5(
        "Address.ofPrivateKey",
        "not a valid private key",
        { privateKey: `${privateKey}` },
        error
      );
    }
  }
  /**
   * Create an Address instance from the given public key.
   *
   * @param {Uint8Array} publicKey - The public key to convert.
   *
   * @returns {Address} The converted address.
   *
   * @remarks Security auditable method, depends on
   * * {@link Secp256k1.inflatePublicKey}.
   */
  static ofPublicKey(publicKey) {
    try {
      const publicKeyInflated = Secp256k1.inflatePublicKey(publicKey);
      const publicKeyHash = Keccak256.of(
        publicKeyInflated.slice(1)
      ).bytes;
      return _Address.of(publicKeyHash.slice(12));
    } catch (error) {
      throw new InvalidDataType5(
        "Address.ofPublicKey",
        "not a valid public key",
        { publicKey: `${publicKey}` },
        error
      );
    }
  }
  /**
   * Derives the address from a given list of words of
   * [BIP39 Mnemonic Words](https://github.com/bitcoin/bips/blob/master/bip-0039.mediawiki)
   * and a [BIP44 Derivation Path](https://github.com/satoshilabs/slips/blob/master/slip-0044.md)
   * as in the examples.
   *
   * Secure audit function.
   * - {@link bip32.HDKey}(https://github.com/paulmillr/scure-bip32)
   * - {@link HDKey}
   *
   * @example `m/0` (default)
   * @example `m/0/2`
   * @example `m/0/2/4/6`
   *
   * @param {string[]} mnemonic - Mnemonic used to generate the HD node.
   * @param {string} [path='m/0'] - The derivation path from the current node.
   * @return {Address} - The derived address.
   * @throws {InvalidHDKey}
   *
   */
  static ofMnemonic(mnemonic2, path = "m/0") {
    const root = HDKey2.fromMnemonic(mnemonic2);
    try {
      return _Address.ofPublicKey(
        root.derive(path).publicKey
      );
    } catch (error) {
      throw new InvalidHDKey2(
        "mnemonic.deriveAddress()",
        "Invalid derivation path given as input.",
        { derivationPath: path },
        error
      );
    }
  }
};
var addressUtils = {
  fromPrivateKey: (privateKey) => Address.ofPrivateKey(privateKey).toString(),
  fromPublicKey: (publicKey) => Address.ofPublicKey(publicKey).toString(),
  isAddress: (addressToVerify) => Address.isValid(addressToVerify),
  toERC55Checksum: (address) => Address.checksum(HexUInt.of(address)).toString()
};

// src/vcdm/BloomFilter.ts
import * as nc_utils4 from "@noble/curves/abstract/utils";

// src/vcdm/hash/Blake2b256.ts
import { blake2b as nh_blake2b } from "@noble/hashes/blake2b";
import { InvalidOperation as InvalidOperation8 } from "@vechain/sdk-errors";
var Blake2b256 = class _Blake2b256 extends HexUInt {
  /**
   * Generates the [BLAKE](https://en.wikipedia.org/wiki/BLAKE_(hash_function)) [BLAKE2B 256](https://www.blake2.net/) hash of the given input.
   *
   * @param {bigint | number | string | Uint8Array | Hex} exp - The input value to hash.
   *
   * @returns {Sha256} - The [BLAKE2B 256](https://www.blake2.net/) hash of the input value.
   *
   * @throws {InvalidOperation} - If a hash error occurs.
   *
   * @remarks Security auditable method, depends on
   * * [`nh_blake2b.create(...).update(...).digest(...)`](https://github.com/paulmillr/noble-hashes#sha3-fips-shake-keccak).
   */
  static of(exp) {
    try {
      const hash2 = nh_blake2b.create({ dkLen: 32 }).update(HexUInt.of(exp).bytes).digest();
      return new _Blake2b256(Hex.POSITIVE, HexUInt.of(hash2).digits);
    } catch (e) {
      throw new InvalidOperation8("Blake2b256.of", "hash error", {
        exp: `${exp}`,
        // Needed to serialize bigint values.
        e
      });
    }
  }
};
function blake2b256(data, returnType = "buffer") {
  return returnType === "buffer" ? Blake2b256.of(Txt.of(data).bytes).bytes : Blake2b256.of(Txt.of(data).bytes).toString();
}

// src/vcdm/BloomFilter.ts
import { InvalidDataType as InvalidDataType6, InvalidOperation as InvalidOperation9 } from "@vechain/sdk-errors";
var BloomFilter = class _BloomFilter {
  /**
   * Return the Bloom filter structure: an array of `m` bits per key encoding if a key is not part of the structure.
   *
   * @typedef {Uint8Array} bytes
   */
  bytes;
  /**
   * Return the number of hash functions used to compute this Bloom filter.
   *
   * @type {number}
   */
  k;
  /**
   * Creates a new instance of this class.
   *
   * @param {Uint8Array} bytes - The Bloom filter structure of `m` bits per key encoding if the key
   *                             likely belongs to the structure or surely doesn't.
   * @param {number} k - The number of hash functions used to compute this Bloom filter.
   *
   */
  constructor(bytes, k) {
    this.bytes = bytes;
    this.k = k;
  }
  /**
   * Return the Bloom filter data structure represented as a {@link bigint} value.
   *
   * @returns {bigint} - The Bloom filter data structure represented as a {@link bigint} value.
   */
  get bi() {
    return nc_utils4.bytesToNumberBE(this.bytes);
  }
  /**
   * Return the Bloom filter data structure represented as a {@link number} value.
   *
   * @returns {bigint} - The Bloom filter data structure represented as a {@link number} value.
   *
   * @throws InvalidDataType if the data structure of the bloom filter can't be represented as a number
   * because underflow or overflow number safe integer range according
   * [IEEE 754 double precision 64 bits floating point format](https://en.wikipedia.org/wiki/Double-precision_floating-point_format).
   *
   * @remarks Preferably use {@link bi} because the Bloom filter data structure can always be represented as a {@link bigint} value.
   */
  get n() {
    const bi = this.bi;
    if (Number.MIN_SAFE_INTEGER <= bi && bi <= Number.MAX_SAFE_INTEGER) {
      return Number(bi);
    }
    throw new InvalidDataType6(
      "BloomFilter.n",
      "not in the safe number range",
      {
        bytes: this.bytes,
        k: this.k
      }
    );
  }
  /**
   * Compare the current BloomFilter instance with another BloomFilter instance according their
   * * {@link bytes} data structure first,
   * * {@link k} if the data structures are equal.
   *
   * @param {BloomFilter} that - The BloomFilter instance to compare with.
   *
   * @return {number} - Returns a negative number if the current instance is less than the provided instance,
   *                   returns zero if they are equal, and returns a positive number if the current instance is greater than the provided instance.
   */
  compareTo(that) {
    return this.bi < that.bi ? -1 : this.bi === that.bi ? this.k - that.k : 1;
  }
  /**
   * Checks if the current BloomFilter instance is equal to another BloomFilter instance.
   *
   * @param {BloomFilter} that - The other BloomFilter instance to compare with.
   *
   * @return {boolean} - Returns true if the current BloomFilter instance is equal to the other BloomFilter instance, otherwise returns false.
   */
  isEqual(that) {
    return this.bi === that.bi && this.k === that.k;
  }
  /**
   * Checks if the specified key may be contained within this Bloom filter or surely isn't.
   *
   * @param {Hex|Uint8Array} key - The key to check. It can be either a Hex object or a Uint8Array.
   *
   * @return {boolean} Returns true if this Bloom filter may contain the key, otherwise returns false.
   *
   * @remarks False positive matches are possible, but false negatives are not.
   * @remarks Security auditable method, depends on
   * * {@link hash}.
   */
  contains(key) {
    return distribute(
      hash(key instanceof Hex ? key.bytes : key),
      this.k,
      this.bytes.byteLength * 8,
      (index, bit) => {
        return (this.bytes[index] & bit) === bit;
      }
    );
  }
  /**
   * Calculates the optimal number of bits per key (`m` in math literature) based
   * on the number of hash functions (`k` in math literature) used to generate the Bloom Filter.
   *
   * Mathematically, `m` is approximated as `(k / ln(2))` which is simplified
   * to the higher integer close to `(m / 0.69)` for computational efficiency.
   * It also ensures that `k` is within a practical range [1, 30], hence the function
   * - returns `2` for `k = 1`,
   * - returns `44` for `k >= 30`.
   *
   * @param {number} k - The number of keys.
   *
   * @return {number} - The number of bits per key.
   */
  static computeBestBitsPerKey(k) {
    if (k <= 1) return 2;
    return k >= 30 ? 44 : Math.ceil(k / 0.69);
  }
  /**
   * Calculates the optimal number of hash functions (`k` in math literature)
   * based on bits per key (`m` in math literature).
   *
   * Mathematically, `k` is approximated as `(m * ln(2))` which is simplified
   * to the lower integer close to `(m * 0.69)` for computational efficiency.
   * It also ensures that `k` stays within a practical range [1, 30].
   *
   * @param m - The number of bits per key.
   *
   * @returns The calculated optimal `k` value.
   */
  static computeBestHashFunctionsQuantity(m) {
    const k = Math.floor(m * 0.69);
    if (k < 1) return 1;
    return k > 30 ? 30 : k;
  }
  /**
   * Checks if the current BloomFilter instance is possible to join with another BloomFilter instance.
   *
   * @param {BloomFilter} other - The BloomFilter instance to check if it is possible to join with the current instance.
   *
   * @return {boolean} - Returns true if the BloomFilter instances have the same 'k' value and 'bytes' length, false otherwise.
   */
  isJoinable(other) {
    return this.k === other.k && this.bytes.length === other.bytes.length;
  }
  /**
   * Joins the current BloomFilter with another BloomFilter by performing a bitwise OR operation on the
   * data structures of the filters.
   * Both filters must have been generated with the same number of hash functions, and they must have the same length.
   *
   * @param other - The BloomFilter to join with.
   *
   * @returns A new BloomFilter that represents the result of the join operation.
   *          They keys made this and `other` filter may belong to the returned filter.
   *          Any key not part of the joined filter surely doesn't belong to the returned filter.
   *
   * @throws {InvalidOperation} If the k values of the BloomFilters are different.
   * @throws {InvalidOperation} If the length of the byte arrays are different.
   */
  join(other) {
    if (this.k === other.k) {
      if (this.bytes.length === other.bytes.length) {
        return new _BloomFilter(
          new Uint8Array(
            this.bytes.map(
              (byte, index) => byte | other.bytes[index]
            )
          ),
          this.k
        );
      }
      throw new InvalidOperation9(
        "BloomFilter.join",
        "different length values",
        { this: this, other }
      );
    }
    throw new InvalidOperation9("BloomFilter.join", "different k values", {
      this: this,
      other
    });
  }
  /**
   * Creates a new instance of BloomFilterBuilder and adds the specified keys to it.
   * * Call {@link BloomFilterBuilder.add} to add more keys.
   * * Call {@link BloomFilterBuilder.build} to create a new Bloom filter once
   *
   * @param {...(Hex[] | Uint8Array[])} keys - The keys to be added to the BloomFilterBuilder.
   *
   * @returns {BloomFilterBuilder} - A new instance of BloomFilterBuilder with the specified keys added.
   *
   * @remarks Security auditable method, depends on
   * * {@link BloomFilterBuilder.add}.
   */
  static of(...keys) {
    const builder = new BloomFilterBuilder();
    builder.add(...keys);
    return builder;
  }
};
var BloomFilterBuilder = class _BloomFilterBuilder {
  /**
   * The default value number of hash functions used to create {@link BloomFilter} instances.
   */
  static DEFAULT_K = 5;
  /**
   * Map each element of the keys as likely part of the data structure of the Bloom filter to build.
   * Each key is mapped in `m` bits using `k` hash functions.
   *
   * @see {hash}
   */
  hashMap = /* @__PURE__ */ new Map();
  /**
   * Adds one or more keys to the Bloom filter to create.
   *
   * @param {Hex[] | Uint8Array[]} keys - The keys to be added to Bloom filter to create.
   *
   * @return {this} - Returns this {@link BloomFilterBuilder} instance, the {@link this.hashMap} is updated to
   * map the keys presence in the filter data structure.
   *
   * @remarks Security auditable method, depends on
   * * {@link hash}.
   */
  add(...keys) {
    for (const key of keys) {
      this.hashMap.set(hash(key instanceof Hex ? key.bytes : key), true);
    }
    return this;
  }
  /**
   * Builds a Bloom filter with the specified parameters and returns it.
   *
   * @param k - The number of hash functions to use in the Bloom filter.  to BloomFilterBuilder.DEFAULT_K.
   * @param m - The number of bits per key in the Bloom filter. Defaults to the value computed by BloomFilter.computeBestBitsPerKey(k).
   *
   * @return The built Bloom filter.
   */
  build(k = _BloomFilterBuilder.DEFAULT_K, m = BloomFilter.computeBestBitsPerKey(k)) {
    let nBytes = Math.floor((this.hashMap.size * m + 7) / 8);
    nBytes = nBytes < 8 ? 8 : nBytes;
    const bits = new Uint8Array(nBytes);
    const nBits = nBytes * 8;
    for (const hash2 of this.hashMap.keys()) {
      distribute(hash2, k, nBits, (index, bit) => {
        bits[index] |= bit;
        return true;
      });
    }
    return new BloomFilter(bits, k);
  }
};
var UINT32_LIMIT = 2 ** 32;
function addAndWrapAsUInt32(a, b) {
  return (a + b) % UINT32_LIMIT;
}
function distribute(hash2, k, m, collision) {
  const delta = (hash2 >>> 17 | hash2 << 15) >>> 0;
  for (let i = 0; i < k; i++) {
    const bitPos = hash2 % m;
    if (!collision(Math.floor(bitPos / 8), 1 << bitPos % 8)) {
      return false;
    }
    hash2 = addAndWrapAsUInt32(hash2, delta);
  }
  return true;
}
function hash(key) {
  return Number(
    nc_utils4.bytesToNumberBE(Blake2b256.of(key).bytes.slice(0, 4))
  );
}

// src/vcdm/currency/Coin.ts
import { InvalidDataType as InvalidDataType7 } from "@vechain/sdk-errors";
var Coin = class {
  /**
   * Represent coin {@link code} denomination.
   */
  _code;
  /**
   * Represent the coin {@link value}.
   *
   * @type {FixedPointNumber}
   */
  _value;
  /**
   * Creates an instance of the class with the specified code and value.
   *
   * @param {Txt} code - The code associated with this instance.
   * @param {FixedPointNumber} value - The value associated with this instance.
   */
  constructor(code, value) {
    this._code = code;
    this._value = value;
  }
  /**
   * Return the code as a Txt object.
   *
   * @return {Txt} The code object
   *
   * @remarks Since currency codes likely use Unicode composite symbols,
   * {@link Txt} type enforce the representation of the code is normalized.
   */
  get code() {
    return this._code;
  }
  /**
   * Return the current value as an FixedPointNumber (Fixed-Point Number).
   *
   * @return {FixedPointNumber} The current value in Fixed-Point Number format.
   */
  get value() {
    return this._value;
  }
  /**
   * Returns the integer part of the FixedPointNumber {@link value}.
   *
   * @return {bigint} the integer part of this FixedPointNumber {@link value}.
   *
   * @throws {InvalidOperation} If the {@link value} is not finite.
   *
   * @remarks Do not use for financial math: apply {@link FixedPointNumber} methods instead.
   */
  get bi() {
    return this._value.bi;
  }
  /**
   * Returns the array of bytes representing the *Normalization Form Canonical Composition*
   * [Unicode Equivalence](https://en.wikipedia.org/wiki/Unicode_equivalence)
   * of the textual expression '{@link value} {@link code}'.
   */
  get bytes() {
    return Txt.of(this.toString()).bytes;
  }
  /**
   * Return this {@linl value} approximated as {@link number}.
   *
   * @remarks Do not use for financial math: apply {@link FixedPointNumber} methods instead.
   */
  get n() {
    return this._value.n;
  }
  /**
   * Compares this Currency object with another Currency object for order.
   *
   * @param {Currency} that - The Currency object to be compared.
   * @return {number} A negative integer, zero, or a positive integer as this Currency
   *     is less than, equal to, or greater than the specified Currency.
   * @throws {InvalidDataType} If the currency codes do not match.
   */
  compareTo(that) {
    if (this.code.isEqual(that.code)) {
      return this.value.compareTo(that.value);
    }
    throw new InvalidDataType7("Coin.compareTo", "not VET currency", {
      // eslint-disable-next-line @typescript-eslint/no-base-to-string
      that: `${that}`
      // Needed to serialize bigint value.
    });
  }
  /**
   * Determines if this Currency object is equal to another Currency object.
   *
   * @param {Currency} that - The Currency object to compare with the current instance.
   * @return {boolean} - `true` if the objects are considered equal, otherwise `false`.
   */
  isEqual(that) {
    try {
      return this.compareTo(that) === 0;
    } catch (e) {
      return false;
    }
  }
  /**
   * Returns the textual representation of this currency as
   * '{@link value} {@link code}'.
   *
   * @return A string that contains the value and code properties of the object.
   */
  toString() {
    return `${this.value.toString()} ${this._code}`;
  }
};

// src/vcdm/currency/Units.ts
var Units = /* @__PURE__ */ ((Units2) => {
  Units2[Units2["wei"] = 0] = "wei";
  Units2[Units2["kwei"] = 3] = "kwei";
  Units2[Units2["mwei"] = 6] = "mwei";
  Units2[Units2["gwei"] = 9] = "gwei";
  Units2[Units2["szabo"] = 12] = "szabo";
  Units2[Units2["finney"] = 15] = "finney";
  Units2[Units2["ether"] = 18] = "ether";
  return Units2;
})(Units || {});
((Units2) => {
  function formatEther(wei) {
    return formatUnits(wei, 18 /* ether */);
  }
  Units2.formatEther = formatEther;
  function formatUnits(wei, unit = 18 /* ether */) {
    const fpn = wei.div(FixedPointNumber.of(10n ** BigInt(unit)));
    return fpn.isInteger() ? `${fpn}.0` : `${fpn}`;
  }
  Units2.formatUnits = formatUnits;
  function parseEther(ether) {
    return parseUnits(ether, 18 /* ether */);
  }
  Units2.parseEther = parseEther;
  function parseUnits(exp, unit = 18 /* ether */) {
    return FixedPointNumber.of(exp).times(
      FixedPointNumber.of(10n ** BigInt(unit))
    );
  }
  Units2.parseUnits = parseUnits;
})(Units || (Units = {}));

// src/vcdm/currency/VET.ts
var VET = class _VET extends Coin {
  /**
   * The code for VET is the sequence of Unicode
   * - U+1D64D - mathematical double strike capital letter 'V',
   * - U+039F - Greek capital letter 'Xi',
   * - U+0054 - Latin capital letter 'T'.
   */
  static CODE = Txt.of("\u{1D54D}\u039ET");
  /**
   * Wei fractional digits to express this value.
   */
  static WEI_FD = 18n;
  /**
   * Represents this monetary amount in terms of {@link Units.wei}.
   *
   * @type {bigint}
   */
  wei = this.value.dp(_VET.WEI_FD).sv;
  /**
   * Create a new instance with the given `value`.
   *
   * @param {FixedPointNumber} value The value to be used for initializing the instance.
   */
  constructor(value) {
    super(_VET.CODE, value);
  }
  /**
   * Return a new VET instance with the specified value and unit.
   *
   * @param {bigint | number | string | FixedPointNumber} value - The numerical value for the VET instance.
   * @param {Units} unit - The unit for the value.
   *                     Defaults to {@link Units.ether} if not provided.
   * @return {VET} A new VET instance with the provided value and unit.
   *
   * @throws {InvalidDataType} If `value` is not a numeric expression.
   */
  static of(value, unit = 18 /* ether */) {
    const fpn = value instanceof FixedPointNumber ? value : FixedPointNumber.of(value);
    return new _VET(
      fpn.div(FixedPointNumber.of(10n ** (_VET.WEI_FD - BigInt(unit))))
    );
  }
};

// src/vcdm/currency/VTHO.ts
var VTHO = class _VTHO extends Coin {
  /**
   * The code for VET is the sequence of Unicode
   * - U+1D64D - mathematical double strike capital letter 'V',
   * - U+0054 - Latin capital letter 'T',
   * - U+0048 - Latin capital letter 'H',
   * - U+004F - Latin capital letter 'O'.
   */
  static CODE = Txt.of("\u{1D54D}THO");
  /**
   * Wei fractional digits to express this value.
   */
  static WEI_FD = 18n;
  /**
   * Represents this monetary amount in terms of {@link Units.wei}.
   *
   * @type {bigint}
   */
  wei = this.value.dp(_VTHO.WEI_FD).sv;
  /**
   * Create a new instance with the given `value`.
   *
   * @param {FixedPointNumber} value The value to be used for initializing the instance.
   */
  constructor(value) {
    super(_VTHO.CODE, value);
  }
  /**
   * Return a new VTHO instance with the specified value and unit.
   *
   * @param {bigint | number | string | FixedPointNumber} value - The numerical value for the VTHO instance.
   * @param {Units} unit - The unit for the value.
   *                       Defaults to {@link Units.ether} if not provided.
   * @return {VTHO} A new VTHO instance with the provided value and unit.
   *
   * @throws {InvalidDataType} If `value` is not a numeric expression.
   */
  static of(value, unit = 18 /* ether */) {
    const fpn = value instanceof FixedPointNumber ? value : FixedPointNumber.of(value);
    return new _VTHO(
      fpn.div(FixedPointNumber.of(10n ** (_VTHO.WEI_FD - BigInt(unit))))
    );
  }
};

// src/vcdm/encoding/rlp/RLP.ts
import { RLP as EthereumjsRLP } from "@ethereumjs/rlp";
import { bytesToNumberBE as bytesToNumberBE2 } from "@noble/ciphers/utils";
import { InvalidDataType as InvalidDataType8, InvalidRLP } from "@vechain/sdk-errors";

// src/vcdm/encoding/rlp/kind/ScalarKind.ts
var ScalarKind = class {
};

// src/vcdm/encoding/rlp/RLP.ts
var RLP = class _RLP {
  encoded;
  decoded;
  constructor(data) {
    this.decoded = data instanceof Uint8Array ? EthereumjsRLP.decode(data) : data;
    this.encoded = data instanceof Uint8Array ? data : EthereumjsRLP.encode(data);
  }
  /**
   * Returns the bigint representation of the encoded data in the RLP instance.
   * @returns {bigint} The bigint representation of the encoded data.
   */
  get bi() {
    return bytesToNumberBE2(this.bytes);
  }
  /**
   * Returns the encoded data as a Uint8Array.
   * @returns {Uint8Array} The encoded data.
   */
  get bytes() {
    return this.encoded;
  }
  /**
   * Returns the number representation of the encoded data in the RLP instance.
   * @returns {number} The number representation of the encoded data.
   */
  get n() {
    const bi = this.bi;
    if (bi <= Number.MAX_SAFE_INTEGER) {
      return Number(bi);
    }
    throw new InvalidDataType8("RLP.n", "not in the safe number range", {
      bytes: this.bytes
    });
  }
  /**
   * Compares the current RLP instance with another RLP instance.
   * @param {RLP} that The RLP instance to compare.
   * @returns 0 if the RLP instances are equal, -1/1 if they are not.
   */
  compareTo(that) {
    if (this.encoded.length !== that.encoded.length) {
      return -1;
    }
    for (let i = 0; i < this.encoded.length; i++) {
      if (this.encoded[i] !== that.encoded[i]) {
        return 1;
      }
    }
    return 0;
  }
  /**
   * Relies on compareTo to check if the RLP instances are equal.
   * @param {RLP} that The RLP instance to compare.
   * @returns true if the RLP instances are equal, false otherwise.
   */
  isEqual(that) {
    return this.compareTo(that) === 0;
  }
  /**
   * Creates {@link Hex} instance from the RLP encoded value.
   * @returns {Hex} The Hex instance.
   */
  toHex() {
    return Hex.of(this.bytes);
  }
  /**
   * Returns an RLP instance from a plain value.
   * @param data - The plain data
   * @returns {RLP} The RLP instance.
   */
  static of(data) {
    try {
      return new _RLP(data);
    } catch (error) {
      throw new InvalidRLP(
        "RLP.of()",
        `Error when creating an RLP instance for data ${data}`,
        {
          context: "This method creates an RLP instance from a plain value.",
          data: {
            data
          }
        },
        error
      );
    }
  }
  /**
   * Returns an RLP instancen from an encoded value.
   * @param {Uint8Array} encodedData - The RLP-encoded data.
   * @returns The decoded data or null if decoding fails.
   */
  static ofEncoded(encodedData) {
    try {
      return new _RLP(encodedData);
    } catch (error) {
      throw new InvalidRLP(
        "RLP.ofEncoded()",
        `Error when creating an RLP instance for encoded data.`,
        {
          context: "This method creates an RLP instance from an encoded value.",
          data: {
            encodedData
          }
        },
        error
      );
    }
  }
  /**
   * Handles the RLP packing of data.
   * Recursively processes through object properties or array elements to prepare data for RLP encoding.
   *
   * @param obj - The object data to be packed.
   * @param profile - Profile for encoding structures.
   * @param context - Encoding context for error tracing.
   * @returns Packed data as RLPInput.
   * @throws {InvalidRLP}
   *
   */
  static packData(obj, profile, context) {
    context = context !== "" ? context + "." + profile.name : profile.name;
    const kind = profile.kind;
    if (kind instanceof ScalarKind) {
      return kind.data(obj, context).encode();
    }
    if (Array.isArray(kind)) {
      return kind.map(
        (k) => this.packData(obj[k.name], k, context)
      );
    }
    if (!Array.isArray(obj)) {
      throw new InvalidRLP(
        "RLP.packData()",
        `Validation error: Expected an array in ${context}.`,
        {
          context,
          data: {
            obj,
            profile
          }
        }
      );
    }
    if ("item" in kind && Array.isArray(obj)) {
      const item = kind.item;
      return obj.map(
        (part, i) => this.packData(
          part,
          { name: "#" + i, kind: item },
          context
        )
      );
    }
  }
  /**
   * Handles the RLP unpacking of data.
   * Recursively processes through packed properties or elements to prepare data post RLP decoding.
   *
   * @param packed - The packed data to be unpacked.
   * @param profile - Profile for decoding structures.
   * @param context - Decoding context for error tracing.
   * @returns Unpacked data as RLPValueType.
   * @throws {InvalidRLP}
   *
   */
  static unpackData(packed, profile, context) {
    context = context !== "" ? context + "." + profile.name : profile.name;
    const kind = profile.kind;
    if (kind instanceof ScalarKind) {
      if (!(packed instanceof Uint8Array)) {
        throw new InvalidRLP(
          "RLP.unpackData()",
          `Unpacking error: Expected data type is Uint8Array.`,
          {
            context,
            data: {
              packed,
              profile
            }
          }
        );
      }
      return kind.buffer(packed, context).decode();
    }
    if (Array.isArray(kind) && Array.isArray(packed)) {
      const parts = packed;
      if (kind.length !== parts.length) {
        throw new InvalidRLP(
          "RLP.unpackData()",
          `Unpacking error: Expected ${kind.length} items, but got ${parts.length}.`,
          {
            context,
            data: {
              packed,
              profile
            }
          }
        );
      }
      return kind.reduce(
        (obj, profile2, index) => {
          obj[profile2.name] = this.unpackData(
            parts[index],
            profile2,
            context
          );
          return obj;
        },
        {}
      );
    }
    if (!Array.isArray(packed)) {
      throw new InvalidRLP(
        "RLP.unpackData()",
        `Validation error: Expected an array in ${context}.`,
        {
          context,
          data: {
            packed,
            profile
          }
        }
      );
    }
    if ("item" in kind && Array.isArray(packed)) {
      const item = kind.item;
      return packed.map(
        (part, index) => this.unpackData(
          part,
          { name: "#" + index, kind: item },
          context
        )
      );
    }
  }
};

// src/vcdm/encoding/rlp/RLPProfiler.ts
var RLPProfiler = class _RLPProfiler extends RLP {
  /**
   * Creates a new Profiler instance.
   * @param profile - Profile for encoding/decoding structures.
   */
  constructor(data, profile) {
    super(data);
    this.profile = profile;
  }
  /**
   * Creates an RLPProfiler instance from a valid object.
   * @param {RLPValidObject} validObject Object to be encoded.
   * @returns {RLPProfiler} RLPProfiler instance.
   */
  static ofObject(validObject, profile) {
    const packedData = this.packData(validObject, profile, "");
    return new _RLPProfiler(packedData, profile);
  }
  /**
   * Decodes an object following the provided profile.
   * @param encodedData Data to be decoded.
   * @param profile Profile for encoding/decoding structures.
   * @returns - Decoded data as RLPValueType.
   */
  static ofObjectEncoded(encodedData, profile) {
    const packedData = RLP.ofEncoded(encodedData).decoded;
    return new _RLPProfiler(packedData, profile);
  }
  /**
   * Returns the decoded unpacked object.
   * @returns {RLPValueType} Decoded unpacked object.
   */
  get object() {
    return _RLPProfiler.unpackData(this.decoded, this.profile, "");
  }
};

// src/vcdm/encoding/rlp/helpers/numerickind.ts
import { InvalidRLP as InvalidRLP2 } from "@vechain/sdk-errors";
var validateNumericKindData = (data, context) => {
  if (typeof data !== "number" && typeof data !== "string") {
    throw new InvalidRLP2(
      "validateNumericKindData()",
      `Validation error: Input in ${context} must be a string or number.`,
      {
        context,
        data: {
          data
        }
      }
    );
  }
  if (typeof data === "number") {
    _validateNumericKindNumber(data, context);
  } else if (typeof data === "string") {
    _validateNumericKindString(data, context);
  }
  return BigInt(data);
};
var _validateNumericKindNumber = (num, context) => {
  if (!Number.isSafeInteger(num) || num < 0) {
    throw new InvalidRLP2(
      "_validateNumericKindNumber()",
      `Validation error: Number in ${context} must be a safe and non-negative integer.`,
      {
        context,
        data: {
          num
        }
      }
    );
  }
};
var _validateNumericKindString = (str, context) => {
  const isHexUInt = HexUInt.isValid0x(str);
  const isDecimal = FixedPointNumber.isNaturalExpression(str);
  if (!isHexUInt && !isDecimal) {
    throw new InvalidRLP2(
      "_validateNumericKindString()",
      `Validation error: String in ${context} must represent a non-negative integer in hex or decimal format.`,
      {
        context,
        data: {
          str
        }
      }
    );
  }
  if (isHexUInt && str.length <= 2) {
    throw new InvalidRLP2(
      "_validateNumericKindString()",
      `Validation error: Hex string number in ${context} must be of valid length.`,
      {
        context,
        data: {
          str
        }
      }
    );
  }
};
var assertValidNumericKindBuffer = (buf, context, maxBytes) => {
  if (maxBytes !== void 0 && buf.length > maxBytes) {
    throw new InvalidRLP2(
      "assertValidNumericKindBuffer()",
      `Validation error: Buffer in ${context} must be less than ${maxBytes} bytes.`,
      {
        context,
        data: {
          buf,
          maxBytes
        }
      }
    );
  }
  if (buf[0] === 0) {
    throw new InvalidRLP2(
      "assertValidNumericKindBuffer()",
      `Validation error: Buffer in ${context} must represent a canonical integer (no leading zeros).`,
      {
        context,
        data: {
          buf,
          maxBytes
        }
      }
    );
  }
};
var encodeBigIntToBuffer = (bi, maxBytes, context) => {
  if (bi === 0n) return Uint8Array.from([]);
  const hex = Hex.of(bi).digits;
  if (maxBytes !== void 0 && hex.length > maxBytes * 2) {
    throw new InvalidRLP2(
      "encodeBigIntToBuffer()",
      `Validation error: Encoded number in ${context} must fit within ${maxBytes} bytes.`,
      {
        context,
        data: {
          hex,
          maxBytes
        }
      }
    );
  }
  return Hex.of(hex).bytes;
};
var decodeBufferToNumberOrHex = (buffer) => {
  if (buffer.length === 0) return 0;
  const bi = Hex.of(buffer).bi;
  const num = Number(bi);
  return Number.isSafeInteger(num) ? num : "0x" + bi.toString(16);
};

// src/vcdm/encoding/rlp/helpers/hexblobkind.ts
import { InvalidRLP as InvalidRLP3 } from "@vechain/sdk-errors";
var assertValidHexBlobKindData = (data, context) => {
  if (typeof data !== "string") {
    throw new InvalidRLP3(
      "assertValidHexBlobKindData()",
      `Validation error: Input must be a string.`,
      {
        context,
        data: {
          data
        }
      }
    );
  }
  if (!Hex.isValid(data)) {
    throw new InvalidRLP3(
      "assertValidHexBlobKindData()",
      `Validation error: Input must be a valid hex string with a '0x' prefix.`,
      {
        context,
        data: {
          data
        }
      }
    );
  }
  if (data.length % 2 !== 0) {
    throw new InvalidRLP3(
      "assertValidHexBlobKindData()",
      `Validation error: Hex string must have an even length.`,
      {
        context,
        data: {
          data
        }
      }
    );
  }
};

// src/vcdm/encoding/rlp/helpers/fixedhexblobkind.ts
import { InvalidRLP as InvalidRLP4 } from "@vechain/sdk-errors";
var assertFixedHexBlobKindData = (data, context, bytes) => {
  if (data.length !== bytes * 2 + 2) {
    throw new InvalidRLP4(
      "assertFixedHexBlobKindData()",
      `Validation error: Hex string in ${context} must be exactly ${bytes} bytes in length.`,
      {
        context,
        data: {
          data,
          bytes
        }
      }
    );
  }
};
var assertFixedHexBlobKindBuffer = (buffer, context, bytes) => {
  if (buffer.length !== bytes) {
    throw new InvalidRLP4(
      "assertFixedHexBlobKindData()",
      `Validation error: Hex string in ${context} must be exactly ${bytes} bytes in length.`,
      {
        context,
        data: {
          buffer,
          bytes
        }
      }
    );
  }
};

// src/vcdm/encoding/rlp/helpers/compactfixedhexblobkind.ts
import { InvalidRLP as InvalidRLP5 } from "@vechain/sdk-errors";
var assertCompactFixedHexBlobBuffer = (buffer, context, bytes) => {
  if (buffer.length > bytes) {
    throw new InvalidRLP5(
      "assertCompactFixedHexBlobBuffer()",
      `Validation error: Buffer in ${context} must be at most ${bytes} bytes.`,
      {
        context,
        data: {
          buffer,
          bytes
        }
      }
    );
  }
  if (buffer.length !== 0 && buffer[0] === 0) {
    throw new InvalidRLP5(
      "assertCompactFixedHexBlobBuffer()",
      `Validation error: Buffer in ${context} should not have leading zero bytes.`,
      {
        context,
        data: {
          buffer,
          bytes
        }
      }
    );
  }
};
var encodeCompactFixedHexBlob = (buffer) => {
  const zeroIndex = buffer.findIndex((byte) => byte !== 0);
  return zeroIndex !== -1 ? buffer.subarray(zeroIndex) : Uint8Array.from([]);
};
var decodeBufferToHexWithLeadingZeros = (buffer, bytes) => {
  return Hex.of(buffer).fit(bytes * 2).toString();
};

// src/vcdm/encoding/rlp/kind/BufferKind.ts
import { InvalidRLP as InvalidRLP6 } from "@vechain/sdk-errors";
var BufferKind = class extends ScalarKind {
  /**
   * Encodes the input data into buffer format.
   *
   * @param {RLPInput} data The data to encode, expected to be of Uint8Array type.
   * @param {string} context Descriptive context for error messages
   * @returns {DataOutput} Object with an encode function.
   * @throws {InvalidRLP}
   */
  data(data, context) {
    if (!(data instanceof Uint8Array))
      throw new InvalidRLP6(
        "BufferKind.data()",
        `Validation error: Expected a Uint8Array type in ${context}.`,
        {
          context,
          data: {
            data
          }
        }
      );
    return {
      encode: () => data
      // Data is already a Buffer, so return as-is.
    };
  }
  /**
   * Decodes the input buffer.
   *
   * @param {Uint8Array} buffer - The buffer to decode, expected to be of buffer type.
   * @returns BufferOutput object with a decode function.
   * @throws {InvalidRLP}
   */
  buffer(buffer) {
    return {
      decode: () => buffer
      // Buffer is already in the correct format, so return as-is.
    };
  }
};

// src/vcdm/encoding/rlp/kind/NumericKind.ts
var NumericKind = class extends ScalarKind {
  /**
   * Constructs a new instance of NumericKind.
   *
   * @param maxBytes - Optional parameter that specifies the maximum number of bytes that numeric data can occupy when encoded.
   */
  constructor(maxBytes) {
    super();
    this.maxBytes = maxBytes;
  }
  /**
   * Encodes the input data into numeric format and ensures it doesn't exceed the maximum bytes, if specified.
   *
   * @param data - The data to encode, expected to be numeric.
   * @param context - Descriptive context for error messages
   * @returns DataOutput object with an encode function.
   * @throws Will throw an error if data validation fails or encoding issues occur.
   */
  data(data, context) {
    const dataBI = validateNumericKindData(data, context);
    return {
      encode: () => encodeBigIntToBuffer(dataBI, this.maxBytes, context)
      // Encodes BigInt to Buffer, respecting maxBytes.
    };
  }
  /**
   * Decodes the input buffer into a number or hexadecimal string, ensuring it meets numeric data constraints.
   *
   * @param {Uint8Array} buffer - The buffer to decode, containing numeric data.
   * @param context - Descriptive context for error messages.
   * @returns BufferOutput object with a decode function.
   * @throws Will throw an error if buffer validation fails.
   */
  buffer(buffer, context) {
    assertValidNumericKindBuffer(buffer, context, this.maxBytes);
    return {
      decode: () => decodeBufferToNumberOrHex(buffer)
      // Decodes buffer to either a number or a hexadecimal string.
    };
  }
};

// src/vcdm/encoding/rlp/kind/hexblob/HexBlobKind.ts
var HexBlobKind = class extends ScalarKind {
  /**
   * Encodes the input data into a Uint8Array.
   *
   * @param data - The data to encode, expected to be a '0x' prefixed even sized hex string.
   * @param context - Context string for error handling.
   * @returns An object containing an encode function which returns the encoded Uint8Array.
   */
  data(data, context) {
    assertValidHexBlobKindData(data, context);
    return {
      encode: () => HexUInt.of(data.slice(2)).bytes
    };
  }
  /**
   * Decodes the input buffer into a hex string.
   *
   * @param buffer - The buffer to decode.
   * @param context - Context string for error handling.
   * @returns An object containing a decode function which returns the decoded hex string.
   */
  buffer(buffer, _context) {
    return {
      decode: () => Hex.of(buffer).toString()
    };
  }
};

// src/vcdm/encoding/rlp/kind/hexblob/FixedHexBlobKind.ts
var FixedHexBlobKind = class extends HexBlobKind {
  /**
   * Creates a new instance of the {@link FixedHexBlobKind} class.
   * @param bytes - The number of bytes the blob must have.
   */
  constructor(bytes) {
    super();
    this.bytes = bytes;
  }
  /**
   * Encodes the input data into a Uint8Array with validation against fixed size.
   *
   * @param data - The data to encode, expected to be a '0x' prefixed even sized hex string.
   * @param context - Context string for error handling.
   * @returns An object containing an encode function which returns the encoded Uint8Array.
   */
  data(data, context) {
    const encoder = super.data(data, context);
    assertFixedHexBlobKindData(data, context, this.bytes);
    return encoder;
  }
  /**
   * Decodes the input buffer into a hex string with validation against fixed size.
   *
   * @param buffer - The buffer to decode.
   * @param context - Context string for error handling.
   * @returns An object containing a decode function which returns the decoded hex string.
   */
  buffer(buffer, context) {
    const decoder = super.buffer(buffer, context);
    assertFixedHexBlobKindBuffer(buffer, context, this.bytes);
    return decoder;
  }
};

// src/vcdm/encoding/rlp/kind/hexblob/OptionalFixedHexBlobKind.ts
var OptionalFixedHexBlobKind = class extends FixedHexBlobKind {
  /**
   * Encodes the input data (which can be null or undefined) into a Uint8Array.
   *
   * @param data - The data to encode, can be null or undefined.
   * @param context - Context string for error handling.
   * @returns An object containing an encode function which returns the encoded Uint8Array.
   */
  data(data, context) {
    return data == null ? {
      encode: () => Uint8Array.from([])
    } : super.data(data, context);
  }
  /**
   * Decodes the input buffer into a hex string or null if the buffer is empty.
   *
   * @param buffer - The buffer to decode, can be empty.
   * @param context - Context string for error handling.
   * @returns An object containing a decode function which returns the decoded hex string or null.
   */
  buffer(buffer, context) {
    return buffer.length === 0 ? {
      decode: () => null
    } : super.buffer(buffer, context);
  }
};

// src/vcdm/encoding/rlp/kind/hexblob/CompactFixedHexBlobKind.ts
var CompactFixedHexBlobKind = class extends FixedHexBlobKind {
  /**
   * Encodes the input data into a Uint8Array, trimming leading zeros.
   *
   * @param data - The data to encode, expected to be a '0x' prefixed hex string.
   * @param context - Context string for error handling.
   * @returns An object containing an encode function which returns the encoded Uint8Array.
   */
  data(data, context) {
    const buffer = super.data(data, context).encode();
    return {
      encode: () => encodeCompactFixedHexBlob(buffer)
      // Encode the buffer, trimming leading zeros.
    };
  }
  /**
   * Decodes the input buffer into a number or hexadecimal string, ensuring it meets the fixed size by padding with zeros.
   *
   * @param buffer - The buffer to decode, containing numeric data.
   * @param context - Descriptive context for error messages, usually representing the caller's identity.
   * @returns BufferOutput object with a decode function.
   * @throws Will throw an error if buffer validation fails.
   */
  buffer(buffer, context) {
    assertCompactFixedHexBlobBuffer(buffer, context, this.bytes);
    return {
      decode: () => (
        // Decode the buffer, returning a hex string with leading zeros.
        Hex.of(buffer).fit(this.bytes * 2).toString()
      )
    };
  }
};

// src/vcdm/Mnemonic.ts
import {
  entropyToMnemonic,
  generateMnemonic,
  validateMnemonic
} from "@scure/bip39";
import { wordlist } from "@scure/bip39/wordlists/english";
import {
  InvalidDataType as InvalidDataType9,
  InvalidHDKey as InvalidHDKey3,
  InvalidHDKeyMnemonic as InvalidHDKeyMnemonic2,
  InvalidOperation as InvalidOperation10
} from "@vechain/sdk-errors";
var Mnemonic = class _Mnemonic {
  /**
   * A TextEncoder instance used for encoding text to bytes.
   *
   * @type {TextEncoder}
   */
  static ENCODER = new TextEncoder();
  /**
   * Throws an exception because the mnemonic cannot be represented as a big integer.
   * @returns {bigint} The BigInt representation of the mnemonic.
   * @throws {InvalidOperation} The mnemonic cannot be represented as a bigint.
   * @override {@link VeChainDataModel#bi}
   * @remark The conversion to BigInt is not supported for a mnemonic.
   */
  get bi() {
    throw new InvalidOperation10(
      "Mnemonic.bi",
      "There is no big integer representation for a mnemonic.",
      { data: "" }
    );
  }
  /**
   * Generates a mnemonic as encoded bytes.
   *
   * @returns {Uint8Array} The bytes representation of the words with spaces.
   */
  get bytes() {
    return _Mnemonic.ENCODER.encode(_Mnemonic.of().join(" "));
  }
  /**
   * Throws an exception because the mnemonic cannot be represented as a number.
   * @returns {bigint} The number representation of the mnemonic.
   * @throws {InvalidOperation} The mnemonic cannot be represented as a number.
   * @override {@link VeChainDataModel#n}
   * @remark The conversion to number is not supported for a mnemonic.
   */
  get n() {
    throw new InvalidOperation10(
      "Mnemonic.n",
      "There is no number representation for a mnemonic.",
      { data: "" }
    );
  }
  /**
   *
   * @param that - The mnemonic to compare with.
   */
  compareTo(_that) {
    throw new InvalidOperation10(
      "Mnemonic.compareTo",
      "There is no comparison for a mnemonic since it is not stored in memory.",
      { data: "" }
    );
  }
  isEqual(_that) {
    throw new InvalidOperation10(
      "Mnemonic.isEqual",
      "There is no comparison for a mnemonic since it is not stored in memory.",
      { data: "" }
    );
  }
  /**
   * Convert the number of words to the corresponding strength.
   *
   * @param numberOfWords - The number of words.
   *
   * @returns {number} The corresponding strength.
   *
   * @throws {InvalidDataType} If the number of words is not valid.
   */
  static wordsNoToStrength(numberOfWords) {
    switch (numberOfWords) {
      case 12:
        return 128;
      case 15:
        return 160;
      case 18:
        return 192;
      case 21:
        return 224;
      case 24:
        return 256;
      default:
        throw new InvalidDataType9(
          "Mnemonic.wordsNoToStrength",
          "not a valid number of words",
          { numberOfWords }
        );
    }
  }
  // Legacy method, probably should be part of a Private Key class (ofMnemonic) #1122
  /**
   * Derives a private key from a given list of
   * [BIP39 Mnemonic Words](https://github.com/bitcoin/bips/blob/master/bip-0039.mediawiki)
   * and a derivation path as in the examples.
   *
   * @example `m/0` (default)
   * @example `m/0/2`
   * @example `m/0/2/4/6`
   *
   * @param {string[]} words - The set of words used for mnemonic generation.
   * @param {string} [path='m/0'] - The derivation path from the current node.
   *
   * @returns {Uint8Array} - The derived private key as a Uint8Array.
   *
   * @throws {InvalidHDKey}
   *
   * @remarks Security auditable method, depends on
   * * {@link HDKey}.
   */
  static toPrivateKey(words, path = "m/0") {
    const root = HDKey2.fromMnemonic(words);
    try {
      return root.derive(path).privateKey;
    } catch (error) {
      throw new InvalidHDKey3(
        "mnemonic.derivePrivateKey()",
        "Invalid derivation path given as input.",
        { derivationPath: path },
        error
      );
    }
  }
  /**
   * Generates a
   * [BIP39 Mnemonic Words](https://github.com/bitcoin/bips/blob/master/bip-0039.mediawiki)
   * phrase using the specified wordlist size and random generator.
   *
   * @param {WordlistSizeType} wordlistSize - The number of words to generate the mnemonic.
   * @param {function} [randomGenerator] - The random generator function used to generate the entropy.
   *
   * @returns {Mnemonic} The generated mnemonic.
   *
   * @throws {InvalidDataType} If the number of words is not valid.
   *
   * @remarks Security auditable method, depends on
   * * [entropyToMnemonic](https://github.com/paulmillr/scure-bip39);
   * * [generateMnemonic](https://github.com/paulmillr/scure-bip39);
   * * `randomGenerator` - **Must provide a cryptographic secure source of entropy
   *    else any secure audit certification related with this software is invalid.**
   */
  static of(wordlistSize = 12, randomGenerator) {
    try {
      const strength = _Mnemonic.wordsNoToStrength(wordlistSize);
      if (randomGenerator != null) {
        const numberOfBytes = strength / 8;
        return entropyToMnemonic(
          randomGenerator(numberOfBytes),
          wordlist
        ).split(" ");
      }
      return generateMnemonic(wordlist, strength).split(" ");
    } catch (error) {
      throw new InvalidHDKeyMnemonic2(
        "Mnemonic.of",
        "error while generating mnemonic",
        { wordlistSize },
        error
      );
    }
  }
  /**
   * Check if the given mnemonic words are valid.
   *
   * @param {string | string[]} words - The mnemonic words to check.
   *
   * @returns {boolean} true if the words are valid, false otherwise.
   *
   * @remarks Security auditable method, depends on
   * * [validateMnemonic](https://github.com/paulmillr/scure-bip39).
   */
  static isValid(words) {
    const wordsToValidate = Array.isArray(words) ? words.join(" ") : words;
    return validateMnemonic(wordsToValidate, wordlist);
  }
};
var mnemonic = {
  deriveAddress: (words, path = "m/0") => Address.ofMnemonic(words, path).toString(),
  derivePrivateKey: (words, path = "m/0") => Mnemonic.toPrivateKey(words, path),
  generate: (wordlistSize, randomGenerator) => Mnemonic.of(wordlistSize, randomGenerator),
  isValid: (words) => Mnemonic.isValid(words)
};

// src/vcdm/Quantity.ts
import { InvalidDataType as InvalidDataType10 } from "@vechain/sdk-errors";
var Quantity = class _Quantity extends HexUInt {
  /**
   * Creates a Quantity instance from a bigint or number given expression
   *
   * @param {bigint | number} exp - The value to be expressed as Quantity object:
   * * bigint must be positive;
   * * number must be positive, it is converted to bigint to create the Quantity.
   *
   * @returns {Quantity} - The new Quantity object.
   *
   * @throws {InvalidDataType} - If the provided expression is not a positive integer value.
   */
  static of(exp) {
    try {
      const huint = HexUInt.of(exp);
      let cue = 0;
      while (cue < huint.digits.length && huint.digits.at(cue) === "0") {
        cue++;
      }
      return new _Quantity(
        huint.sign,
        cue === huint.digits.length ? "0" : huint.digits.slice(cue)
      );
    } catch (e) {
      throw new InvalidDataType10(
        "Quantity.of",
        "not a Quantity expression",
        { exp: `${exp}` },
        // Needed to serialize bigint values.
        e
      );
    }
  }
};

// src/vcdm/Revision.ts
import { InvalidDataType as InvalidDataType11 } from "@vechain/sdk-errors";
var Revision = class _Revision extends Txt {
  /**
   * Regular expression pattern for revision strings.
   * Revision strings can be one of the following:
   * - "best": indicating the best revision
   * - "finalized": indicating a finalized revision
   * - A positive numeric string indicating a specific revision
   *
   * @type {RegExp}
   */
  static REGEX_DECIMAL_REVISION = /^(best|finalized|\d+)$/;
  /**
   * Determines if the given value is valid.
   * This is true if the given value is
   * - "best" string or {@link Txt}: indicating the best revision;
   * - "finalized" string or {@link Txt}: indicating a finalized revision;
   * - a positive number;
   * - a positive numeric decimal or `0x` prefixed hexadecimal string indicating a specific revision,
   *
   * @param {bigint | number | string | Hex | Txt} value - The value to be validated.
   * @returns {boolean} - Returns `true` if the value is valid, `false` otherwise.
   */
  static isValid(value) {
    if (typeof value === "number") {
      return Number.isInteger(value) && value >= 0;
    }
    return HexUInt.isValid0x(value) || _Revision.REGEX_DECIMAL_REVISION.test(value);
  }
  /**
   * Creates a new Revision object from the given value.
   *
   * @param {bigint | number | string | Uint8Array | Hex } value - The value to create the Revision from:
   * * {@link Hex} must be positive;
   * * {@link Uint8Array} is decoded as a string: see {@link Txt.of}.
   *
   * @returns {Revision} - The created Revision object.
   *
   *  @throws {InvalidDataType} if the given value is not a valid revision: see {@link isValid}.
   *
   * @remarks The string representation of the revision is always expressed as a number in base 10.
   * @remarks The {@link Uint8Array} value is decoded as a string content: see {@link Txt.of}.
   */
  static of(value) {
    try {
      let txt;
      if (value instanceof Hex) {
        txt = value.bi.toString();
      } else if (value instanceof Uint8Array) {
        txt = Txt.of(value).toString();
      } else {
        txt = `${value}`;
      }
      if (_Revision.isValid(txt)) {
        return new _Revision(txt);
      }
      throw new InvalidDataType11("Revision.of", "not a revision", {
        value: `${value}`
      });
    } catch (e) {
      throw new InvalidDataType11("Revision.of", "not a revision", {
        value: `${value}`,
        e
      });
    }
  }
};
var revisionUtils = {
  isRevisionAccount: (revision) => Revision.isValid(revision),
  isRevisionBlock: (revision) => Revision.isValid(revision)
};

// src/vcdm/ThorId.ts
import { InvalidDataType as InvalidDataType12 } from "@vechain/sdk-errors";
var ThorId = class _ThorId extends HexUInt {
  /**
   * Number of digits to represent a Thor ID value.
   *
   * @remarks The `0x` prefix is excluded.
   *
   * @type {number}
   */
  static DIGITS = 64;
  /**
   * Constructs a ThorId object with the provided hexadecimal value.
   *
   * @param {HexUInt} huint - The hexadecimal value representing the ThorId.
   */
  constructor(huint) {
    super(Hex.POSITIVE, huint.fit(_ThorId.DIGITS).digits);
  }
  /**
   * Check if the given expression is a valid ThorId.
   *
   * @param {string} exp - The expression to be validated.
   *
   * @return {boolean} Returns true if the expression is a valid ThorId, false otherwise.
   */
  static isValid(exp) {
    return Hex.isValid(exp) && HexUInt.REGEX_HEXUINT_PREFIX.test(exp) ? exp.length === _ThorId.DIGITS + 2 : exp.length === _ThorId.DIGITS;
  }
  /**
   * Determines whether the given string is a valid hex number prefixed with '0x'.
   *
   * @param {string} exp - The hex number to be checked.
   *
   *  @returns {boolean} - True if the hex number is valid, false otherwise.
   */
  static isValid0x(exp) {
    return HexUInt.REGEX_HEXUINT_PREFIX.test(exp) && _ThorId.isValid(exp);
  }
  /**
   * Creates a new ThorId object from the given expression.
   *
   * @param {bigint | number | string | Hex | Uint8Array} exp - The expression to create the ThorId from.
   *     It can be one of the following types:
   *     - bigint: A BigInteger value that represents the ThorId.
   *     - number: A number value that represents the ThorId.
   *     - string: A string value that represents the ThorId.
   *     - HexUInt: A HexUInt object that represents the ThorId.
   *     - Uint8Array: A Uint8Array object that represents the ThorId.
   *
   * @returns {ThorId} - A new ThorId object created from the given expression.
   *
   * @throws {InvalidDataType} If the given expression is not a valid hexadecimal positive integer expression.
   */
  static of(exp) {
    try {
      if (exp instanceof HexUInt) {
        return new _ThorId(exp);
      }
      return new _ThorId(HexUInt.of(exp));
    } catch (e) {
      throw new InvalidDataType12(
        "ThorId.of",
        "not a ThorId expression",
        { exp: `${exp}` },
        // Needed to serialize bigint values.
        e
      );
    }
  }
};

// src/certificate/Certificate.ts
import {
  CertificateSignatureMismatch,
  InvalidDataType as InvalidDataType13
} from "@vechain/sdk-errors";
var Certificate = class _Certificate {
  /**
   * Return the intended use or context of the certificate.
   */
  purpose;
  /**
   * Returns the content of the certificate.
   */
  payload;
  /**
   * Return the description of the context of validity of this certificate.
   */
  domain;
  /**
   * The value expressed as of milliseconds elapsed since the
   * [epoch](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date#the_epoch_timestamps_and_invalid_date),
   * when the certificate was issued.
   *
   * @remarks
   * The value is a natural number in the safe integer range of JS `number` type.
   */
  timestamp;
  /**
   * Return the address of the entity signed the certificate, as
   * a lowercase hexadecimal expression prefixed by `0x`.
   *
   * @remarks
   * Normalized lowercase prefixed expression is needed because
   * the content of this property is part of the {@signature} computation:
   * certificates made from checksum case address of the signer should
   * result valid as the certificate made from the same signer address
   * not checksum case.
   */
  signer;
  /**
   * Return the signature computed evaluating the properties of this object
   * and the private key of the signer.
   *
   * @remarks
   * The signature is a lowercase hexadecimal expression prefixed with `0x`.
   */
  signature;
  /**
   * Returns a new instance of this class assuring the formal validity of the
   * arguments used to build the object.
   *
   * @param {string} purpose - The purpose of the certificate.
   * @param {Object} payload - The payload containing type and content.
   * @param {string} payload.type - The type of the payload.
   * @param {string} payload.content - The content of the payload.
   * @param {string} domain - The domain associated with the certificate.
   * @param {number} timestamp - The time at which the certificate is created;
   * must be a positive safe integer.
   * @param {string} signer - The signer of the certificate;
   * must be a valid address.
   * @param {string|undefined} [signature] - The signature of the certificate;
   * optional parameter.
   *
   * @throws {InvalidDataType} If timestamp is not a positive safe integer.
   * @throws {InvalidDataType} If signer is not a valid address.
   * @throws {InvalidDataType} If signature is invalid.
   *
   * @remarks
   * The `signer` address is represented lowercase and `0x` prefixed.
   */
  constructor(purpose, payload, domain, timestamp, signer, signature) {
    if (Number.isSafeInteger(timestamp) && timestamp >= 0) {
      if (Address.isValid(signer)) {
        this.purpose = purpose;
        this.payload = payload;
        this.domain = domain;
        this.timestamp = timestamp;
        this.signer = signer.toString().toLowerCase();
        try {
          this.signature = typeof signature === "string" ? HexUInt.of(signature).alignToBytes().toString() : signature;
        } catch (e) {
          throw new InvalidDataType13(
            "Certificate.constructor",
            "invalid signature",
            { signature },
            e
          );
        }
      } else
        throw new InvalidDataType13(
          "Certificate.constructor",
          "signer is not an address",
          { signer }
        );
    } else
      throw new InvalidDataType13(
        "Certificate.constructor",
        "not positive safe integer timestamp",
        { timestamp }
      );
  }
  /**
   * Encodes a given object into a Uint8Array representation
   * applying the following operation to normalize the content:
   * - the properties are sorted in ascending alphabetic order;
   * - the key/value properties are delimited with `"` when serialized as JSON
   *   before to be encoded as bytes;
   * - any not meaningful blank characters are ignored;
   * - the JSON representation of this object is byte encoded using the UTF-8
   *   [normalization form for canonical composition](https://en.wikipedia.org/wiki/Unicode_equivalence#Normal_forms).
   *
   * @param {unknown} object - The input object to be encoded.
   * @return {Uint8Array} The encoded Uint8Array representation of the input object.
   */
  static encode(object) {
    return Txt.of(fastJsonStableStringify(object)).bytes;
  }
  /**
   * Return `true` if the current instance has a signature.
   *
   * @return {boolean} `true` if the signature is a valid hexadecimal string,
   * otherwise `false`.
   */
  isSigned() {
    return typeof this.signature === "string" && HexUInt.isValid(this.signature);
  }
  /**
   * Creates a new Certificate instance from the provided CertificateData.
   *
   * @param {CertificateData} data - The data required to create the Certificate.
   * @return {Certificate} A new Certificate instance.
   * @throws {InvalidDataType} If the provided data is invalid:
   * - if timestamp is not a positive safe integer;
   * - if signer is not a valid address;
   * - if signature is an invalid hexadecimal expression.
   *
   * @remarks
   * This method supports {@link signer}
   * [mixed-case checksum address encoding](https://eips.ethereum.org/EIPS/eip-55).
   *
   * @see constructor
   */
  static of(data) {
    try {
      return new _Certificate(
        data.purpose,
        data.payload,
        data.domain,
        data.timestamp,
        data.signer,
        data.signature
      );
    } catch (e) {
      throw new InvalidDataType13(
        "Certificate.of",
        "invalid certificate data",
        { certifiable: data },
        e
      );
    }
  }
  /**
   * Signs the current object using a given private key.
   *
   * The {@link signature} is computed encoding this object according
   * the following normalization rules:
   * - the {@link signature} property is ignored, because its value
   *   is the result of this method.
   * - the properties are sorted in ascending alphabetic order;
   * - the key/value properties are delimited with `"` when serialized as JSON
   *   before to be encoded as bytes;
   * - any not meaningful blank characters are ignored;
   * - the JSON representation of this object is byte encoded using the UTF-8
   *   [normalization form for canonical composition](https://en.wikipedia.org/wiki/Unicode_equivalence#Normal_forms).
   *
   * @param {Uint8Array} privateKey - The private key used for signing.
   * @return {this} The current instance after signing.
   *
   * @throws {InvalidOperation} - If a hash error occurs.
   * @throws {InvalidSecp256k1PrivateKey} - If the private key is not a valid 32-byte private key.
   *
   * @remarks Security auditable method, depends on
   * * {@link Blake2b256.of};
   * * {@link Secp256k1.sign}.
   *
   * @see encode
   * @see verify
   */
  sign(privateKey) {
    this.signature = void 0;
    this.signature = HexUInt.of(
      Secp256k1.sign(
        Blake2b256.of(_Certificate.encode(this)).bytes,
        privateKey
      )
    ).toString();
    return this;
  }
  /**
   * Verifies the certificate by checking its signature.
   *
   * @throws {CertificateSignatureMismatch} if the certificate
   * - is not signed, or
   * - the signature does not match the signer's public key.
   *
   * @remarks
   * This method supports {@link signer}
   * [mixed-case checksum address encoding](https://eips.ethereum.org/EIPS/eip-55).
   *
   * @remarks Security auditable method, depends on
   * * {@link Blake2b256.of};
   * * {@link Secp256k1.recover}.
   */
  verify() {
    if (!this.isSigned())
      throw new CertificateSignatureMismatch(
        "Certificate.verify",
        "signature missing",
        { certificate: this }
      );
    const signer = Address.ofPublicKey(
      Secp256k1.recover(
        Blake2b256.of(
          _Certificate.encode({ ...this, signature: void 0 })
        ).bytes,
        HexUInt.of(this.signature).bytes
      )
    );
    if (signer.toString().toLowerCase() !== this.signer)
      throw new CertificateSignatureMismatch(
        "Certificate.verify",
        "signature doesn't match with signer's public key",
        { certificate: this }
      );
  }
};

// src/keystore/keystore.ts
import { VeChainSDKLogger } from "@vechain/sdk-logging";

// src/keystore/cryptography/ethers/keystore.ts
import {
  InvalidKeystore,
  InvalidKeystoreParams,
  stringifyData
} from "@vechain/sdk-errors";
import { ethers } from "ethers";

// src/keystore/cryptography/ethers/const/keystore.ts
var SCRYPT_PARAMS = {
  N: 131072,
  r: 8,
  p: 1
};

// src/keystore/cryptography/ethers/keystore.ts
async function encrypt(privateKey, password) {
  const derivePublicKey = Secp256k1.derivePublicKey(privateKey);
  const deriveAddress = Address.ofPublicKey(derivePublicKey).toString();
  const keystoreAccount = {
    address: deriveAddress,
    privateKey: HexUInt.of(privateKey).toString()
  };
  const encryptOptions = {
    scrypt: {
      N: SCRYPT_PARAMS.N,
      r: SCRYPT_PARAMS.r,
      p: SCRYPT_PARAMS.p
    }
  };
  const keystoreJsonString = await ethers.encryptKeystoreJson(
    keystoreAccount,
    password,
    encryptOptions
  );
  return JSON.parse(keystoreJsonString);
}
async function decrypt(keystore4, password) {
  if (!isValid(keystore4)) {
    throw new InvalidKeystore(
      "keystore.decrypt()",
      "Invalid keystore. Ensure the keystore is properly formatted and contains the necessary data.",
      { keystore: keystore4 }
    );
  }
  try {
    return await ethers.decryptKeystoreJson(
      stringifyData(keystore4),
      password
    );
  } catch (e) {
    throw new InvalidKeystoreParams(
      "keystore.decrypt()",
      "Decryption failed: Invalid Password for the given keystore.",
      // @NOTE: We are not exposing the password in the error data for security reasons.
      {
        keystore: keystore4
      }
    );
  }
}
function isValid(keystore4) {
  return ethers.isKeystoreJson(stringifyData(keystore4));
}
var keystore = { encrypt, decrypt, isValid };

// src/keystore/cryptography/experimental/keystore.ts
import * as n_utils from "@noble/curves/abstract/utils";
import { InvalidKeystoreParams as InvalidKeystoreParams2, stringifyData as stringifyData2 } from "@vechain/sdk-errors";
import { ctr } from "@noble/ciphers/aes";
import { scrypt } from "@noble/hashes/scrypt";
var KEYSTORE_CRYPTO_CIPHER = "aes-128-ctr";
var KEYSTORE_CRYPTO_PARAMS_DKLEN = 32;
var KEYSTORE_CRYPTO_KDF = "scrypt";
var KEYSTORE_VERSION = 3;
var SCRYPT_PARAMS2 = {
  N: 131072,
  r: 8,
  p: 1
};
function decodeScryptParams(keystore4) {
  const salt = n_utils.hexToBytes(keystore4.crypto.kdfparams.salt);
  const N = keystore4.crypto.kdfparams.n;
  const r = keystore4.crypto.kdfparams.r;
  const p = keystore4.crypto.kdfparams.p;
  if (N <= 0 || (N & N - 1) !== 0)
    throw new InvalidKeystoreParams2(
      "(EXPERIMENTAL) keystore.decodeScryptParams()",
      "Decryption failed: invalid  keystore.crypto.kdfparams.n parameter.",
      {
        keystore: keystore4,
        N
      }
    );
  if (r <= 0 || p <= 0)
    throw new InvalidKeystoreParams2(
      "(EXPERIMENTAL) keystore.decodeScryptParams()",
      "Decryption failed: both keystore.crypto.kdfparams.r or keystore.crypto.kdfparams.p parameter must be > 0.",
      {
        keystore: keystore4,
        r,
        p
      }
    );
  const dkLen = keystore4.crypto.kdfparams.dklen;
  if (dkLen !== KEYSTORE_CRYPTO_PARAMS_DKLEN)
    throw new InvalidKeystoreParams2(
      "(EXPERIMENTAL) keystore.decodeScryptParams()",
      `Decryption failed: keystore.crypto.kdfparams.dklen parameter must be ${KEYSTORE_CRYPTO_PARAMS_DKLEN}`,
      {
        keystore: keystore4,
        dkLen
      }
    );
  return {
    N,
    dkLen: KEYSTORE_CRYPTO_PARAMS_DKLEN,
    name: KEYSTORE_CRYPTO_KDF,
    p,
    r,
    salt
  };
}
function encodeScryptParams(options) {
  const salt = options.salt ?? Secp256k1.randomBytes(KEYSTORE_CRYPTO_PARAMS_DKLEN);
  let N = SCRYPT_PARAMS2.N;
  let r = SCRYPT_PARAMS2.r;
  let p = SCRYPT_PARAMS2.p;
  if (options.scrypt != null) {
    if (options.scrypt.N != null) {
      N = options.scrypt.N;
    }
    if (options.scrypt.r != null) {
      r = options.scrypt.r;
    }
    if (options.scrypt.p != null) {
      p = options.scrypt.p;
    }
  }
  if (N <= 0 || (BigInt(N) & BigInt(N - 1)) !== BigInt(0))
    throw new InvalidKeystoreParams2(
      "(EXPERIMENTAL) keystore.encodeScryptParams()",
      "Encryption failed: invalid options.scrypt.N parameter.",
      {
        options,
        N
      }
    );
  if (r <= 0 || !Number.isSafeInteger(r))
    throw new InvalidKeystoreParams2(
      "(EXPERIMENTAL) keystore.encodeScryptParams()",
      "Encryption failed: invalid options.scrypt.r parameter.",
      {
        options,
        r
      }
    );
  if (p <= 0 || !Number.isSafeInteger(p))
    throw new InvalidKeystoreParams2(
      "(EXPERIMENTAL) keystore.encodeScryptParams()",
      "Encryption failed: invalid options.scrypt.p parameter.",
      {
        options,
        p
      }
    );
  return {
    name: KEYSTORE_CRYPTO_KDF,
    dkLen: KEYSTORE_CRYPTO_PARAMS_DKLEN,
    N,
    p,
    r,
    salt
  };
}
function encrypt2(privateKey, password) {
  return encryptKeystore(privateKey, password, {
    scrypt: {
      N: SCRYPT_PARAMS2.N,
      r: SCRYPT_PARAMS2.r,
      p: SCRYPT_PARAMS2.p
    }
  });
}
function encryptKeystore(privateKey, password, options) {
  try {
    const kdf = encodeScryptParams(options);
    const key = scrypt(password, kdf.salt, {
      N: kdf.N,
      r: kdf.r,
      p: kdf.p,
      dkLen: kdf.dkLen
    });
    const iv = options.iv ?? Secp256k1.randomBytes(16);
    if (iv.length !== 16)
      throw new InvalidKeystoreParams2(
        "(EXPERIMENTAL) keystore.encryptKeystore()",
        "Encryption failed: invalid options.iv length.",
        { iv }
      );
    const uuidRandom = options.uuid ?? Secp256k1.randomBytes(16);
    if (uuidRandom.length !== 16)
      throw new InvalidKeystoreParams2(
        "(EXPERIMENTAL) keystore.encryptKeystore()",
        "Encryption failed: invalid options.uuid length.",
        { uuidRandom }
      );
    const macPrefix = key.slice(16, 32);
    const ciphertext = ctr(key.slice(0, 16), iv).encrypt(privateKey);
    return {
      address: Address.ofPrivateKey(privateKey).toString(),
      crypto: {
        cipher: KEYSTORE_CRYPTO_CIPHER,
        cipherparams: {
          iv: Hex.of(iv).digits
        },
        ciphertext: Hex.of(ciphertext).digits,
        kdf: "scrypt",
        kdfparams: {
          dklen: KEYSTORE_CRYPTO_PARAMS_DKLEN,
          n: kdf.N,
          p: kdf.p,
          r: kdf.r,
          salt: Hex.of(kdf.salt).digits
        },
        // Compute the message authentication code, used to check the password.
        mac: Keccak256.of(n_utils.concatBytes(macPrefix, ciphertext)).digits
      },
      id: uuidV4(uuidRandom),
      version: KEYSTORE_VERSION
    };
  } finally {
    privateKey.fill(0);
    password.fill(0);
  }
}
function decrypt2(keystore4, password) {
  return decryptKeystore(keystore4, password);
}
function decryptKeystore(keystore4, password) {
  try {
    if (keystore4.crypto.cipher.toLowerCase() !== KEYSTORE_CRYPTO_CIPHER)
      throw new InvalidKeystoreParams2(
        "(EXPERIMENTAL) keystore.decryptKeystore()",
        "Decryption failed: unsupported crypto cipher algorithm.",
        { cipher: keystore4.crypto.cipher.toLowerCase() }
      );
    if (keystore4.crypto.kdf.toLowerCase() !== KEYSTORE_CRYPTO_KDF)
      throw new InvalidKeystoreParams2(
        "(EXPERIMENTAL) keystore.decryptKeystore()",
        "Decryption failed: unsupported crypto key derivation function.",
        { keyDerivationFunction: keystore4.crypto.kdf.toLowerCase() }
      );
    if (keystore4.version !== KEYSTORE_VERSION)
      throw new InvalidKeystoreParams2(
        "(EXPERIMENTAL) keystore.decryptKeystore()",
        "Decryption failed: unsupported keystore version.",
        { version: keystore4.version }
      );
    const kdf = decodeScryptParams(keystore4);
    const key = scrypt(password, kdf.salt, {
      N: kdf.N,
      r: kdf.r,
      p: kdf.p,
      dkLen: kdf.dkLen
    });
    const ciphertext = n_utils.hexToBytes(keystore4.crypto.ciphertext);
    if (keystore4.crypto.mac !== Keccak256.of(n_utils.concatBytes(key.slice(16, 32), ciphertext)).digits) {
      throw new InvalidKeystoreParams2(
        "(EXPERIMENTAL) keystore.decryptKeystore()",
        "Decryption failed: Invalid Password for the given keystore.",
        // @NOTE: We are not exposing the password in the error data for security reasons.
        {
          keystore: keystore4
        }
      );
    }
    const privateKey = ctr(
      key.slice(0, 16),
      n_utils.hexToBytes(keystore4.crypto.cipherparams.iv)
    ).decrypt(ciphertext);
    const address = Address.ofPrivateKey(privateKey).toString();
    if (keystore4.address !== "" && address !== Address.checksum(Hex.of(keystore4.address))) {
      throw new InvalidKeystoreParams2(
        "(EXPERIMENTAL) keystore.decryptKeystore()",
        "Decryption failed: address/password mismatch.",
        { keystoreAddress: keystore4.address }
      );
    }
    return {
      address,
      // @note: Convert the private key to a string to be compatible with ethers
      privateKey: Hex.of(privateKey).toString()
    };
  } finally {
    password.fill(0);
  }
}
function isValid2(keystore4) {
  try {
    const copy = JSON.parse(stringifyData2(keystore4));
    if (copy.crypto.cipher.toLowerCase() === KEYSTORE_CRYPTO_CIPHER && copy.crypto.kdf.toLowerCase() === KEYSTORE_CRYPTO_KDF && copy.version === KEYSTORE_VERSION) {
      return true;
    }
  } catch (e) {
  }
  return false;
}
function uuidV4(bytes) {
  bytes[6] = bytes[6] & 15 | 64;
  bytes[8] = bytes[8] & 63 | 128;
  const value = Hex.of(bytes).digits;
  return [
    value.substring(0, 8),
    value.substring(8, 12),
    value.substring(12, 16),
    value.substring(16, 20),
    value.substring(20, 32)
  ].join("-");
}
var keystore2 = { decrypt: decrypt2, encrypt: encrypt2, isValid: isValid2 };

// src/keystore/keystore.ts
var EXPERIMENTAL_CRYPTOGRAPHY = false;
function useExperimentalCryptography(experimentalCryptography) {
  EXPERIMENTAL_CRYPTOGRAPHY = experimentalCryptography;
}
async function encrypt3(privateKey, password) {
  if (EXPERIMENTAL_CRYPTOGRAPHY)
    VeChainSDKLogger("warning").log({
      title: `Experimental cryptography`,
      messages: [
        `Remember, you are using an experimental cryptography library.`,
        "functions: keystore.encrypt"
      ]
    });
  return EXPERIMENTAL_CRYPTOGRAPHY ? keystore2.encrypt(privateKey, Txt.of(password).bytes) : await keystore.encrypt(privateKey, password);
}
async function decrypt3(keystore4, password) {
  if (EXPERIMENTAL_CRYPTOGRAPHY)
    VeChainSDKLogger("warning").log({
      title: `Experimental cryptography`,
      messages: [
        `Remember, you are using an experimental cryptography library.`,
        "functions: keystore.decrypt"
      ]
    });
  return EXPERIMENTAL_CRYPTOGRAPHY ? keystore2.decrypt(keystore4, Txt.of(password).bytes) : await keystore.decrypt(keystore4, password);
}
function isValid3(keystore4) {
  if (EXPERIMENTAL_CRYPTOGRAPHY)
    VeChainSDKLogger("warning").log({
      title: `Experimental cryptography`,
      messages: [
        `Remember, you are using an experimental cryptography library.`,
        "functions: keystore.isValid"
      ]
    });
  return EXPERIMENTAL_CRYPTOGRAPHY ? keystore2.isValid(keystore4) : keystore.isValid(keystore4);
}
var keystore3 = { encrypt: encrypt3, decrypt: decrypt3, isValid: isValid3, useExperimentalCryptography };

// src/utils/const/abi.ts
var ERC20_ABI = [
  { inputs: [], stateMutability: "nonpayable", type: "constructor" },
  {
    inputs: [
      { internalType: "address", name: "spender", type: "address" },
      { internalType: "uint256", name: "allowance", type: "uint256" },
      { internalType: "uint256", name: "needed", type: "uint256" }
    ],
    name: "ERC20InsufficientAllowance",
    type: "error"
  },
  {
    inputs: [
      { internalType: "address", name: "sender", type: "address" },
      { internalType: "uint256", name: "balance", type: "uint256" },
      { internalType: "uint256", name: "needed", type: "uint256" }
    ],
    name: "ERC20InsufficientBalance",
    type: "error"
  },
  {
    inputs: [
      { internalType: "address", name: "approver", type: "address" }
    ],
    name: "ERC20InvalidApprover",
    type: "error"
  },
  {
    inputs: [
      { internalType: "address", name: "receiver", type: "address" }
    ],
    name: "ERC20InvalidReceiver",
    type: "error"
  },
  {
    inputs: [{ internalType: "address", name: "sender", type: "address" }],
    name: "ERC20InvalidSender",
    type: "error"
  },
  {
    inputs: [{ internalType: "address", name: "spender", type: "address" }],
    name: "ERC20InvalidSpender",
    type: "error"
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "owner",
        type: "address"
      },
      {
        indexed: true,
        internalType: "address",
        name: "spender",
        type: "address"
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "value",
        type: "uint256"
      }
    ],
    name: "Approval",
    type: "event"
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "from",
        type: "address"
      },
      {
        indexed: true,
        internalType: "address",
        name: "to",
        type: "address"
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "value",
        type: "uint256"
      }
    ],
    name: "Transfer",
    type: "event"
  },
  {
    inputs: [
      { internalType: "address", name: "owner", type: "address" },
      { internalType: "address", name: "spender", type: "address" }
    ],
    name: "allowance",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [
      { internalType: "address", name: "spender", type: "address" },
      { internalType: "uint256", name: "value", type: "uint256" }
    ],
    name: "approve",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    inputs: [{ internalType: "address", name: "account", type: "address" }],
    name: "balanceOf",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [],
    name: "decimals",
    outputs: [{ internalType: "uint8", name: "", type: "uint8" }],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [],
    name: "name",
    outputs: [{ internalType: "string", name: "", type: "string" }],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [],
    name: "symbol",
    outputs: [{ internalType: "string", name: "", type: "string" }],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [],
    name: "totalSupply",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [
      { internalType: "address", name: "to", type: "address" },
      { internalType: "uint256", name: "value", type: "uint256" }
    ],
    name: "transfer",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    inputs: [
      { internalType: "address", name: "from", type: "address" },
      { internalType: "address", name: "to", type: "address" },
      { internalType: "uint256", name: "value", type: "uint256" }
    ],
    name: "transferFrom",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "nonpayable",
    type: "function"
  }
];
var ERC721_ABI = [
  {
    inputs: [],
    stateMutability: "nonpayable",
    type: "constructor"
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "sender",
        type: "address"
      },
      {
        internalType: "uint256",
        name: "tokenId",
        type: "uint256"
      },
      {
        internalType: "address",
        name: "owner",
        type: "address"
      }
    ],
    name: "ERC721IncorrectOwner",
    type: "error"
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "operator",
        type: "address"
      },
      {
        internalType: "uint256",
        name: "tokenId",
        type: "uint256"
      }
    ],
    name: "ERC721InsufficientApproval",
    type: "error"
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "approver",
        type: "address"
      }
    ],
    name: "ERC721InvalidApprover",
    type: "error"
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "operator",
        type: "address"
      }
    ],
    name: "ERC721InvalidOperator",
    type: "error"
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "owner",
        type: "address"
      }
    ],
    name: "ERC721InvalidOwner",
    type: "error"
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "receiver",
        type: "address"
      }
    ],
    name: "ERC721InvalidReceiver",
    type: "error"
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "sender",
        type: "address"
      }
    ],
    name: "ERC721InvalidSender",
    type: "error"
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "tokenId",
        type: "uint256"
      }
    ],
    name: "ERC721NonexistentToken",
    type: "error"
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "owner",
        type: "address"
      },
      {
        indexed: true,
        internalType: "address",
        name: "approved",
        type: "address"
      },
      {
        indexed: true,
        internalType: "uint256",
        name: "tokenId",
        type: "uint256"
      }
    ],
    name: "Approval",
    type: "event"
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "owner",
        type: "address"
      },
      {
        indexed: true,
        internalType: "address",
        name: "operator",
        type: "address"
      },
      {
        indexed: false,
        internalType: "bool",
        name: "approved",
        type: "bool"
      }
    ],
    name: "ApprovalForAll",
    type: "event"
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "from",
        type: "address"
      },
      {
        indexed: true,
        internalType: "address",
        name: "to",
        type: "address"
      },
      {
        indexed: true,
        internalType: "uint256",
        name: "tokenId",
        type: "uint256"
      }
    ],
    name: "Transfer",
    type: "event"
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "to",
        type: "address"
      },
      {
        internalType: "uint256",
        name: "tokenId",
        type: "uint256"
      }
    ],
    name: "approve",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "owner",
        type: "address"
      }
    ],
    name: "balanceOf",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "tokenId",
        type: "uint256"
      }
    ],
    name: "getApproved",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "owner",
        type: "address"
      },
      {
        internalType: "address",
        name: "operator",
        type: "address"
      }
    ],
    name: "isApprovedForAll",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "receiver",
        type: "address"
      }
    ],
    name: "mintItem",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256"
      }
    ],
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    inputs: [],
    name: "name",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "tokenId",
        type: "uint256"
      }
    ],
    name: "ownerOf",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "from",
        type: "address"
      },
      {
        internalType: "address",
        name: "to",
        type: "address"
      },
      {
        internalType: "uint256",
        name: "tokenId",
        type: "uint256"
      }
    ],
    name: "safeTransferFrom",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "from",
        type: "address"
      },
      {
        internalType: "address",
        name: "to",
        type: "address"
      },
      {
        internalType: "uint256",
        name: "tokenId",
        type: "uint256"
      },
      {
        internalType: "bytes",
        name: "data",
        type: "bytes"
      }
    ],
    name: "safeTransferFrom",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "operator",
        type: "address"
      },
      {
        internalType: "bool",
        name: "approved",
        type: "bool"
      }
    ],
    name: "setApprovalForAll",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "bytes4",
        name: "interfaceId",
        type: "bytes4"
      }
    ],
    name: "supportsInterface",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [],
    name: "symbol",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "tokenId",
        type: "uint256"
      }
    ],
    name: "tokenURI",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "from",
        type: "address"
      },
      {
        internalType: "address",
        name: "to",
        type: "address"
      },
      {
        internalType: "uint256",
        name: "tokenId",
        type: "uint256"
      }
    ],
    name: "transferFrom",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function"
  }
];
var ERC1155_ABI = [
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "account",
        type: "address"
      },
      {
        indexed: true,
        internalType: "address",
        name: "operator",
        type: "address"
      },
      {
        indexed: false,
        internalType: "bool",
        name: "approved",
        type: "bool"
      }
    ],
    name: "ApprovalForAll",
    type: "event"
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "operator",
        type: "address"
      },
      {
        indexed: true,
        internalType: "address",
        name: "from",
        type: "address"
      },
      {
        indexed: true,
        internalType: "address",
        name: "to",
        type: "address"
      },
      {
        indexed: false,
        internalType: "uint256[]",
        name: "ids",
        type: "uint256[]"
      },
      {
        indexed: false,
        internalType: "uint256[]",
        name: "values",
        type: "uint256[]"
      }
    ],
    name: "TransferBatch",
    type: "event"
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "operator",
        type: "address"
      },
      {
        indexed: true,
        internalType: "address",
        name: "from",
        type: "address"
      },
      {
        indexed: true,
        internalType: "address",
        name: "to",
        type: "address"
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "id",
        type: "uint256"
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "value",
        type: "uint256"
      }
    ],
    name: "TransferSingle",
    type: "event"
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "string",
        name: "value",
        type: "string"
      },
      {
        indexed: true,
        internalType: "uint256",
        name: "id",
        type: "uint256"
      }
    ],
    name: "URI",
    type: "event"
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "account",
        type: "address"
      },
      {
        internalType: "uint256",
        name: "id",
        type: "uint256"
      }
    ],
    name: "balanceOf",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "address[]",
        name: "accounts",
        type: "address[]"
      },
      {
        internalType: "uint256[]",
        name: "ids",
        type: "uint256[]"
      }
    ],
    name: "balanceOfBatch",
    outputs: [
      {
        internalType: "uint256[]",
        name: "",
        type: "uint256[]"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "account",
        type: "address"
      },
      {
        internalType: "address",
        name: "operator",
        type: "address"
      }
    ],
    name: "isApprovedForAll",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "from",
        type: "address"
      },
      {
        internalType: "address",
        name: "to",
        type: "address"
      },
      {
        internalType: "uint256[]",
        name: "ids",
        type: "uint256[]"
      },
      {
        internalType: "uint256[]",
        name: "amounts",
        type: "uint256[]"
      },
      {
        internalType: "bytes",
        name: "data",
        type: "bytes"
      }
    ],
    name: "safeBatchTransferFrom",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "from",
        type: "address"
      },
      {
        internalType: "address",
        name: "to",
        type: "address"
      },
      {
        internalType: "uint256",
        name: "id",
        type: "uint256"
      },
      {
        internalType: "uint256",
        name: "amount",
        type: "uint256"
      },
      {
        internalType: "bytes",
        name: "data",
        type: "bytes"
      }
    ],
    name: "safeTransferFrom",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "operator",
        type: "address"
      },
      {
        internalType: "bool",
        name: "approved",
        type: "bool"
      }
    ],
    name: "setApprovalForAll",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "bytes4",
        name: "interfaceId",
        type: "bytes4"
      }
    ],
    name: "supportsInterface",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "id",
        type: "uint256"
      }
    ],
    name: "uri",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string"
      }
    ],
    stateMutability: "view",
    type: "function"
  }
];
var VIP180_ABI = ERC20_ABI;
var VIP181_ABI = ERC721_ABI;
var VIP210_ABI = ERC1155_ABI;

// src/utils/const/data.ts
var ZERO_BYTES = (size) => new Uint8Array(size);
var NUMERIC_REGEX = /(^-?\d+(\.\d+)?)$|(^-?\.\d+)$/;

// src/utils/data/data.ts
import * as nc_utils5 from "@noble/curves/abstract/utils";
import { InvalidDataType as InvalidDataType14 } from "@vechain/sdk-errors";
var decodeBytes32String = (hex) => {
  if (!Hex.isValid(hex) || Hex.of(hex).digits.length !== 64)
    throw new InvalidDataType14(
      "dataUtils.decodeBytes32String()",
      `Failed to decode value ${hex} to string. Value is not a valid hex string or it is not 64 characters long`,
      { value: hex }
    );
  const valueInBytes = Hex.of(hex).bytes;
  const firstZeroIndex = valueInBytes.findIndex((byte) => byte === 0);
  if (firstZeroIndex === 0) {
    const firstNotZeroIndex = valueInBytes.findIndex((byte) => byte !== 0);
    return Txt.of(valueInBytes.subarray(firstNotZeroIndex)).toString();
  } else if (firstZeroIndex !== -1) {
    return Txt.of(valueInBytes.subarray(0, firstZeroIndex)).toString();
  } else {
    return Txt.of(valueInBytes).toString();
  }
};
var encodeBytes32String = (value, zeroPadding = "right") => {
  try {
    const valueInBytes = Txt.of(value).bytes;
    if (valueInBytes.length > 32) {
      throw new InvalidDataType14(
        "dataUtils.encodeBytes32String()",
        `Failed to encode value ${value} to bytes32 string. Value exceeds 32 bytes.`,
        { value }
      );
    }
    const pad = ZERO_BYTES(32 - valueInBytes.length);
    return zeroPadding === "left" ? Hex.of(nc_utils5.concatBytes(pad, valueInBytes)).toString() : Hex.of(nc_utils5.concatBytes(valueInBytes, pad)).toString();
  } catch (e) {
    throw new InvalidDataType14(
      "dataUtils.encodeBytes32String()",
      `Failed to encode value ${value} to bytes32 string.`,
      { value },
      e
    );
  }
};
var dataUtils = {
  decodeBytes32String,
  encodeBytes32String
};

// src/transaction/Clause.ts
import { InvalidDataType as InvalidDataType15 } from "@vechain/sdk-errors";
var Clause = class _Clause {
  /**
   * Used internally in {@link Clause.callFunction}.
   */
  static FORMAT_TYPE = "json";
  /**
   * Used internally to tag a transaction not tranferring token amount.
   */
  static NO_VALUE = Hex.PREFIX + "0";
  /**
   * Used internally to tag a transaction without data.
   */
  static NO_DATA = Hex.PREFIX;
  /**
   * Used internally in {@link Clause.transferNFT} method.
   */
  static TRANSFER_NFT_FUNCTION = "transferFrom";
  /**
   * Used internally in {@link Clause.transferToken} method.
   */
  static TRANSFER_TOKEN_FUNCTION = "transfer";
  /**
   * Represents the address where:
   * - transfer token to, or
   * - invoke contract method on.
   */
  to;
  /**
   * Return the hexadecimal expression of the amount of VET or VTHO
   * token in {@link Units.wei} to transfer to the destination.
   *
   * @see {Clause.callFunction}
   * @see {Clause.transferToken}
   * @see {Clause.transferVET}
   */
  value;
  /**
   * Return the hexadecimal expression of the encoding of the arguments
   * of the called function of a smart contract.
   */
  data;
  /**
   * An optional comment to describe the purpose of the clause.
   */
  comment;
  /**
   * An optional  Application Binary Interface (ABI) of the called
   * function of a smart contract.
   */
  abi;
  /**
   * Creates an instance of the class.
   *
   * @param {string|null} to - The address to transfer token
   * or the smart contract to call, can be null.
   * @param {string} value - The token amount being transferred in wei units
   * as hexadecimal expression.
   * @param {string} data - Arguments of the smart contract function called
   * as encoded as a hexadecimal expression
   * @param {string} [comment] - An optional comment.
   * @param {string} [abi] - An optional ABI string.
   */
  constructor(to, value, data, comment, abi) {
    this.to = to;
    this.value = value;
    this.data = data;
    this.comment = comment;
    this.abi = abi;
  }
  /**
   * Return the amount of {@link VET} or {@link VTHO} token
   * in {@link Units.wei} to transfer to the destination.
   *
   * @return {FixedPointNumber} The amount as a fixed-point number.
   */
  amount() {
    return FixedPointNumber.of(HexInt.of(this.value).bi);
  }
  /**
   * Return a new clause to call a function of a smart contract.
   *
   * @param {Address} contractAddress - The address of the smart contract.
   * @param {ABIFunction} functionAbi - The ABI definition of the function to be called.
   * @param {unknown[]} args - The arguments for the function.
   * @param {VET} [amount=VET.of(FixedPointNumber.ZERO)] - The amount of VET to be sent with the transaction calling the function.
   * @param {ClauseOptions} [clauseOptions] - Optional clause settings.
   * @return {Clause} A clause object to call the function in a transaction.
   * @throws {InvalidDataType} Throws an error if the amount is not a finite positive value.
   */
  static callFunction(contractAddress, functionAbi, args, amount = VET.of(FixedPointNumber.ZERO), clauseOptions) {
    if (amount.value.isFinite() && amount.value.isPositive()) {
      return new _Clause(
        contractAddress.toString().toLowerCase(),
        Hex.PREFIX + amount.wei.toString(Hex.RADIX),
        functionAbi.encodeData(args).toString(),
        clauseOptions?.comment,
        clauseOptions?.includeABI === true ? functionAbi.format(_Clause.FORMAT_TYPE) : void 0
      );
    }
    throw new InvalidDataType15(
      "Clause.callFunction",
      "not finite positive amount",
      { amount: `${amount.value}` }
    );
  }
  /**
   * Returns a new clause to deploy a smart contract.
   *
   * @param {HexUInt} contractBytecode - The bytecode of the contract to be deployed.
   * @param {DeployParams} [deployParams] - Optional parameters to pass to the smart contract constructor.
   * @param {ClauseOptions} [clauseOptions] - Optional clause settings.
   * @return {Clause} The clause to deploy the smart contract as part of a transaction.
   */
  static deployContract(contractBytecode, deployParams, clauseOptions) {
    const data = deployParams !== null && deployParams !== void 0 ? contractBytecode.digits + ethersAbi.encodeParams(deployParams.types, deployParams.values).replace(Hex.PREFIX, "") : contractBytecode.digits;
    return new _Clause(
      null,
      _Clause.NO_VALUE,
      Hex.PREFIX + data,
      clauseOptions?.comment
    );
  }
  /**
   * Transfers an NFT from the sender to the recipient.
   *
   * @param {Address} contractAddress - The address of the NFT contract.
   * @param {Address} senderAddress - The address of the current owner (sender) of the NFT.
   * @param {Address} recipientAddress - The address of the new owner (recipient) of the NFT.
   * @param {HexUInt} tokenId - The unique identifier of the NFT to be transferred.
   * @param {ClauseOptions} [clauseOptions] - Optional clause settings.
   * @return {Clause} The clause object representing the transfer operation as part of a transaction.
   */
  static transferNFT(contractAddress, senderAddress, recipientAddress, tokenId, clauseOptions) {
    return _Clause.callFunction(
      contractAddress,
      ABIContract.ofAbi(ERC721_ABI).getFunction(
        _Clause.TRANSFER_NFT_FUNCTION
      ),
      [
        senderAddress.toString(),
        recipientAddress.toString(),
        tokenId.bi.toString()
      ],
      void 0,
      clauseOptions
    );
  }
  /**
   * Return a new clause to transfers the specified amount of
   * [VIP180](https://docs.vechain.org/introduction-to-vechain/dual-token-economic-model/vethor-vtho#vip180-vechains-fungible-token-standard)
   * token.
   *
   * @param {Address} tokenAddress - The address of the VIP180 token.
   * @param {Address} recipientAddress - The address of the recipient.
   * @param {VTHO} amount - The amount of token to be transferred.
   * @param {ClauseOptions} [clauseOptions] - Optional clause settings.
   * @return {Clause} The clause to transfer VIP180 tokens as part of a transaction.
   * @throws {InvalidDataType} Throws an error if the amount is not a positive integer.
   *
   * @see VTHO.transferTokenTo
   */
  static transferToken(tokenAddress, recipientAddress, amount, clauseOptions) {
    if (amount.value.isFinite() && amount.value.isPositive()) {
      return this.callFunction(
        tokenAddress,
        ABIContract.ofAbi(VIP180_ABI).getFunction(
          _Clause.TRANSFER_TOKEN_FUNCTION
        ),
        [recipientAddress.toString(), amount.wei],
        void 0,
        clauseOptions
      );
    }
    throw new InvalidDataType15(
      "Clause.transferToken",
      "not positive integer amount",
      { amount: `${amount.value}` }
    );
  }
  /**
   * Return a new clause to transfers VET to a specified recipient address.
   *
   * @param {Address} recipientAddress - The address of the recipient.
   * @param {VET} amount - The amount of VET to transfer.
   * @param {ClauseOptions} [clauseOptions] - Optional clause settings.
   * @return {Clause} - The clause object to transfer VET as part of a transaction.
   * @throws {InvalidDataType} - If the amount is not a finite positive value.
   *
   * @see VET.transferTo
   */
  static transferVET(recipientAddress, amount, clauseOptions) {
    if (amount.value.isFinite() && amount.value.isPositive()) {
      return new _Clause(
        recipientAddress.toString().toLowerCase(),
        Hex.PREFIX + amount.wei.toString(Hex.RADIX),
        _Clause.NO_DATA,
        clauseOptions?.comment
      );
    }
    throw new InvalidDataType15(
      "Clause.transferVET",
      "not finite positive amount",
      { amount: `${amount.value}` }
    );
  }
};

// src/transaction/Transaction.ts
import * as nc_utils6 from "@noble/curves/abstract/utils";
import {
  InvalidDataType as InvalidDataType16,
  InvalidSecp256k1PrivateKey as InvalidSecp256k1PrivateKey4,
  InvalidSecp256k1Signature as InvalidSecp256k1Signature2,
  InvalidTransactionField,
  NotDelegatedTransaction,
  UnavailableTransactionField
} from "@vechain/sdk-errors";
var Transaction = class _Transaction {
  /**
   * Represent the block reference length in bytes.
   */
  static BLOCK_REF_LENGTH = 8;
  /**
   * A collection of constants used for gas calculations in transactions.
   *
   * Properties
   * - `TX_GAS` - The base gas cost for a transaction.
   * - `CLAUSE_GAS` - The gas cost for executing a clause in a transaction.
   * - `CLAUSE_GAS_CONTRACT_CREATION` - The gas cost for creating a contract via a clause.
   * - `ZERO_GAS_DATA` - The gas cost for transmitting zero bytes of data.
   * - `NON_ZERO_GAS_DATA` - The gas cost for transmitting non-zero bytes of data.
   */
  static GAS_CONSTANTS = {
    TX_GAS: 5000n,
    CLAUSE_GAS: 16000n,
    CLAUSE_GAS_CONTRACT_CREATION: 48000n,
    ZERO_GAS_DATA: 4n,
    NON_ZERO_GAS_DATA: 68n
  };
  /**
   * RLP_FIELDS is an array of objects that defines the structure and encoding scheme
   * for various components in a transaction using Recursive Length Prefix (RLP) encoding.
   * Each object in the array represents a field in the transaction, specifying its name and kind.
   * The `kind` attribute is an instance of an RLP coder that determines how the field is encoded.
   *
   * Properties
   * - `chainTag` - Represent the id of the chain the transaction is sent to.
   * - `blockRef` - Represent the last block of the chain the transaction is sent to.
   * - `expiration` -  Represent the expiration date of the transaction.
   * - `clauses` - List of clause objects, each containing:
   *   - `to` - Represent the destination of the transaction.
   *   - `value` - Represent the 'wei' quantity (VET or VTHO) value the transaction is worth.
   *   - `data` - Represent the content of the transaction.
   * - `gasPriceCoef` - Represent the gas price coefficient of the transaction.
   * - `gas` - Represent the gas limit of the transaction.
   * - `dependsOn` - Represent the hash of the transaction the current transaction depends on.
   * - `nonce` - Represent the nonce of the transaction.
   * - `reserved` -  Reserved field.
   */
  static RLP_FIELDS = [
    { name: "chainTag", kind: new NumericKind(1) },
    { name: "blockRef", kind: new CompactFixedHexBlobKind(8) },
    { name: "expiration", kind: new NumericKind(4) },
    {
      name: "clauses",
      kind: {
        item: [
          {
            name: "to",
            kind: new OptionalFixedHexBlobKind(20)
          },
          { name: "value", kind: new NumericKind(32) },
          { name: "data", kind: new HexBlobKind() }
        ]
      }
    },
    { name: "gasPriceCoef", kind: new NumericKind(1) },
    { name: "gas", kind: new NumericKind(8) },
    { name: "dependsOn", kind: new OptionalFixedHexBlobKind(32) },
    { name: "nonce", kind: new NumericKind(8) },
    { name: "reserved", kind: { item: new BufferKind() } }
  ];
  /**
   * Represent the Recursive Length Prefix (RLP) of the transaction features.
   *
   * Properties
   * - `name` - A string indicating the name of the field in the RLP structure.
   * - `kind` - RLP profile type.
   */
  static RLP_FEATURES = {
    name: "reserved.features",
    kind: new NumericKind(4)
  };
  /**
   * Represents a Recursive Length Prefix (RLP) of the transaction signature.
   *
   * Properties
   * - `name` - A string indicating the name of the field in the RLP structure.
   * - `kind` - RLP profile type.
   */
  static RLP_SIGNATURE = {
    name: "signature",
    kind: new BufferKind()
  };
  /**
   * Represents a Recursive Length Prefix (RLP) of the signed transaction.
   *
   * Properties
   * - `name` - A string indicating the name of the field in the RLP structure.
   * - `kind` - RLP profile type.
   */
  static RLP_SIGNED_TRANSACTION_PROFILE = {
    name: "tx",
    kind: _Transaction.RLP_FIELDS.concat([_Transaction.RLP_SIGNATURE])
  };
  /**
   * Represents a Recursive Length Prefix (RLP) of the unsigned transaction.
   *
   * Properties
   * - `name` - A string indicating the name of the field in the RLP structure.
   * - `kind` - RLP profile type.
   */
  static RLP_UNSIGNED_TRANSACTION_PROFILE = {
    name: "tx",
    kind: _Transaction.RLP_FIELDS
  };
  /**
   * It represents the content of the transaction.
   */
  body;
  /**
   * It represents the signature of the transaction content.
   */
  signature;
  /**
   * Creates a new instance of the class with the specified transaction body and optional signature.
   *
   * @param {TransactionBody} body The transaction body to be used.
   * @param {Uint8Array} [signature] The optional signature for the transaction.
   */
  constructor(body, signature) {
    this.body = body;
    this.signature = signature;
  }
  // ********** GET COMPUTED PROPERTIES **********
  /**
   * Get the delegator's address if the transaction is delegated.
   *
   * If the transaction is delegated and a signature is available, this method recovers
   * the delegator parameter from the signature and subsequently recovers the delegator's public key
   * to derive the delegator's address.
   *
   * @return {Address} The address of the delegator.
   * @throws {UnavailableTransactionField} If the transaction is delegated but the signature is missing.
   * @throws {NotDelegatedTransaction} If the transaction is not delegated.
   *
   * @remarks Security auditable method, depends on
   * - {@link Address.ofPublicKey};
   * - {@link Secp256k1.recover};
   * - {@link Transaction.getSignatureHash}.
   */
  get delegator() {
    if (this.isDelegated) {
      if (this.signature !== void 0) {
        const delegator = this.signature.slice(
          Secp256k1.SIGNATURE_LENGTH,
          this.signature.length
        );
        const delegatorPublicKey = Secp256k1.recover(
          this.getSignatureHash(this.origin).bytes,
          delegator
        );
        return Address.ofPublicKey(delegatorPublicKey);
      }
      throw new UnavailableTransactionField(
        "Transaction.delegator()",
        "missing delegator",
        { fieldName: "delegator" }
      );
    }
    throw new NotDelegatedTransaction(
      "Transaction.delegator()",
      "not delegated transaction",
      void 0
    );
  }
  /**
   * Get the encoded bytes as a Uint8Array.
   * The encoding is determined by whether the data is signed.
   *
   * @return {Uint8Array} The encoded byte array.
   *
   * @see decode
   */
  get encoded() {
    return this.encode(this.isSigned);
  }
  /**
   * Get transaction ID.
   *
   * The ID is the Blake2b256 hash of the transaction's signature
   * concatenated with the origin's address.
   * If the transaction is not signed,
   * it throws an UnavailableTransactionField error.
   *
   * @return {Blake2b256} The concatenated hash of the signature
   * and origin if the transaction is signed.
   * @throws {UnavailableTransactionField} If the transaction is not signed.
   *
   * @remarks Security auditable method, depends on
   * - {@link Blake2b256.of}
   */
  get id() {
    if (this.isSigned) {
      return Blake2b256.of(
        nc_utils6.concatBytes(
          this.getSignatureHash().bytes,
          this.origin.bytes
        )
      );
    }
    throw new UnavailableTransactionField(
      "Transaction.id()",
      "not signed transaction: id unavailable",
      { fieldName: "id" }
    );
  }
  /**
   * Return the intrinsic gas required for this transaction.
   *
   * @return {VTHO} The computed intrinsic gas for the transaction.
   */
  get intrinsicGas() {
    return _Transaction.intrinsicGas(this.body.clauses);
  }
  /**
   * Returns `true` if the transaction is delegated, otherwise `false`.
   *
   * @return {boolean} `true` if the transaction is delegated,
   * otherwise `false`.
   */
  get isDelegated() {
    return _Transaction.isDelegated(this.body);
  }
  /**
   * Return `true` if the signature is defined, otherwise `false`.
   *
   * @return {boolean} return `true` if the signature is defined, otherwise `false`.
   */
  get isSigned() {
    return this.signature !== void 0;
  }
  /**
   * Return the origin address of the transaction.
   *
   * The origin is determined by recovering the public key from the transaction's signature.
   *
   * @return {Address} The address derived from the public key of the transaction's signer.
   * @throws {UnavailableTransactionField} If the transaction is not signed, an exception is thrown indicating the absence of the origin field.
   *
   * @remarks Security auditable method, depends on
   * - {@link Address.ofPublicKey};
   * - {@link Secp256k1.recover}.
   */
  get origin() {
    if (this.signature !== void 0) {
      return Address.ofPublicKey(
        // Get the origin public key.
        Secp256k1.recover(
          this.getSignatureHash().bytes,
          // Get the (r, s) of ECDSA digital signature without delegator params.
          this.signature.slice(0, Secp256k1.SIGNATURE_LENGTH)
        )
      );
    }
    throw new UnavailableTransactionField(
      "Transaction.origin()",
      "not signed transaction, no origin",
      { fieldName: "origin" }
    );
  }
  // ********** PUBLIC METHODS **********
  /**
   * Decodes a raw transaction byte array into a new Transaction object.
   *
   * @param {Uint8Array} rawTransaction - The raw transaction bytes to decode.
   * @param {boolean} isSigned - Flag indicating if the transaction is signed.
   * @return {Transaction} The decoded transaction object.
   *
   * @see encoded
   */
  static decode(rawTransaction, isSigned) {
    const profile = isSigned ? _Transaction.RLP_SIGNED_TRANSACTION_PROFILE : _Transaction.RLP_UNSIGNED_TRANSACTION_PROFILE;
    const decodedRLPBody = RLPProfiler.ofObjectEncoded(
      rawTransaction,
      profile
    ).object;
    const bodyWithoutReservedField = {
      blockRef: decodedRLPBody.blockRef,
      chainTag: decodedRLPBody.chainTag,
      clauses: decodedRLPBody.clauses,
      dependsOn: decodedRLPBody.dependsOn,
      expiration: decodedRLPBody.expiration,
      gas: decodedRLPBody.gas,
      gasPriceCoef: decodedRLPBody.gasPriceCoef,
      nonce: decodedRLPBody.nonce
    };
    const correctTransactionBody = decodedRLPBody.reserved.length > 0 ? {
      ...bodyWithoutReservedField,
      reserved: _Transaction.decodeReservedField(
        decodedRLPBody.reserved
      )
    } : bodyWithoutReservedField;
    return decodedRLPBody.signature !== void 0 ? _Transaction.of(
      correctTransactionBody,
      decodedRLPBody.signature
    ) : _Transaction.of(correctTransactionBody);
  }
  /**
   * Computes the signature hash, optionally incorporating a delegator's address.
   *
   * @param {Address} [delegator] - Optional delegator's address to include in the hash computation.
   * @return {Blake2b256} - The computed signature hash.
   *
   * @remarks
   * `delegator` is used to sign a transaction on behalf of another account.
   *
   * @remarks Security auditable method, depends on
   * - {@link Blake2b256.of}.
   */
  getSignatureHash(delegator) {
    const txHash = Blake2b256.of(this.encode(false));
    if (delegator !== void 0) {
      return Blake2b256.of(
        nc_utils6.concatBytes(txHash.bytes, delegator.bytes)
      );
    }
    return txHash;
  }
  /**
   * Calculates the intrinsic gas required for the given transaction clauses.
   *
   * @param {TransactionClause[]} clauses - An array of transaction clauses to calculate the intrinsic gas for.
   * @return {VTHO} The total intrinsic gas required for the provided clauses.
   * @throws {InvalidDataType} If clauses have invalid data as invalid addresses.
   */
  static intrinsicGas(clauses) {
    if (clauses.length > 0) {
      return VTHO.of(
        clauses.reduce((sum, clause) => {
          if (clause.to !== null) {
            if (!Address.isValid(clause.to) && !clause.to.includes("."))
              throw new InvalidDataType16(
                "Transaction.intrinsicGas",
                "invalid data type in clause: each `to` field must be a valid address.",
                { clause }
              );
            sum += _Transaction.GAS_CONSTANTS.CLAUSE_GAS;
          } else {
            sum += _Transaction.GAS_CONSTANTS.CLAUSE_GAS_CONTRACT_CREATION;
          }
          sum += _Transaction.computeUsedGasFor(clause.data);
          return sum;
        }, _Transaction.GAS_CONSTANTS.TX_GAS),
        0 /* wei */
      );
    }
    return VTHO.of(
      _Transaction.GAS_CONSTANTS.TX_GAS + _Transaction.GAS_CONSTANTS.CLAUSE_GAS,
      0 /* wei */
    );
  }
  /**
   * Return `true` if the transaction body is valid, `false` otherwise.
   *
   * @param {TransactionBody} body - The transaction body to validate.
   * @return {boolean} `true` if the transaction body is valid, `false` otherwise.
   */
  static isValidBody(body) {
    return (
      // Chain tag
      body.chainTag !== void 0 && body.chainTag >= 0 && body.chainTag <= 255 && // Block reference
      body.blockRef !== void 0 && Hex.isValid0x(body.blockRef) && HexUInt.of(body.blockRef).bytes.length === _Transaction.BLOCK_REF_LENGTH && // Expiration
      body.expiration !== void 0 && // Clauses
      body.clauses !== void 0 && // Gas price coef
      body.gasPriceCoef !== void 0 && // Gas
      body.gas !== void 0 && // Depends on
      body.dependsOn !== void 0 && // Nonce
      body.nonce !== void 0
    );
  }
  /**
   * Creates a new Transaction instance if the provided body and optional
   * signature are valid.
   *
   * @param {TransactionBody} body - The transaction body to be validated.
   * @param {Uint8Array} [signature] - Optional signature to be validated.
   * @return {Transaction} A new Transaction instance if validation is successful.
   * @throws {InvalidSecp256k1Signature} If the provided signature is invalid.
   * @throws {InvalidTransactionField} If the provided body is invalid.
   */
  static of(body, signature) {
    if (_Transaction.isValidBody(body)) {
      if (signature === void 0 || _Transaction.isSignatureValid(body, signature)) {
        return new _Transaction(body, signature);
      }
      throw new InvalidSecp256k1Signature2(
        "Transaction.of",
        "invalid signature",
        { signature }
      );
    }
    throw new InvalidTransactionField("Transaction.of", "invalid body", {
      fieldName: "body",
      body
    });
  }
  /**
   * Signs the transaction using the provided private key.
   *
   * @param {Uint8Array} signerPrivateKey - The private key used to sign the transaction.
   * @return {Transaction} The signed transaction.
   * @throws {InvalidTransactionField} If attempting to sign a delegated transaction.
   * @throws {InvalidSecp256k1PrivateKey} If the provided private key is not valid.
   *
   * @remarks Security auditable method, depends on
   * - {@link Secp256k1.isValidPrivateKey};
   * - {@link Secp256k1.sign}.
   */
  sign(signerPrivateKey) {
    if (Secp256k1.isValidPrivateKey(signerPrivateKey)) {
      if (!this.isDelegated) {
        const signature = Secp256k1.sign(
          this.getSignatureHash().bytes,
          signerPrivateKey
        );
        return _Transaction.of(this.body, signature);
      }
      throw new InvalidTransactionField(
        `Transaction.sign`,
        "delegated transaction: use signWithDelegator method",
        { fieldName: "delegator", body: this.body }
      );
    }
    throw new InvalidSecp256k1PrivateKey4(
      `Transaction.sign`,
      "invalid private key: ensure it is a secp256k1 key",
      void 0
    );
  }
  /**
   * Signs the transaction using both the signer and the delegator private keys.
   *
   * @param {Uint8Array} signerPrivateKey - The private key of the signer.
   * @param {Uint8Array} delegatorPrivateKey - The private key of the delegator.
   * @return {Transaction} A new transaction with the concatenated signatures
   * of the signer and the delegator.
   * @throws {InvalidSecp256k1PrivateKey} - If either the signer or delegator private key is invalid.
   * @throws {NotDelegatedTransaction} - If the transaction is not delegated.
   *
   * @remarks Security auditable method, depends on
   * - {@link Address.ofPublicKey}
   * - {@link Secp256k1.isValidPrivateKey};
   * - {@link Secp256k1.sign}.
   */
  signWithDelegator(signerPrivateKey, delegatorPrivateKey) {
    if (Secp256k1.isValidPrivateKey(signerPrivateKey)) {
      if (Secp256k1.isValidPrivateKey(delegatorPrivateKey)) {
        if (this.isDelegated) {
          const transactionHash = this.getSignatureHash().bytes;
          const delegatedHash = this.getSignatureHash(
            Address.ofPublicKey(
              Secp256k1.derivePublicKey(signerPrivateKey)
            )
          ).bytes;
          return _Transaction.of(
            this.body,
            nc_utils6.concatBytes(
              Secp256k1.sign(transactionHash, signerPrivateKey),
              Secp256k1.sign(delegatedHash, delegatorPrivateKey)
            )
          );
        }
        throw new NotDelegatedTransaction(
          "Transaction.signWithDelegator",
          "not delegated transaction: use sign method",
          void 0
        );
      }
      throw new InvalidSecp256k1PrivateKey4(
        `Transaction.signWithDelegator`,
        "invalid delegator private: ensure it is a secp256k1 key",
        void 0
      );
    }
    throw new InvalidSecp256k1PrivateKey4(
      `Transaction.signWithDelegator`,
      "invalid signer private key: ensure it is a secp256k1 key",
      void 0
    );
  }
  // ********** PRIVATE FUNCTIONS **********
  /**
   * Computes the amount of gas used for the given data.
   *
   * @param {string} data - The hexadecimal string data for which the gas usage is computed.
   * @return {bigint} The total gas used for the provided data.
   * @throws {InvalidDataType} If the data is not a valid hexadecimal string.
   *
   * @remarks gas value is expressed in {@link Units.wei} unit.
   */
  static computeUsedGasFor(data) {
    if (data !== "" && !Hex.isValid(data))
      throw new InvalidDataType16(
        "calculateDataUsedGas()",
        `Invalid data type for gas calculation. Data should be a hexadecimal string.`,
        { data }
      );
    let sum = 0n;
    for (let i = 2; i < data.length; i += 2) {
      if (data.substring(i, i + 2) === "00") {
        sum += _Transaction.GAS_CONSTANTS.ZERO_GAS_DATA;
      } else {
        sum += _Transaction.GAS_CONSTANTS.NON_ZERO_GAS_DATA;
      }
    }
    return sum;
  }
  /**
   * Decodes the {@link TransactionBody.reserved} field from the given buffer array.
   *
   * @param {Buffer[]} reserved  - An array of Uint8Array objects representing the reserved field data.
   * @return {Object} An object containing the decoded features and any unused buffer data.
   * @return {number} [return.features] The decoded features from the reserved field.
   * @return {Buffer[]} [return.unused] An array of Buffer objects representing unused data, if any.
   * @throws {InvalidTransactionField} Thrown if the reserved field is not properly trimmed.
   */
  static decodeReservedField(reserved) {
    if (reserved[reserved.length - 1].length > 0) {
      const featuresField = _Transaction.RLP_FEATURES.kind.buffer(reserved[0], _Transaction.RLP_FEATURES.name).decode();
      return reserved.length > 1 ? {
        features: featuresField,
        unused: reserved.slice(1)
      } : { features: featuresField };
    }
    throw new InvalidTransactionField(
      "Transaction.decodeReservedField",
      "invalid reserved field: fields in the `reserved` property must be properly trimmed",
      { fieldName: "reserved", reserved }
    );
  }
  /**
   * Encodes the transaction body using RLP encoding.
   *
   * @param {boolean} isSigned - Indicates whether the transaction is signed.
   * @return {Uint8Array} The RLP encoded transaction body.
   *
   * @see encoded
   */
  encode(isSigned) {
    return this.encodeBodyField(
      {
        // Existing body and the optional `reserved` field if present.
        ...this.body,
        /*
         * The `body.clauses` property is already an array,
         * albeit TypeScript realize, hence cast is needed
         * otherwise encodeObject will throw an error.
         */
        clauses: this.body.clauses,
        // New reserved field.
        reserved: this.encodeReservedField()
      },
      isSigned
    );
  }
  /**
   * Encodes the given transaction body into a Uint8Array, depending on whether
   * the transaction is signed or not.
   *
   * @param body - The transaction object adhering to the RLPValidObject structure.
   * @param isSigned - A boolean indicating if the transaction is signed.
   * @return A Uint8Array representing the encoded transaction.
   *
   * @see encoded
   */
  encodeBodyField(body, isSigned) {
    if (isSigned) {
      return RLPProfiler.ofObject(
        {
          ...body,
          signature: Uint8Array.from(this.signature)
        },
        _Transaction.RLP_SIGNED_TRANSACTION_PROFILE
      ).encoded;
    }
    return RLPProfiler.ofObject(
      body,
      _Transaction.RLP_UNSIGNED_TRANSACTION_PROFILE
    ).encoded;
  }
  /**
   * Encodes the {@link TransactionBody.reserved} field data for a transaction.
   *
   * @return {Uint8Array[]} The encoded list of reserved features.
   * It removes any trailing unused features that have zero length from the list.
   *
   * @remarks The {@link TransactionBody.reserved} is optional, albeit
   * is required to perform RLP encoding.
   *
   * @see encode
   */
  encodeReservedField() {
    const reserved = this.body.reserved ?? {};
    const featuresKind = _Transaction.RLP_FEATURES.kind;
    const featuresList = [
      featuresKind.data(reserved.features ?? 0, _Transaction.RLP_FEATURES.name).encode(),
      ...reserved.unused ?? []
    ];
    while (featuresList.length > 0) {
      if (featuresList[featuresList.length - 1].length === 0) {
        featuresList.pop();
      } else {
        break;
      }
    }
    return featuresList;
  }
  /**
   * Return `true` if the transaction is delegated, else `false`.
   *
   * @param {TransactionBody} body - The transaction body.
   * @return {boolean} `true` if the transaction is delegated, else `false`.
   */
  static isDelegated(body) {
    const reserved = body.reserved ?? {};
    const features = reserved.features ?? 0;
    return (features & 1) === 1;
  }
  /**
   * Return Returns true if the signature is valid, otherwise false.
   *
   * @param {TransactionBody} body - The transaction body to be checked.
   * @param {Uint8Array} signature - The signature to validate.
   * @return {boolean} - Returns true if the signature is valid, otherwise false.
   */
  static isSignatureValid(body, signature) {
    const expectedSignatureLength = this.isDelegated(body) ? Secp256k1.SIGNATURE_LENGTH * 2 : Secp256k1.SIGNATURE_LENGTH;
    return signature.length === expectedSignatureLength;
  }
};

// src/utils/const/network.ts
var VECHAIN_MAINNET_CHAIN_TAG = 74;
var VECHAIN_TESTNET_CHAIN_TAG = 39;
var VECHAIN_SOLO_CHAIN_TAG = 246;
var ZERO_ADDRESS = "0x0000000000000000000000000000000000000000";
var VTHO_ADDRESS = "0x0000000000000000000000000000456e65726779";
var mainnetGenesisBlock = {
  number: 0,
  id: "0x00000000851caf3cfdb6e899cf5958bfb1ac3413d346d43539627e6be7ec1b4a",
  size: 170,
  parentID: "0xffffffff53616c757465202620526573706563742c20457468657265756d2100",
  timestamp: 1530316800,
  gasLimit: 1e7,
  beneficiary: "0x0000000000000000000000000000000000000000",
  gasUsed: 0,
  totalScore: 0,
  txsRoot: "0x45b0cfc220ceec5b7c1c62c4d4193d38e4eba48e8815729ce75f9c0ab0e4c1c0",
  txsFeatures: 0,
  stateRoot: "0x09bfdf9e24dd5cd5b63f3c1b5d58b97ff02ca0490214a021ed7d99b93867839c",
  receiptsRoot: "0x45b0cfc220ceec5b7c1c62c4d4193d38e4eba48e8815729ce75f9c0ab0e4c1c0",
  signer: "0x0000000000000000000000000000000000000000",
  isTrunk: true,
  transactions: []
};
var testnetGenesisBlock = {
  number: 0,
  id: "0x000000000b2bce3c70bc649a02749e8687721b09ed2e15997f466536b20bb127",
  size: 170,
  parentID: "0xffffffff00000000000000000000000000000000000000000000000000000000",
  timestamp: 1530014400,
  gasLimit: 1e7,
  beneficiary: "0x0000000000000000000000000000000000000000",
  gasUsed: 0,
  totalScore: 0,
  txsRoot: "0x45b0cfc220ceec5b7c1c62c4d4193d38e4eba48e8815729ce75f9c0ab0e4c1c0",
  txsFeatures: 0,
  stateRoot: "0x4ec3af0acbad1ae467ad569337d2fe8576fe303928d35b8cdd91de47e9ac84bb",
  receiptsRoot: "0x45b0cfc220ceec5b7c1c62c4d4193d38e4eba48e8815729ce75f9c0ab0e4c1c0",
  signer: "0x0000000000000000000000000000000000000000",
  isTrunk: true,
  transactions: []
};
var soloGenesisBlock = {
  number: 0,
  id: "0x00000000c05a20fbca2bf6ae3affba6af4a74b800b585bf7a4988aba7aea69f6",
  size: 170,
  parentID: "0xffffffff00000000000000000000000000000000000000000000000000000000",
  timestamp: 15264e5,
  gasLimit: 1e7,
  beneficiary: "0x0000000000000000000000000000000000000000",
  gasUsed: 0,
  totalScore: 0,
  txsRoot: "0x45b0cfc220ceec5b7c1c62c4d4193d38e4eba48e8815729ce75f9c0ab0e4c1c0",
  txsFeatures: 0,
  stateRoot: "0x93de0ffb1f33bc0af053abc2a87c4af44594f5dcb1cb879dd823686a15d68550",
  receiptsRoot: "0x45b0cfc220ceec5b7c1c62c4d4193d38e4eba48e8815729ce75f9c0ab0e4c1c0",
  signer: "0x0000000000000000000000000000000000000000",
  isTrunk: true,
  transactions: []
};
var MAINNET_NETWORK = {
  genesisBlock: mainnetGenesisBlock,
  chainTag: VECHAIN_MAINNET_CHAIN_TAG
};
var TESTNET_NETWORK = {
  genesisBlock: testnetGenesisBlock,
  chainTag: VECHAIN_TESTNET_CHAIN_TAG
};
var SOLO_NETWORK = {
  genesisBlock: soloGenesisBlock,
  chainTag: VECHAIN_SOLO_CHAIN_TAG
};
var networkInfo = {
  mainnet: MAINNET_NETWORK,
  testnet: TESTNET_NETWORK,
  solo: SOLO_NETWORK
};
export {
  ABIContract,
  ABIEvent,
  ABIFunction,
  ABIItem,
  Account,
  Address,
  Blake2b256,
  BloomFilter,
  BufferKind,
  Certificate,
  Clause,
  Coin,
  CompactFixedHexBlobKind,
  ERC1155_ABI,
  ERC20_ABI,
  ERC721_ABI,
  FixedHexBlobKind,
  FixedPointNumber,
  HDKey2 as HDKey,
  Hex,
  HexBlobKind,
  HexInt,
  HexUInt,
  Keccak256,
  MAINNET_NETWORK,
  Mnemonic,
  NUMERIC_REGEX,
  NumericKind,
  OptionalFixedHexBlobKind,
  Quantity,
  RLP,
  RLPProfiler,
  Revision,
  SOLO_NETWORK,
  ScalarKind,
  Secp256k1,
  Sha256,
  TESTNET_NETWORK,
  ThorId,
  Transaction,
  Txt,
  Units,
  VET,
  VIP180_ABI,
  VIP181_ABI,
  VIP210_ABI,
  VTHO,
  VTHO_ADDRESS,
  ZERO_ADDRESS,
  ZERO_BYTES,
  ethersAbi as abi,
  addressUtils,
  assertCompactFixedHexBlobBuffer,
  assertFixedHexBlobKindBuffer,
  assertFixedHexBlobKindData,
  assertValidHexBlobKindData,
  assertValidNumericKindBuffer,
  blake2b256,
  core_exports as core,
  dataUtils,
  decodeBufferToHexWithLeadingZeros,
  decodeBufferToNumberOrHex,
  encodeBigIntToBuffer,
  encodeCompactFixedHexBlob,
  keccak256,
  keystore3 as keystore,
  mnemonic,
  networkInfo,
  revisionUtils,
  sha2562 as sha256,
  validateNumericKindData,
  ethers2 as vechain_sdk_core_ethers
};
